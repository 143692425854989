import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasketItem } from '@sabstravtech/obtservices/angular';
import { EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import { LoungesEnterpriseSearchInterface, TerminalsType } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-basket-lounge',
  templateUrl: './basket-lounge.component.html',
  styleUrls: ['./basket-lounge.component.scss']
})
export class BasketLoungeComponent implements OnInit {
  @Input() lounge: BasketItem;
  @Input() duplicateBooking = false;
  @Input() itemIndex: number = 0;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChangeModal: EventEmitter<void> = new EventEmitter<void>();

  public searchParameters: LoungesEnterpriseSearchInterface = null;

  constructor(private searchService: EnterpriseSearchService) { }

  ngOnInit() {
    this.searchParameters = this.searchService.searches[ServiceType.Lounge];
  }

  getTerminal(terminal: string) {
    if (terminal) {
      let newTerminals: TerminalsType[] = this.searchService.processTerminals([terminal]);
      if (newTerminals.length > 1) {
        return this.searchService.processTerminals([terminal])[1]?.key;
      } else {
        return this.searchService.processTerminals([terminal])[0]?.key;
      }
    } else {
      return 'Any Terminal';
    }
  }
}
