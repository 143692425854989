<div
  class="background-60 carbon-comparison-container"
  *ngIf="lowestCarbonFlight && lowestCarbonRail">
  <div class="flex-column-container">
    <div class="flex-column-container">
      <div class="flex-column-container">
        <h2 class="carbon_header" i18n="@@carbonComparisonHeader">Carbon Comparison</h2>
        <div *ngIf="lowestCarbonRail < lowestCarbonFlight">
          <span i18n="@@carbonComparisonSave">Save</span>&nbsp;{{
            (lowestCarbonFlight - lowestCarbonRail).toFixed()
          }}kg&nbsp;<span i18n="@@carbonComparisonText">of CO₂ by travelling via rail.</span>
        </div>
      </div>
      <div class="flex-column-container">
        <div class="transaction-carbon-container">
          <i class="ico-flight" aria-hidden="true"> </i>
          <div
            class="transaction-carbon-bar"
            [ngClass]="{ high_carbon: lowestCarbonFlight > lowestCarbonRail }"
            [ngStyle]="{ width: lowestCarbonFlight < lowestCarbonRail ? getCarbonStyle() : null }">
            Flight - {{ lowestCarbonFlight.toFixed() }}kg
          </div>
        </div>
        <div class="transaction-carbon-container">
          <i class="ico-rail" aria-hidden="true"> </i>
          <div
            class="transaction-carbon-bar"
            [ngClass]="{ high_carbon: lowestCarbonRail > lowestCarbonFlight }"
            [ngStyle]="{ width: lowestCarbonRail < lowestCarbonFlight ? getCarbonStyle() : null }">
            Rail - {{ lowestCarbonRail.toFixed() }}kg
          </div>
          <span *ngIf="carbonPercentage"
            >Have you considered the Rail option? This could reduce your environmental impact by
            {{ carbonPercentage.toFixed() }}%</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
