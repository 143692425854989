import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SearchObjects, SearchObject } from '@sabstravtech/obtservices/base'
import { WithSubscriptionComponent, EnterpriseSearchService, ServiceType, StorageService, BasketItem } from '@sabstravtech/obtservices/angular'
import { Router } from '@angular/router';
import { PageType } from '../../vendor/enum/page-type.enum';

@Component({
  selector: 'app-sub-header-bar',
  templateUrl: './sub-header-bar.component.html',
  styleUrls: ['./sub-header-bar.component.scss']
})

export class SubHeaderBarComponent extends WithSubscriptionComponent {
  @Input() page: string;
  @Input() resultsType: string;
  @Input() basketItemTypes: ServiceType[] = null;
  @Input() valid: boolean = true;

  pageType = PageType;
  selectedSearch: SearchObjects;
  constructor(public readonly searchService: EnterpriseSearchService, private readonly router: Router, private storageService: StorageService) { super(); }

  ngOnInit(): void {
    if(this.page === this.pageType.Itinerary){
      this.sortBasketOrder();
      Object.values(this.searchService.search_objects).forEach((value: SearchObject) => {
        if(value.originalChosen) {
          value.originalChosen = false;
        }
      });
    }
    this.resultsType = this.resultsType?.replace(/-/g, ' ');
  }

  sortBasketOrder(){
    const searchObjectOrder = Object.keys(this.searchService.search_objects);
    this.basketItemTypes = this.basketItemTypes.sort((a, b) => searchObjectOrder.indexOf(a) - searchObjectOrder.indexOf(b));
  }

  getIconClass(searchObject: ServiceType) {
    switch (searchObject) {
        case ServiceType.Hotel:
            return 'ico-bed';
        case ServiceType.Flight:
            return 'ico-flight';
        case ServiceType.Rail:
            return 'ico-rail';
        case ServiceType.InternationalRail:
            return 'ico-rail';
        case ServiceType.Car:
            return 'ico-car';
        case ServiceType.Lounge:
            return 'ico-m-lounge';
        case ServiceType.Parking:
            return 'ico-m-parking';
        case ServiceType.Cab:
            return 'ico-taxi';
        case ServiceType.Eurostar:
            return 'custom_icon-eurostar';
        case ServiceType.FastTrack:
            return 'ico-material ico-fast-track';
        // case ServiceType.COACH:
        //   return 'ico-bus';
        default:
            return 'ico-dot';
    }
  }
}
