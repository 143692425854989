import {
  Component,
  OnInit,
  ViewChildren,
  ViewChild,
  ElementRef,
  AfterViewInit,
  HostListener,
  OnDestroy
} from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormControl } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Title } from '@angular/platform-browser';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';
import {
  SearchObject,
  UserServices,
  MenuSlideEnum,
  OBTAirportDetails,
  ServiceProvider,
  RightPanelMenu,
  LocationTypes
} from '@sabstravtech/obtservices/base';
import {
  EnterpriseBasketService,
  EnterpriseSearchService,
  ModalOpenerService,
  ServiceType,
  StorageService,
  UserService,
  HelperRoutines,
  User,
  WithSubscriptionComponent,
  Document,
  DocType
} from '@sabstravtech/obtservices/angular';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { DDAService } from '../../../vendor/services/dda.service';
import { TravelTypeInfo, TravelTypeInfoType } from '../../../vendor/interfaces/travel-types';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { WCAGFocusHandler } from '../../../vendor/services/wcag-focus-handler.service';
import { LanguageService } from '../../../vendor/services/language.service';

const now = new Date();
const tomorrow = new Date(now.getTime() + 24 * 60 * 60 * 1000);
const OtherItems = [
  // // ServiceType.AIR_EXP,
  ServiceType.Lounge,
  ServiceType.Parking,
  ServiceType.Cab,
  ServiceType.Coach,
  ServiceType.FastTrack,
  ServiceType.InternationalRail,
  ServiceType.Ferry,
  ServiceType.Apartment,
  ServiceType.SeasonTicket,
  ServiceType.MeetingRoom
];
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    // animations for basket slide in/out
    trigger('slideInOut', [
      state(
        MenuSlideEnum.IN,
        style({
          transform: 'translate3d(-100%, 0, 0)'
        })
      ),
      state(
        MenuSlideEnum.OUT,
        style({
          transform: 'translate3d(0, 0, 0)'
        })
      ),
      state(
        MenuSlideEnum.FLAT,
        style({
          transform: 'translate3d(0, 33px, 0)'
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class SearchComponent
  extends WithSubscriptionComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('searchForm') searchForm;
  @ViewChildren('input') trvNpt;
  @ViewChild('main') main: ElementRef;
  @ViewChild('basket') basket: ElementRef;

  get basketCounter(): number {
    return this.basketService.getNumberItemsForBasket();
  }

  hasOthers: boolean = false;
  ServiceType: typeof ServiceType = ServiceType;
  ServiceProvider: typeof ServiceProvider = ServiceProvider;
  travelServices = new FormControl();
  flight_chosen = false;
  rail_chosen = false;
  showMore = true;
  hotel_chosen = false;
  car_chosen = false;
  toolTipClicked = false;
  othersExpanded = false;
  show_errors = false;
  showRecentSearch = true;
  showMyBaskets = false;
  showFrequentTravellers = false;
  menuOpen = false;
  RightPanelMenu = RightPanelMenu;
  MenuSlideEnum = MenuSlideEnum;
  selectedRightPanelItem: RightPanelMenu = RightPanelMenu.RecentSearches;
  othersSelectedAriaLabel = null;
  //
  nights: number;
  location: string;
  //
  currentUser: User;
  start_date: NgbDateStruct = {
    year: now.getFullYear(),
    month: now.getMonth() + 1,
    day: now.getDate()
  };
  end_date: NgbDateStruct = {
    year: tomorrow.getFullYear(),
    month: tomorrow.getMonth() + 1,
    day: tomorrow.getDate()
  };
  showTravelerInput = false;
  showTravelerDropdown = false;
  showGuestTravellerForm = false;
  canBookOther = true;
  bookableTravelTypes: TravelTypeInfoType[] = [];
  loadingTravellerProfile = false;
  loadingSSO: boolean = false;
  otherTravelTypes = [
    // ServiceType.AIR_EXP,
    ServiceType.Lounge,
    ServiceType.Parking,
    // ServiceType.EUROSTAR,
    ServiceType.Cab,
    ServiceType.Coach
  ];
  userServices$: Observable<UserServices>;
  passportErrors: string[] = [];
  hotelsearchRequested: boolean = false;
  @ViewChild('basketExpanderButton') basketExpanderButton: ElementRef;

  constructor(
    public userService: UserService,
    public searchService: EnterpriseSearchService,
    public basketService: EnterpriseBasketService,
    // public recentService: RecentSearchesService,
    public modalService: ModalOpenerService,
    public ddaService: DDAService,
    private storageService: StorageService,
    private title: Title,
    public translateService: TranslateService,
    private helpers: HelperRoutines,
    private wcagFocusHandlerService: WCAGFocusHandler,
    private languageService: LanguageService
  ) {
    super();
  }

  @HostListener('document:click', ['$event.target'])
  public onClick() {
    if (window.innerWidth > 1600) { // ui-width-a: 1600px
      this.basketService.menuState = MenuSlideEnum.OUT;
    } 
    if (this.toolTipClicked === true) {
    } else if (this.basketService.menuState === MenuSlideEnum.IN) {
      this.toolTipClicked = true;
    }
  }
  public innerWidth: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.onLoad();
  }

  ngOnInit() {
    let ssoFlightQuery = window.location.href.split('?')[1]?.split('#')[0];

    if (ssoFlightQuery && this.searchService.ssoCall) {
      console.warn('+++ LOADING SSO CALL +++');
      this.loadingSSO = true;
    }

    this.subscribe(this.userService.fullUserDetails.allDetails, (fullUser: User) => {
      if (fullUser) {
        setTimeout(() => {
          if (ssoFlightQuery && this.searchService.ssoCall && this.searchService.searches[ServiceType.Flight]) {
            ssoFlightQuery = ssoFlightQuery.replace(/%3A/g, '');
            const splitQuery = ssoFlightQuery.split('&');
            const ssoObject = splitQuery.reduce((acc, param) => {
              const splitParam = param.split('=');
              acc[splitParam[0]] = splitParam[1];
              return acc;
            }, {});
            this.searchService.ssoCall = false;
            this.searchService.makePortalFlightCall(ssoObject);

          } else if (!this.searchService.searches[ServiceType.Flight]) {
            console.error('+++ SEARCH NOT KICKED OFF - SEARCHES OBJECT MAY NOT HAVE LOADED CORRECTLY +++');
            this.loadingSSO = false;
          }
        }, 2000);
        const selectedLanguage = fullUser?.selectedLanguage;
        const isSupprtAgent = this.userService.userIsSupportAgent();
        if (selectedLanguage && !isSupprtAgent) {
          const availableLanguages = fullUser.availableLanguages.map(
            (lang) => lang.isoCode
          );
          console.log(selectedLanguage.name);
          console.log(selectedLanguage.isoCode);
          this.languageService.changeLanguageFromUserDetails(
            selectedLanguage.name,
            selectedLanguage.isoCode,
            availableLanguages
          );
        }
      }
    });

    this.searchService.resetSearchPriorities();
    // console.log('searchobj: ', this.searchService.search_objects);
    // console.log('highestPriority: ', this.searchService.highest_search_priority);

    this.title.setTitle(this.translateService.instant('New Search - LightUK'));
    // if no filter is selected set user start tab based on user profile or Flight by default
    this.getUserServices();
    this.basketService.reset();
    this.subscribe(this.basketService.basketChangeEmitter, (id: string) => {
      this.basketService.getRequiresReasonService().currentBasketid = id;
      this.basketService.getRequiresOverrideReasonService().currentBasketid = id;
    });

    this.travelServices.setValue([]);
    this.onLoad();
    // this.currentUser = this.userService.getUser();


    // this.searchService.getRiskAlerts(RiskLevel.Moderate, RiskLevel.Extreme, 'FR').subscribe(data => {
    //   console.log(data);
    // }, error => {
    //   console.error(error);
    // });

    // this.searchService.getUserCurrentCarbonAllowance().subscribe(data => {
    // })

    this.subscribe(this.userService.fullUserDetails.userServices, (services: UserServices) => {
      if (services) {
        this.bookableTravelTypes = TravelTypeInfo.filter(info => {
          return services[info.type];
        });
      }
      this.hasOthers =
        !!services &&
        !!OtherItems.find((item: ServiceType) => {
          return !!services[item];
        });
    });
    /* TODO
    if (this.searchService.hotel.country_list.length === 0 || this.searchService.car.country_list.length === 0) {
      this.searchService.fetchUserData();
    }
    if (this.userService.getUserDetails() && this.searchService.firstLoad && !this.basketService.hasBookingStarted()) {
      this.selectStartTab();
      this.closeValidationErrors();
    }*/
    this.searchService.determineHighestSearchPriority();
    const found = Object.values(this.searchService.search_objects).find((item: SearchObject) => {
      return item.autosearch && this.searchService.highest_search_priority === item.priority;
    });
    if (found) {
      this.fetchSearchAll();
    }
    this.fillSelectedOthers();
    this.wcagFocusHandlerService.skipToBasketClicked.subscribe(() => {
      this.basketExpanderButton.nativeElement.focus();
    });
    this.userService.newInvokedUser.subscribe((user) => {
      this.getUserPassports();
    })
  }

  ngOnDestroy(): void {
    this.basketService.getRequiresReasonService().currentBasketid = null;
    this.basketService.getRequiresOverrideReasonService().currentBasketid = null;
    super.ngOnDestroy();
  }

  getUserServices(): void {
    this.userServices$ = this.userService.fullUserDetails.userServices.pipe(
      tap((services: UserServices) => {
        this.setDefaultFilter(services);
      })
    );
  }
  others() {
    if (this.bookableTravelTypes.length <= 5) {
      return {};
    }
    return _.transform(
      this.bookableTravelTypes.slice(4),
      (a, travelType) => {
        a[travelType.searchName] = travelType.typeName;
      },
      {}
    );
  }

  ngAfterViewInit(): void {
    this.ddaService.main = this.main;
    this.ddaService.basket = this.basket;
    this.subscribe(this.searchService.isGutMeeting, (isGutMeeting: boolean) => {
      if (isGutMeeting) {
        this.selectedRightPanelItem = RightPanelMenu.MyBaskets;
        this.openRightPanelMenu(this.selectedRightPanelItem);
      } else {
        this.selectedRightPanelItem = (this.userService.getUserFavoriteObject(LightningUserFavorurite.startTab) as {
          sideMenu: RightPanelMenu })?.sideMenu || RightPanelMenu.RecentSearches;
          this.openRightPanelMenu(this.selectedRightPanelItem);
      }
    })
  }

  setRightPanelItem(showRightPanelMenu:boolean){
    if (showRightPanelMenu && this.selectedRightPanelItem !== RightPanelMenu.FrequentTravellers) {
      const previousOption: RightPanelMenu = this.selectedRightPanelItem;
      this.selectedRightPanelItem = RightPanelMenu.FrequentTravellers;
      this.openRightPanelMenu(this.selectedRightPanelItem);
      setTimeout(() => {
        this.selectedRightPanelItem = previousOption;
        this.openRightPanelMenu(this.selectedRightPanelItem);
      }, 3000);
    }
  }

  noFilterSelected(): boolean {
    return (
      !this.searchService.search_objects[ServiceType.Lounge]?.chosen &&
      !this.searchService.search_objects[ServiceType.Parking]?.chosen &&
      !this.searchService.search_objects[ServiceType.Eurostar]?.chosen &&
      !this.searchService.search_objects[ServiceType.Cab]?.chosen &&
      !this.searchService.search_objects[ServiceType.Coach]?.chosen &&
      !this.searchService.search_objects[ServiceType.Flight]?.chosen &&
      !this.searchService.search_objects[ServiceType.Rail]?.chosen &&
      !this.searchService.search_objects[ServiceType.InternationalRail]?.chosen &&
      !this.searchService.search_objects[ServiceType.Hotel]?.chosen &&
      !this.searchService.search_objects[ServiceType.Car]?.chosen
    );
  }

  setDefaultFilter(services: UserServices) {
    this.searchService.simultaneousSearchesCheck(false);
    const isNoFilterSelected = this.noFilterSelected();
    const servicesKeys = services ? Object.keys(services) : [];
    const startTab = this.userService.getUserFavoriteValue(LightningUserFavorurite.startTab);
    const isStartTabIncluded =
      startTab && servicesKeys.length ? servicesKeys.includes(ServiceType[startTab]) : false;
    if (isNoFilterSelected) {
      if (isStartTabIncluded && startTab) {
        this.selectStartTab(startTab);
      }
      //  else if (!isStartTabIncluded && servicesKeys.length) {
      //   this.setOption(servicesKeys[0], true);
      // }
    }
  }

  toggleOptionAttempt(choice: ServiceType): void {
    const searchObj = this.searchService.search_objects[choice];
    if (
      searchObj.type === ServiceType.Flight &&
      searchObj.chosen === false &&
      this.searchService.hasSimpleGuests()
    ) {
      this.modalService
        .open(LightningModalTypes.ModalGuestTravellerComponent, {}, {})
        .then(success => {
          if (success) {
            this.toggleOption(choice);
          }
        });
      return;
    }
    this.toggleOption(choice);
  }

  /**
  @desc - control for choosing the forms
  **/
  toggleOption(choice: ServiceType): void {
    this.searchService.search_objects[choice].chosen =
      !this.searchService.search_objects[choice].chosen;
    this.searchService.search_objects[choice].originalChosen =
      this.searchService.search_objects[choice].chosen;
    this.storageService.setSessionItem('search_params', this.searchService.search_objects);
    console.log('Current Choices: ', this.searchService.search_objects);
    this.searchService.determineHighestSearchPriority();
    this.setOthersAriaLabel();
  }

  isEmpty(variable: string[]): boolean {
    const isem = _.isEmpty(variable);
    return isem;
  }

  setOption(choice: string, value: boolean): void {
    this.searchService.search_objects[choice].chosen = value;
    this.searchService.search_objects[choice].originalChosen = value;
    this.searchService.search_objects[choice].autosearch = false;
    this.searchService.determineHighestSearchPriority();
  }

  selectedOptions(): ServiceType[] {
    return this.searchService.getSelectedOptions();
  }

  /**
    @desc - start the search - so long as the validation comes back correct - kick off the searches
  **/
  async fetchSearchAll(): Promise<void> {
    // datepicker takes 1.5 seconds to send the date
    setTimeout(async () => {
      await this.searchService.simultaneousSearchesCheck(true);

      // now we need to set eurostar as selected - and change the priority so that it searches at the same time and dosen't change the url

      if (!this.searchService.validateForms() && this.searchForm) {
        this.show_errors = true;
        const context = this;
        setTimeout(function () {
          // Focus if the user is tabbing
          window.scrollTo({ behavior: 'smooth', top: 0 });
          context.searchForm.nativeElement.blur();
          if (
            document.body.classList.contains('user-is-tabbing') &&
            document.getElementById('error-message-list')
          ) {
            document.getElementById('error-alert').style.display = 'block';
            document.getElementById('error-alert').focus();
            setTimeout(function () {
              document.getElementById('error-alert').style.display = 'none';
            }, 1000);
          }
        }, 0);
        return;
      } else {
        this.show_errors = false;
      }
      console.log(this.searchService.search_objects);
      // TODO kick off the searches.
      if (this.searchService.searches[ServiceType.InternationalRail].offlineRequest) {
        this.searchService.startSearches('search');
      } else {
        if (this.checkForHotelReminder() && this.searchService.search_objects.HOTEL.enabled) {
          this.searchService.search_objects[ServiceType.Hotel].chosen = true;
          return this.searchService.startSearches();
        } else {
          return this.searchService.startSearches();
        }
      }
    }, 500);
  }

  getModalHotelNightsParams(outBoundDate: moment.Moment, inboundDate: moment.Moment): number {
    const startOfOutBoundDate = outBoundDate.clone().startOf('day');
    const startInboundDate = inboundDate.clone().startOf('day');
    return Math.abs(startOfOutBoundDate.diff(startInboundDate, 'days'));
  }

  getModalHotelLocationParamFlightEurostar(type: string): string {
    return (this.searchService.searches[type].arriveLocation as OBTAirportDetails)?.destination;
  }

  checkForHotelReminder(): boolean {
    try {
      if (
        this.searchService.search_objects[ServiceType.Flight].chosen &&
        this.hotelsearchRequested
      ) {
        return this.checkDiffBetweenDates(
          this.searchService.searches[ServiceType.Flight].outBoundDate,
          this.searchService.searches[ServiceType.Flight].inboundDate,
          ServiceType.Flight,
          'return'
        );
      } else if (
        this.searchService.search_objects[ServiceType.Eurostar].chosen &&
        this.hotelsearchRequested
      ) {
        return this.checkDiffBetweenDates(
          this.searchService.searches[ServiceType.Eurostar].outBoundDate,
          this.searchService.searches[ServiceType.Eurostar].inboundDate,
          ServiceType.Eurostar,
          'return'
        );
      } else if (
        this.searchService.search_objects[ServiceType.Rail].chosen &&
        this.hotelsearchRequested
      ) {
        return this.checkDiffBetweenDates(
          this.searchService.searches[ServiceType.Rail].outBoundDateTime,
          this.searchService.searches[ServiceType.Rail].inBoundDateTime,
          ServiceType.Rail,
          'ReturnJourney'
        );
      } else if (
        this.searchService.search_objects[ServiceType.Car].chosen &&
        this.hotelsearchRequested
      ) {
        const carSearch = this.searchService.searches[ServiceType.Car];
        return this.checkDiffBetweenDates(
          carSearch.pickup_datetime,
          carSearch.dropoff_datetime,
          ServiceType.Car
        );
      }
      return false;
    } catch {
      return false;
    }
  }

  checkDiffBetweenDates(
    outBoundDate: moment.Moment,
    inboundDate: moment.Moment,
    type: string,
    chosenSearchType: string = null,
  ): boolean {
    return (chosenSearchType  && this.searchService.searches[type].chosenSearchType === chosenSearchType || !chosenSearchType) &&
      (!outBoundDate.isSame(inboundDate, 'date') && inboundDate > outBoundDate) //If they're not on the same date, and inbound is later than outbound, then the stay must be atleast over 1 night
  }

  getSelectedOthers(): string {
    return this.travelServices.value.map(val => this.others()[val]).join(', ');
  }

  /**
   * Fill in selected others
   *
   * @memberof SearchComponent
   */
  fillSelectedOthers(): void {
    Object.values(this.searchService.search_objects).forEach((value: SearchObject) => {
      if (value.chosen && this.others()[value.type]) {
        this.travelServices.value.push(value.type);
      }
    });
  }

  closeValidationErrors(): void {
    this.searchService.show_errors = false;
    this.searchService.traveller_error = false; // Remove all red borders
    /* TODO
    this.searchService.flight.return_error = false;
    this.searchService.flight.departure_error = false;
    this.searchService.rail.departureTime_error = false;
    this.searchService.rail.returnLocation_error = false;
    this.searchService.rail.outboundLocation_error = false;
    this.searchService.hotel.locationPostcode_error = false;
    this.searchService.hotel.rooms_error = false;
    this.searchService.car.pickupTime_error = false;
    this.searchService.car.pickupLocation_error = false;
    this.searchService.car.dropoffLocation_error = false;
    */
  }

  startAgain(): void {
    this.searchService.reset();
  }

  toggleMenu(): void {
    this.basketService.toggleMenu(); // basket controls moved to basket service
    this.toolTipClicked = false;
  }

  setLoadingTravellerProfile(loading: boolean): void {
    this.loadingTravellerProfile = loading;
  }

  onPanelClick(e) {
    e.stopPropagation();
  }

  anyOthersSelect() {
    return (
      // this.searchService.search_objects.airportexpress.chosen ||
      this.searchService.search_objects[ServiceType.Lounge].chosen ||
      this.searchService.search_objects[ServiceType.Parking].chosen ||
      this.searchService.search_objects[ServiceType.InternationalRail].chosen ||
      this.searchService.search_objects[ServiceType.Cab].chosen ||
      this.searchService.search_objects[ServiceType.Coach].chosen ||
      this.searchService.search_objects[ServiceType.FastTrack].chosen ||
      this.searchService.search_objects[ServiceType.Ferry].chosen
    );
  }

  openOthers($event: any) {
    $event.stopPropagation();
    this.othersExpanded = !this.othersExpanded;
  }

  closeOthers() {
    this.othersExpanded = false;
  }

  closeOthersAndFocusBack() {
    this.othersExpanded = false;
    if (document.getElementById('others-expander')) {
      document.getElementById('others-expander').focus();
    }
  }

  closeIfOthersFocusHasLeft() {
    const context = this;
    setTimeout(function () {
      const othersContainer = document.getElementById('others-container');
      if (othersContainer && othersContainer.contains(document.activeElement)) {
        // Everything is fine
      } else {
        context.othersExpanded = false; // Close when focus leaves the list
      }
    }, 0);
  }

  onLoad() {
    this.basketService.menuState = MenuSlideEnum.OUT;
  }

  /**
   * Set user start tab based on user profile
   * Available products are prioritised, so if user can not book option and his
   * profile says set me unavailable option as default first available will be selected.
   **/
  selectStartTab(startTab: string) {
    switch (startTab) {
      case 'Flight':
        this.setOption(ServiceType.Flight, true);
        break;
      case 'Rail':
        this.setOption(ServiceType.Rail, true);
        break;
      case 'InternationalRail':
        this.setOption(ServiceType.InternationalRail, true);
        break;
      case 'Hotel':
        this.setOption(ServiceType.Hotel, true);
        break;
      case 'Car':
        this.setOption(ServiceType.Car, true);
        break;
      case 'Cab':
        this.setOption(ServiceType.Cab, true);
        break;
      case 'Lounge':
        this.setOption(ServiceType.Lounge, true);
        break;
      case 'Parking':
        this.setOption(ServiceType.Parking, true);
        break;
      case 'Eurostar':
        this.setOption(ServiceType.Eurostar, true);
        break;
      case 'Coach':
        this.setOption(ServiceType.Coach, true);
        break;
    }
  }

  openRightPanelMenu(selectedItem: RightPanelMenu) {
    this.selectedRightPanelItem = selectedItem;
    this.menuOpen = false;
    this.showMyBaskets = false;
    this.showRecentSearch = false;
    this.showFrequentTravellers = false;
    document.getElementById('rightPanelMenu').focus();
    switch (selectedItem) {
      case RightPanelMenu.RecentSearches:
        this.showRecentSearch = true;
        break;
      case RightPanelMenu.MyBaskets:
        this.showMyBaskets = true;
        break;
      case RightPanelMenu.FrequentTravellers:
        this.showFrequentTravellers = true;
        break;
      default:
        this.showRecentSearch = true;
    }
  }

  becomingUser(): boolean {
    // return this.userService.becomingUser;
    return false;
  }

  getUserPassports() {
    this.passportErrors = [];
    this.userService.getUserDocumentLoyalty().subscribe((user: any) => {
      user.documents.forEach((document: Document) => {
        if (document.docType === DocType.Passport) {
          const now = moment();
          const expiryDate = moment(document.dateOfExpiry);
          const nowInSixMonths = moment().add(6, 'months');
          if (expiryDate < now) {
            this.passportErrors.push('Your passport has expired, please update your profile.');
          } else if (expiryDate < nowInSixMonths) {
            this.passportErrors.push('Travel Compliance Notification: Please be aware a document within your profile has less than 6 months validity. Please update your profile & update accordingly.');
          }
        }
      })
    });
    if (this.userService.bobo) {
      this.userService.getUserDocumentLoyalty(this.userService.bobo.id).subscribe((user: any) => {
        user.documents.forEach((document: Document) => {
          if (document.docType === DocType.Passport) {
            const now = moment();
            const expiryDate = moment(document.dateOfExpiry);
            const nowInSixMonths = moment().add(6, 'months');
            if (expiryDate < now) {
              this.passportErrors.push(`The passport of ${this.userService.bobo.forename} ${this.userService.bobo.surname} has expired. Please share this message so that the passport information in their profile can be updated`);
            } else if (expiryDate < nowInSixMonths) {
              this.passportErrors.push(`The passport of ${this.userService.bobo.forename} ${this.userService.bobo.surname} has less than 6 months validity. Please share this message so that the passport information in their profile can be updated.`);
            }
          }
        })
      });
    }

  }

  getCarHireLocation(): string {
    const carSearch = this.searchService.searches[ServiceType.Car];
    switch(carSearch.locationType){
      case LocationTypes.Airport:
        return carSearch.dropoff_location?.destination;
      case LocationTypes.City:
        return carSearch.cityDropoff?.name;
      case LocationTypes.Postcode:
        return carSearch.postcodeDropoff?.cityName;
    }
  }

  toggleHotelSearchRequested(requested: boolean) {
    this.hotelsearchRequested = requested;
  }

  setOthersAriaLabel() {
    let newAriaLabel = "";
    OtherItems.forEach((item) => {
      if (this.searchService.search_objects[item]?.chosen) {
        if (newAriaLabel) {
          newAriaLabel += ", "
        } else {
          newAriaLabel += " - "
        }
        newAriaLabel += `${this.getVisualLabelOtherSearchType(item)} selected`;
      }
    });
    this.othersSelectedAriaLabel = newAriaLabel ? newAriaLabel : null;
  }

  getVisualLabelOtherSearchType(serviceType: ServiceType) {
    if (serviceType === ServiceType.Lounge) {
      return "Airport Lounge"
    }
    if (serviceType === ServiceType.Parking) {
      return "Airport Parking"
    }
    if (serviceType === ServiceType.Cab) {
      return "Taxi"
    }
    if (serviceType === ServiceType.Coach) {
      return "Coach"
    }
    if (serviceType === ServiceType.FastTrack) {
      return "Airport Fast Track"
    }
    if (serviceType === ServiceType.InternationalRail) {
      return "European Rail"
    }
    if (serviceType === ServiceType.Ferry) {
      return "Ferry/Eurotunnel"
    }
    if (serviceType === ServiceType.Apartment) {
      return "Apartment"
    }
    if (serviceType === ServiceType.SeasonTicket) {
      return "Season Tickets"
    }
    if (serviceType === ServiceType.MeetingRoom) {
      return "Meeting Room"
    }
  }

}

