import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BasketItem } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-basket-car-hire',
  templateUrl: './basket-car-hire.component.html',
  styleUrls: ['./basket-car-hire.component.scss']
})
export class BasketCarHireComponent {
  @Input() car: BasketItem;
  @Input() duplicateBooking = false;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChangeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }
}
