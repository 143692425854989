import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss'],
})
export class LoadingScreenComponent implements OnInit {
  @Input() icon: string = 'ico-dot';
  @Input() animated: boolean = true;

  constructor() {}

  ngOnInit(): void {
    this.icon = this.transformIcon(this.icon);
    console.log(this.icon);
  }

  transformIcon(icon): string {
    try {
      const type = icon.split('-')[1];
      switch (type) {
        case 'hotel':
        case 'bed':
          return 'ico-bed';
        case 'parking':
        case 'parking':
          return 'ico-parking ico-material';
        case 'lounge':
          return 'ico-lounge ico-material';
        case 'taxi':
          return 'ico-taxi';
        case 'eurostar':
          return 'custom_icon-eurostar';
        case 'fasttrack':
          return 'ico-material ico-fast-track';
        case undefined:
          return 'ico-dot';
        case 'undefined':
          return 'ico-dot';
        default:
          return icon;
      }
    } catch (e) {
      console.warn(
        `Your ${icon} can not be transformed, will use default one!`
      );
      return 'ico-dot';
    }
  }
}
