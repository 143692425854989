<!--Announcements Display-->
<div class="announcement_dropdown_container" #announcementDropdown aria-modal="true">
  <div
    *ngIf="announcements"
    class="announcements_clear_all cross_ann"
    attr.aria-label="Close announcements"
    tabindex="0"
    (click)="closeAnn()"
    (keydown.enter)="closeAnn()"
    role="button">
    <span i18n="@@closeAnnouncements" class="close-icon">Close announcements</span
    ><i aria-hidden="true" class="ico-cancel"></i>
  </div>
  <div class="announcements_clear_all" *ngIf="announcements && showClearAllButton">
    <a
      (click)="clearAllAnnouncements()"
      tabindex="0"
      role="button"
      i18n="@@announcements_clear_all"
      (keydown.enter)="clearAllAnnouncements()"
      >Clear All</a
    >
  </div>

  <div *ngIf="isTravelAdvice">
    <div *ngIf="travelAdvice">
      <div class="announcement_labels">
        <h2 class="announcement_header" i18n="@@announcementsTravelAdvice">Travel Advice</h2>
      </div>
      <div class="travel_advice advice_only">
        <div class="travel-advice-message">
          Due to the frequency of changes in FCO advice on international travel, we highly recommend
          visiting their website for the most up to date travel advice for
          {{ travelAdvice.country }}
        </div>
        <a
          class="more_info"
          target="_blank"
          href="{{ travelAdvice.link }}"
          i18n="@@announcementsMoreInfo"
          >More Info</a
        >
        <div
          class="announcement_close"
          (click)="isTravelAdvice = false"
          (keydown.enter)="isTravelAdvice = false"
          attr.aria-label="Close {{ travelAdvice.country }} travel restriction announcement"
          tabindex="0"
          role="button">
          <i class="ico-cancel"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="announcement" *ngFor="let annPage of announcements | keyValue">
    <h2 class="announcement_header">{{ annPage?.value[0]?.name }}</h2>
    <ul class="announcements_list">
      <ng-container *ngIf="annPage.value">
        <ng-container *ngFor="let ann of annPage.value; let i = index">
          <li>
            <span [innerHTML]="ann?.config?.message | safeHtml : 'html'"></span>
            <div
              class="announcement_close"
              *ngIf="ann.clickToConfirm"
              (click)="deleteAnnouncement(ann.id)"
              (keydown.enter)="deleteAnnouncement(ann.id)"
              attr.aria-label="Close {{ ann?.config?.message }} announcement"
              tabindex="0"
              role="button">
              <i class="ico-cancel"></i>
            </div>
          </li>
          <div *ngIf="ann.userCanSupress && !ann.clickToConfirm">
            <mat-checkbox
              (click)="suppressAnnouncement(ann.id)"
              class="light-accent"
              color="primary"
              id="suppress_announcement"
              i18n="@@suppressAnnouncement"
              aria-label="I have read the message, do not display again">
              I have read the message, do not display again
            </mat-checkbox>
          </div>
        </ng-container>
      </ng-container>
    </ul>
  </div>

  <div class="no_announcements" *ngIf="!announcements" i18n="@@announcementsNoAnnouncements">
    No Announcements to display!
  </div>
</div>
