// import { GoogleMapsAPIWrapper } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Geolocation } from '../../../vendor/classes/geolocation';
import { MapDirectionsService } from '@angular/google-maps';
import { map } from 'rxjs/operators';
declare var google: any;

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.css']
})
export class MapViewComponent implements OnInit {
  // map: GoogleMapsAPIWrapper;
  @Input() center: Geolocation;
  @Input() pins: (Geolocation & { message: string; })[] = [];
  @Input() title: string;
  @Input() icon: string;
  @Input() item;
  @Input() backBtn = true;
  @Input() height = 500;
  @Output() closeMapView = new EventEmitter();
  @Input() bgColor = '#fff';
  @Input() displayRoute = false;
  @Input() zoom: number = 16;
  mapOptions: google.maps.MapOptions = {};
  directionRendered: any;
  directionsService: any;
  directionsResults$: any;

  constructor(
    // public gMaps: GoogleMapsAPIWrapper
    public mapDirectionsService: MapDirectionsService,
  ) { }

  ngOnInit(): void {
    this.mapOptions = {
      center: { lat: this.center.lat, lng: this.center.lng },
      zoom: 13
    };
    if (this.item) {
      this.center = {
        lat: +this.item.rawData.Features.split(',')[0],
        lng: +this.item.rawData.Features.split(',')[1]
      };
      this.title = this.item.provider;
      this.createMarker(this.item.image);
    } else {
      this.createMarker(this.icon);
    }
    if (this.displayRoute) {
      this.setupDirectionsResults();
    }
  }

  public centerMap(pins: any[]): void {
    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
    pins.forEach(pin => {
      bounds.extend((pin as any));
    });
    // this.map.fitBounds(bounds);
  }

  public loadAPIWrapper(map): void {
    // this.map = map;
    const currentZoom = this.zoom;
    const zoomChangeBoundsListener = google.maps.event.addListenerOnce(map, 'bounds_changed', function () {
      if (this.getZoom() > currentZoom) {
        map.setZoom(currentZoom);
      }
      google.maps.event.removeListener(zoomChangeBoundsListener);
    });

    const pins = [this.center, ...this.pins];
    this.centerMap(pins);
    if (pins.length > 1 && this.displayRoute) {
      this.directionsService = new google.maps.DirectionsService();
      this.directionRendered = new google.maps.DirectionsRenderer();
      this.drawRoute((pins as any) as google.maps.LatLng[]);
    }
  }

  private drawRoute(pins: google.maps.LatLng[]): void {
    const request = {
      origin: pins[0],
      destination: pins[pins.length - 1],
      travelMode: google.maps.TravelMode.DRIVING
    };

    this.directionsService.route(request, (response, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        this.directionRendered.setDirections(response);
        // this.directionRendered.setMap(this.map);
      } else {
        console.error(`Directions Request for your route draw failed: ${status}`);
      }
    });
  }

  private createMarker(icon: string) {
    const img = new Image();
    const canvas = document.createElement('canvas');
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

    img.crossOrigin = 'anonymous'; // Set crossOrigin attribute for cross-origin images
    img.src = icon;

    img.onload = () => {
      const markerSize = 70;
      const markerBorder = 5;
      const imgHeight = (markerSize - markerBorder * 2) / img.width * img.height;

      canvas.width = markerSize;
      canvas.height = imgHeight + markerBorder * 2 + 20;

      // draw white marker
      ctx.fillStyle = this.bgColor;
      ctx.shadowBlur = 10;
      ctx.shadowColor = 'gray';
      this.drawPipe(ctx, markerSize, markerBorder, imgHeight, canvas);
      ctx.roundRect(0, 0, canvas.width, imgHeight + markerBorder * 2, 5)?.fill();
      ctx.shadowBlur = 0;
      this.drawPipe(ctx, markerSize, markerBorder, imgHeight, canvas);

      // place and center image in marker created above
      ctx.drawImage(img, markerBorder, markerBorder, markerSize - markerBorder * 2, imgHeight);

      // Return the canvas data URL as a string
      this.icon = canvas.toDataURL();
    };
  }

  private drawPipe(ctx: CanvasRenderingContext2D, markerSize: number, markerBorder: number, imgHeight: number, canvas): void {
    ctx.beginPath();
    ctx.moveTo(markerSize / 2 - 10, imgHeight + markerBorder * 2);
    ctx.lineTo(markerSize / 2 + 10, imgHeight + markerBorder * 2);
    ctx.lineTo(markerSize / 2, canvas.height);
    ctx.fill();
    ctx.closePath();
  }

  private setupDirectionsResults(): void {
    const pins = [this.center, ...this.pins];
    const locations: google.maps.LatLngLiteral[] = pins.map(pin => ({ lat: pin.lat, lng: pin.lng }));
    let waypoints: google.maps.DirectionsWaypoint[] = [];

    locations.slice(1, -1).map(item => {
      waypoints.push({ location: item });
    });

    const request: google.maps.DirectionsRequest = {
      origin: locations[0],
      destination: locations[locations.length - 1],
      waypoints: waypoints,
      travelMode: google.maps.TravelMode.DRIVING
    };
    this.directionsResults$ = this.mapDirectionsService.route(request).pipe(map(response => response.result));
  }
}
