<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
      <h3 class="modal-title"
        i18n="@@modalPasswordExpiredTitle"
        id="modal-header">Password Expired</h3>
    <button type="button"
            class="close"
            aria-label="Recover Password"
            ngbAutoFocus
            i18n-aria-label
            (click)="activeModal.close(false)">
      <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
    </button>
  </div>
  <div class="modal-body">
    <p i18n="@@passwordExpiredInstructions">
      Your password has expired and must be changed
    </p>

  </div>
  <div class="modal-footer">
    <button type="button" aria-labelledby="reset-password"
            class="small-button"
            (click)="activeModal.close(true)"
            i18n="@@modalUserErrorChangePassword">Change Password</button>
  </div>
</div>