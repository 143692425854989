<!-- search-types Templates -->
<ng-template #flightairtemp let-r="result" let-t="term">
  <div class="light-flex">
    <i class="ico-flight item-icon" aria-hidden="true" *ngIf="!r.group"></i>
    <span *ngIf="r.group" class="multiairportgrouping">
      <i class="ico-flight" aria-hidden="true"></i>
    </span>
    <span class="item-code">{{ r.code }}</span>
    <span class="item-dest">{{ r.destination | flightFormat }} ({{ r.gateway }})</span>
  </div>
</ng-template>

<div class="multi-city-container">
  <div class="flight-search-wrapper">
    <fieldset>
      <legend class="visuallyhidden">Flight {{flightIndex + 1}}</legend>
      <div class="flight-search-container">
        <div class="inputs-container">
          <div class="two-thirds-container flex-row-container--start">
            <div class="two-third">
              <div class="flex-row-container">
                <div class="flex-column-container combo-box-left">
                  <label
                    id="outboundFlight"
                    class="header-label"
                    i18n="@@multi-city-outbound-flight"
                    for="travellingFrom"
                    >Outbound Flight Departure</label
                  >
                  <i class="ico-icon-takeoff combo-box-icon" aria-hidden="true"></i>
                  <input
                    autocomplete="off"
                    name="flight{{ flightIndex }}"
                    i18n-placeholder
                    placeholder="Depart from.."
                    type="text"
                    id="travellingFrom"
                    class="input-box"
                    [(ngModel)]="flight.orig"
                    [ngbTypeahead]="flightLocations"
                    [resultTemplate]="flightairtemp"
                    [inputFormatter]="formatter_rail_air"
                    (click)="updateTemp(flightIndex)"
                    (focus)="focus$.next($event.target.value)"
                    (ngModelChange)="updateTemp(flightIndex)" />
                </div>
                <div class="flex-column-container combo-box-right">
                  <label
                  id="outboundFlight"
                  class="header-label"
                  i18n="@@multi-city-outbound-flight"
                  for="travellingFrom"
                  >Outbound Flight Arrival</label
                  >
                  <i class="ico-icon-landing combo-box-icon" aria-hidden="true"></i>
                  <input
                    #ofa
                    autocomplete="off"
                    name="flight{{ flightIndex }}"
                    i18n-placeholder
                    placeholder="Flying to.."
                    type="text"
                    class="input-box"
                    [(ngModel)]="flight.dest"
                    [ngbTypeahead]="flightLocations2"
                    [resultTemplate]="flightairtemp"
                    [inputFormatter]="formatter_rail_air"
                    (click)="updateTemp(flightIndex)"
                    (ngModelChange)="updateTemp(flightIndex)"
                    (focus)="focus2$.next($event.target.value)" />
                </div>
              </div>
            </div>

            <div class="one-third">
              <div class="flex-row-container">
                <ngbd-datepicker-i18n>
                  <div class="flex-column-container combo-box-left">
                    <label class="header-label date-label" i18n="@@flightSearchFlightDate" for="flight_date_{{flightIndex}}"
                      >Flight Date (DD/MM/YYYY)</label
                    >
                    <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"></i>
                    <app-datepicker
                      [minDate]="minDate"
                      [startDate]="date"
                      [model]="date"
                      [class]="'combo-box'"
                      [componentId]="'flight_date_' + flightIndex"
                      (newDate)="updateTemp(flightIndex, $event)">
                    </app-datepicker>
                  </div>
                  <div class="flex-column-container select-box-right">
                    <label
                      i18n="@@AriaFlightOutboundDate"
                      class="header-label" 
                      for="flight_time_{{flightIndex}}"
                      >Flight Time</label
                    >
                    <i class="ico-clock select-box-icon" aria-hidden="true"></i>
                    <select
                      id="flight_time_{{flightIndex}}"
                      class="select-box"
                      [(ngModel)]="time"
                      (ngModelChange)="updateTemp(flightIndex)"
                      aria-labelledby="aria-flight-outbound-time_1"
                      tabindex="0"
                      [ngClass]="{ time_disabled: flight.outTimeCriteria === TimeWindow.Anytime }"
                      [disabled]="flight.outTimeCriteria === TimeWindow.Anytime">
                      <option *ngFor="let time of searchParams.timeArray" [ngValue]="time.value">
                        {{ time.display }}
                      </option>
                    </select>
                  </div>
                  <div class="time-flexibility-container" *ngIf="flight.outTimeCriteria === TimeWindow.Depart">
                    <div class="flex-column-container" >
                      <label
                        i18n="@@flightSearchFlex"
                        class="header-label" 
                        for="flight_flexibility_{{flightIndex}}"
                        >Flexibility</label
                      >
                      <span id="aria-out-time-flexibility-{{flightIndex}}" hidden i18n="@@AriaOutboundIncreaseDecrease">Ability to increase / decrease time window</span>
                      <app-plus-minus-icon></app-plus-minus-icon>
                      <select
                        [ngClass]="{ time_disabled: flight.outTimeCriteria === TimeWindow.Anytime }"
                        [disabled]='flight.outTimeCriteria === TimeWindow.Anytime'
                        name="out-time-flexibility"
                        i18n-placeholder
                        aria-describedby="aria-out-time-flexibility-{{flightIndex}}"
                        class='select-box'
                        [(ngModel)]="flight.outTimeFlexibility"
                        id="flight_flexibility_{{flightIndex}}">
                        <option *ngFor="let hour of flexibilityHours" [value]="hour">{{ hour }}</option>
                      </select>
                    </div>
                  </div>
                </ngbd-datepicker-i18n>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex-row-container flex-row-container--right flex-row-container--tb-margin flex-row-container--reset">
          <mat-radio-group
            [(ngModel)]="flight.outTimeCriteria"
            aria-label="outbound flight time preferences"
            i18n-aria-label="@@AriaOutboundFlightTimePreferences"
            class="triple-radio-buttons">
            <mat-radio-button
              class="light-primary"
              color="primary"
              [value]="flightcrit"
              *ngFor="let flightcrit of criteria">
              {{ flightcrit | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </fieldset>
  </div>

  <div class="remove-icon-wrapper">
    <fa-icon
      *ngIf="flightIndex >= 2"
      (click)="removeFlight(flightIndex)"
      [icon]="faTrash"
      class="fa-fw remove-flight-button"></fa-icon>
  </div>
</div>
