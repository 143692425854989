import { LogonService } from '@sabstravtech/obtservices/angular';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

const GUARD_EXCEPTIONS = [
  // list of exceptions to the guard - anyone can get to these pages, even if not logged in
  '/sso',
  '/login',
];

const sso_re = /^\/sso\/(?:flight|hotel|rail)/;

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(private authService: LogonService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const url: string = state.url;
    return this.checkLogin(url);
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.canActivate(route, state);
  }

  async checkLogin(url: string): Promise<boolean> {
    if (
      GUARD_EXCEPTIONS.includes(url) ||
      sso_re.test(url) ||
      (await this.authService.checkLogin())
    ) {
      return true;
    }
    this.authService.redirectUrl = url;
    this.router.navigate(['/logon']);
    return false;
  }
}
