import { Component, OnDestroy, OnInit, Input, OnChanges } from '@angular/core';
import { CarbonAllowance, EnterpriseBasketService, Basket, WithSubscriptionComponent, BasketStatus, CarbonPolicySource } from '@sabstravtech/obtservices/angular';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-carbon-allowance',
  templateUrl: './carbon-allowance.component.html',
  styleUrls: ['./carbon-allowance.component.scss']
})
export class CarbonAllowanceComponent extends WithSubscriptionComponent implements OnInit, OnDestroy {
  @Input() policy: CarbonAllowance;
  @Input() first: boolean;
  @Input() co2PerItem: number;
  @Input() currentId: string;
  @Input() basket = null;
  startOfPeriod: string;
  endOfPeriod: string;
  remainingCo2Percentage: number;
  policyHolder: string;
  calculatedRemaingCo2Percentage: number;
  adjustedTotal: number;
  loaded = false;
  totalCo2: number;
  CarbonPolicySource: typeof CarbonPolicySource = CarbonPolicySource;
  fetchBasketSubscription: Subscription;

  constructor(private basketService: EnterpriseBasketService) {
    super();
  }

  ngOnInit(): void {
    this.setUpPolicyDetails(this.policy);
    if (this.currentId) {
      // this.basketService.getBasket({ convertCurrencyTo: 'GBP', id: this.currentId, withSupplemental: false }).pipe(take(1)).subscribe(basket => {
      if (this.basket) {
        this.updateAllowanceFromBasket(<Basket>this.basket);
        this.loaded = true;
      }
      // });
    } else {
      this.subscribe(this.basketService.basketChangeEmitter, data => {

        if (this.fetchBasketSubscription) {
          this.fetchBasketSubscription.unsubscribe();
        }

        if (data) {
          setTimeout(() =>
            this.fetchBasketSubscription = this.basketService.getBasketCo2Info({ convertCurrencyTo: 'GBP', id: data, withSupplemental: false }).pipe(take(1)).subscribe(basket => {

              // TODO - use the data here to update the calculated percentage
              this.updateAllowanceFromBasket(<Basket>basket);

            }), 1000);
        }
        this.loaded = true;
      });
    }
    // this.basketService.baskets.subscribe(basket => {
    //   console.log(basket);
    // })
  }

  ngOnDestroy(): void {
    if (this.fetchBasketSubscription) {
      this.fetchBasketSubscription.unsubscribe();
    }
  }

  setUpPolicyDetails(policy: CarbonAllowance) {
    // check that we are within the date range
    // console.log(policy);

    if (moment(policy.endOfPeriod) && moment(policy.startOfPeriod)) {
      this.startOfPeriod = moment.utc(policy.startOfPeriod).format('DD/MM/YYYY');
      this.endOfPeriod = moment.utc(policy.endOfPeriod).format('DD/MM/YYYY');
      this.totalCo2 = policy.totalCo2;
      this.remainingCo2Percentage = policy.remainingCo2 / policy.totalCo2 * 100;
      this.remainingCo2Percentage = Number(this.remainingCo2Percentage.toFixed(2));
      this.calculatedRemaingCo2Percentage = this.remainingCo2Percentage;

      switch (policy.carbonPolicySource) {
        case CarbonPolicySource.Company:
          this.policyHolder = policy.company.name;
          break;
        case CarbonPolicySource.Office:
          this.policyHolder = policy.office.name;
          break;
        case CarbonPolicySource.Division:
          this.policyHolder = policy.division.name;
          break;
        case CarbonPolicySource.User:
          this.policyHolder = `${policy.user.email}`;
          break;
        default:
          console.warn(`+++ Unknown policy source: ${policy.carbonPolicySource} +++`);
      }
    } else {
      console.log('Carbon policy outside of date range');
    }
  }

  updateAllowanceFromBasket(basket: Basket): void {
    this.calculatedRemaingCo2Percentage = this.remainingCo2Percentage;
    let carbonUsed = 0;
    if (!this.basket || (this.basket && this.basket.status !== BasketStatus.Booked)) {
      basket.basketItems.forEach(item => {
        // Not sure if all of the item types in the basket will use the same property
        // as far as I'm aware - only Taxi atm has carbon usage being returned
        // ! - DEPRECIATED - no longer required.
        // if (item.detail.estimatedCO2) {
        //   carbonUsed += item.detail.estimatedCO2;
        // }
        //  we only care if within time period 
        if (moment(item.outboundDate).isAfter(moment(this.policy.startOfPeriod)) && moment(item.outboundDate).isBefore(moment(this.policy.endOfPeriod))) {
          if (item.co2PerItem && this.policy.carbonPolicySource !== CarbonPolicySource.User) {
            carbonUsed += item.co2PerItem;
          }
          if (item.co2PerPassenger && this.policy.carbonPolicySource === CarbonPolicySource.User) {
            carbonUsed += item.co2PerPassenger;
          }
        }
        // switch(item.service.type) {
        //   case 'CAB':
        //     carbonUsed += item.detail.estimatedCO2;
        //   break;
        //   console.warn('+++ Unknown Service Type: ', item.service.type, ' +++');
        // }
      });
    }

    if (carbonUsed) {
      // console.log(`+++ carbon used: ${carbonUsed} +++`);

      this.adjustedTotal = Number((this.policy.remainingCo2 - carbonUsed).toFixed(2));
      // now calculate the carbon used as a percentage
      const carbonUsedPercentage = carbonUsed / this.policy.totalCo2 * 100;
      this.calculatedRemaingCo2Percentage -= carbonUsedPercentage;
      this.calculatedRemaingCo2Percentage = Number(this.calculatedRemaingCo2Percentage.toFixed(2));
    }
  }
  calculatePercentage(): string {
    if (this.co2PerItem) {
      return (this.co2PerItem / this.totalCo2 * 100).toFixed(2);
    } else {
      return '0';
    }
  }
}
