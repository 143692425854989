import { Component, ElementRef, ViewChild } from '@angular/core';
import {
  BasketPanelComponent,
  EnterpriseBasketService,
  EnterpriseSearchService,
  ServiceType,
  UserService,
  ModalOpenerService,
  BasketItem,
  BasketStatus
} from '@sabstravtech/obtservices/angular';
import { InternalBasketInterface } from '@sabstravtech/obtservices/base';
import { Basket } from '@sabstravtech/obtservices/angular';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-basket-panel',
  templateUrl: './basket-panel.component.html',
  styleUrls: ['./basket-panel.component.scss']
})
export class LightningBasketPanelComponent extends BasketPanelComponent {
  travellers = [];
  public ItemTypes: typeof ServiceType = ServiceType;
  public editNameMode: boolean = false;
  public renameString: string = '';
  public multiBasketIndex = 0;
  public maxBasketNumber = 0;
  BasketStatus: typeof BasketStatus = BasketStatus;
  @ViewChild('basketTabGroup') basketTabGroup: MatTabGroup;

  constructor(
    basketService: EnterpriseBasketService,
    userService: UserService,
    searchService: EnterpriseSearchService,
    router: Router,
    modalService: ModalOpenerService
  ) {
    super(basketService, userService, searchService, router, modalService);
    this.maxBasketNumber =
      (
        this.userService.getUserFavoriteObject(
          LightningUserFavorurite.MultiBasketConfiguration
        ) as {
          value: number;
          maxBaskets: number;
          sameTravellers: number;
        }
      )?.maxBaskets ||
      environment.maxBasketNumber ||
      5; // Default to 5 if it's not been set just in case
  }


  // removeChosenBasketType(type: ServiceType){
  //   if (this.basketService.)
  // }

  removeBasketItemCheck(item: BasketItem): void {
    this.removeBasketItem(
      item,
      this.selectedBasket.subject.value.basketItems.filter(a => a.service.type == item.service.type)
        .length != 1
    );
  }

  getItineraryUrl(basketId: string): string[] {
    return ['/itinerary', basketId];
  }

  getMainPageUrl(): string[] {
    return ['/main'];
  }

  changeBasketSelect(data, basket) {
    if (this.selectedBasketIndex !== basket.id) {
      this.changeBasket(data.baskets, basket.id);
      this.selectedBasketIndex = basket.id;
      this.editNameMode = false;
      this.basketTabGroup.focusTab(0);
    }
  }

  closeAndDeleteBasket(id: string) {
    this.editNameMode = false;
    this.basketService.deletingBasket.next(true);
    setTimeout(function(){
      this.removeBasket(id);
    }.bind(this), 2000)
    
  }

  openBasketRename(basket) {
    this.editNameMode = true;
    this.renameString = basket.title;
  }

  saveBasketRename(basket) {
    this.editNameMode = false;
    this.renameBasket(basket.id, this.renameString);
    basket.title = this.renameString;
    this.renameString = '';
  }

  cancelBasketRename() {
    this.editNameMode = false;
    this.renameString = '';
  }

  openMoveBasketModal(basketItem, data) {
    this.modalService.open(
      LightningModalTypes.ModalChangeBasketComponent,
      { centered: true },
      {
        originalBasket: data.selectedBasket,
        baskets: data.baskets,
        basketItem: basketItem,
        basketService: this.basketService,
        selectedBasketId: this.selectedBasketIndex
      }
    );
  }

  addNewBasket(baskets) {
    const newBasketNumber = baskets.length + 1;
    const newBasketString = 'Basket ' + newBasketNumber;
    this.changeBasket(baskets, '-1');
  }

  getSelectedBasketIndex(baskets: any[]): number {
    let result = 0;
    baskets.forEach((item, index: number) => {
      if (item.id === this.selectedBasketIndex) {
        result = index;
      }
    });
    return result;

  }

  get deletingBasket(): boolean {
    return this.basketService.deletingBasket.value;
  }

  async openShareBasket(basket: Basket): Promise<void> {
    console.log(basket);
    const shareResult = await this.modalService.open(LightningModalTypes.ShareBasketModalComponent);
    console.log(shareResult);
    if (shareResult) {
      const shareCallResult = await this.basketService.shareBasket(basket.id, shareResult).toPromise();
      console.log(shareCallResult);
      await this.modalService.open(LightningModalTypes.InfoModalComponent, null, {info: 'Basket shared successfully.', buttonText: 'Ok'});
    }  
  }

}

