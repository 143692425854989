<ng-container
  *ngIf="{
    results: (searchParams.results | async) || [],
    isvalid: searchParams.isValid | async,
    messages: searchParams.messages | async,
    cheapest: searchParams.cheapest | async,
    originalUserSearch: searchParams.originalUserSearch,
    fullWidth:
      searchParams.originalUserSearch.chosenSearchType === RailJourneyType.SingleJourney ||
      searchParams.originalUserSearch.chosenSearchType === RailJourneyType.OpenReturnJourney
  } as data">
  <div class="rail_search_content background-60">
    <app-search-toggle>
      <app-validate-errors [messages]="data.messages"></app-validate-errors>
      <a href="javascript:void(0)" (click)="skipToResultContent()" class="sr-only sr-only-focusable"
        >Skip to Result Content</a
      >
      <app-rail-search></app-rail-search>
      <div class="flex-row-container flex-row-container--right">
        <button
          *ngIf="showDebug"
          class="large-button large-button--search"
          (click)="alwaysLog(data.results[index])">
          Dump
        </button>
        <button
          i18n-title
          class="large-button large-button--search"
          title="Start another search"
          (click)="reSearch()"
          i18n="@@railSearch">
          Search
        </button>
      </div>
    </app-search-toggle>
    <app-risk-alerts [countryCode]="'GB'"></app-risk-alerts>
  </div>
  <div class="flex-row-container carbon_rail_container">
    <button
      class="large-button"
      *ngIf="flightSearchParams.railFromFlightSearchPerformed"
      (click)="switchToFlight()">
      Back to flights
    </button>
    <app-carbon-comparison *ngIf="flightSearchParams.railFromFlightSearchPerformed">
    </app-carbon-comparison>
  </div>
  <section
    *ngIf="
      searchParams.originalUserSearch.chosenSearchType === RailJourneyType.SingleJourney &&
      data.results[index]
    ">
    <div
      appStickyBlock
      [attr.margin]="70"
      [attr.marginFix]="30"
      class="irl-class-header half-container flex-row-container">
      <div class="half-block"></div>
      <div class="half-block flex-row-container">
        <div
          id="fareRulesStand"
          class="irl-class-header_item-class irl-class-header_item-class--2ndclass">
          <span i18n="@@irl2ndclassReturn">2nd Class</span>
        </div>
        <div class="irl-class-header_item-class irl-class-header_item-class--1stclass">
          <span i18n="@@irl1stclassrReturn">1st class</span>
          <section class="container"></section>
        </div>
      </div>
    </div>
    <app-trainline-eu-item
      [item]="item"
      [sourceId]="data.results[index].sourceId"
      *ngFor="let item of data.results[index].outbound"></app-trainline-eu-item>
  </section>
  <section
    *ngIf="
      searchParams.originalUserSearch.chosenSearchType === RailJourneyType.ReturnJourney &&
      data.results[index]
    ">
    <div
      appStickyBlock
      [attr.margin]="70"
      [attr.marginFix]="30"
      class="irl-class-header half-container flex-row-container">
      <div class="half-block">
        <h3 i18n="@@railEuOutbound">Outbound</h3>
      </div>
      <div class="half-block flex-row-container">
        <div
          id="fareRulesStand"
          class="irl-class-header_item-class irl-class-header_item-class--2ndclass">
          <span i18n="@@irl2ndclassReturn">2nd Class</span>
        </div>
        <div class="irl-class-header_item-class irl-class-header_item-class--1stclass">
          <span i18n="@@irl1stclassrReturn">1st class</span>
          <section class="container"></section>
        </div>
      </div>
    </div>
    <app-trainline-eu-item
      [item]="item"
      [type]="RailJourneyType.ReturnJourney"
      [sourceId]="data.results[index].sourceId"
      [clearOutbound]="clearOutbound"
      *ngFor="let item of data.results[index].outbound"
      (fetchInboundTickets)="getInboundSearchResults($event, data.results[index])">
    </app-trainline-eu-item>
  </section>
  <section
    *ngIf="
      searchParams.originalUserSearch.chosenSearchType === RailJourneyType.ReturnJourney &&
      inboundResults
    ">
    <div
      appStickyBlock
      [attr.margin]="70"
      [attr.marginFix]="30"
      class="irl-class-header half-container flex-row-container">
      <div class="half-block">
        <h3 i18n="@@railEuInbound">Inbound</h3>
      </div>
      <div class="half-block flex-row-container">
        <div
          id="fareRulesStand"
          alt="For more information regarding the fare conditions select here"
          class="irl-class-header_item-class irl-class-header_item-class--2ndclass">
          <span i18n="@@irl2ndclassReturn">2nd Class</span>
        </div>
        <div class="irl-class-header_item-class irl-class-header_item-class--1stclass">
          <span i18n="@@irl1stclassrReturn">1st class</span>
          <section class="container"></section>
        </div>
      </div>
    </div>
    <app-trainline-eu-item
      [item]="item"
      [type]="RailJourneyType.ReturnJourney"
      [sourceId]="data.results[index].sourceId"
      [isInbound]="true"
      *ngFor="let item of inboundResults[0].return"
      (fetchInboundTickets)="getInboundSearchResults($event, data.results[index])">
    </app-trainline-eu-item>
  </section>
  <div
    class="no_results_message background-10" role="status"
    *ngIf="
      data.results.length === 0 ||
      data.results[index]?.outbound.length === 0 ||
      (data.results[index]?.outbound.length === 0 && data.results[index]?.return.length === 0)
    ">
    <h2 class="no_results_message_heading"><i class="ico-norail no_results_icon" aria-hidden="true"></i>Oh, no!</h2>
    <p>
      <span i18n="@@railCouldntFind">
        We couldn't find any rail journeys that match your criteria. Try
      </span>
      <strong i18n="@@railCouldntFindB">updating your search</strong>.
    </p>
    <p i18n="@@railContactConsultant">
      If you can't find exactly what you're looking for, contact a consultant.
    </p>
  </div>
</ng-container>
<app-loading-screen *ngIf="loadingInbound">
  <span title i18n="@@itinerary_LoadingInboundTickets"> Loading Inbound Tickets... </span>
</app-loading-screen>

