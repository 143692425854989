import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EnterpriseSearchService, UserService, DocGender, Guest, PassengerType, ServiceType, ModalOpenerService } from '@sabstravtech/obtservices/angular';
import { Traveller, PersonTitles, PersonTitle, ages } from '@sabstravtech/obtservices/base';
import { BaseComponent } from '../../../vendor/components/base_components/base-componet';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { Helpers } from '../../../vendor/classes/helpers';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import moment from 'moment';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-guest-traveller-form',
  templateUrl: './guest-traveller-form.component.html',
  styleUrls: ['./guest-traveller-form.component.scss']
})
export class GuestTravellerFormComponent extends BaseComponent implements OnInit {
  @Output() guest: EventEmitter<Traveller> = new EventEmitter<Traveller>();
  @Input() selectedTravellers: Traveller[];
  /** titles - ask for gender if Dr or Prof chosen */
  readonly titles = PersonTitles;
  public serviceType: typeof ServiceType = ServiceType;
  titlesMap: { [key: string]: boolean; } = {};

  /** genders list */
  readonly genders = ['Female', 'Male'];
  public travelersSameName: boolean = false;
  public isFlightSelected: boolean = false;
  public emailRequired: boolean = true;
  public loading: boolean = false;
  public error: string = '';
  public countries = [];
  private tempCountries = [{ value: 'Honduras', code: 'HN' }, { value: 'Guinea-Bissau', code: 'GW' }];
  selectedGuest = undefined;

  // model variables
  public guest_title: PersonTitle = this.titles[2];
  public guest_first_name: string = '';
  public guest_last_name: string = '';
  public guest_email: string = '';
  public guest_phone: string = '';
  public guest_nationality: string = '';
  public guestMobileCountryNo: string = '';
  public guestMobileNumber: string = '';

  public childAllowed = false;
  public isChild = false;
  public childDOB: NgbDateStruct | null = null;
  public minChildDate: NgbDateStruct | null = null;
  public maxChildDate: NgbDateStruct | null = null;

  public guestDetailsInvalid = true;
  public isInfant: boolean = false;
  public savedGuests: Guest[] = [];
  loadingSavedGuests: boolean = true;
  faTrash = faTrash;
  constructor(
    private searchService: EnterpriseSearchService,
    private userService: UserService,
    public deviceDetector: DeviceDetector,
    title: Title,
    public translateService: TranslateService,
    private modalService: ModalOpenerService
  ) {
    super(title, translateService);
    this.titlesMap = this.titles.reduce((current: { [key: string]: boolean; }, { title: title1, gender }) => {
      current[title1] = !!gender;
      return current;
    }, {});
  }

  ngOnInit(): void {
    this.setTitle(this.translateService.instant('Guest traveller form - LightUk'));

    // if Rail service chosen - show "Traveller is a child" block
    this.childAllowed = this.searchService.search_objects[ServiceType.Rail].chosen;
    if (this.childAllowed) {
      this.setChildDates();
    }

    this.subscribe(this.userService.getUserGuests(), guests => {
      if (!this.searchService.getCurrentNoOfTravellers() || !this.childAllowed) {
        this.savedGuests = guests.filter(guest => guest.guestType === PassengerType.Adult);
      } else {
        this.savedGuests = guests;
      }
      this.loadingSavedGuests = false;
    }, error => {
      console.error(`+++ Error recovering saved guests: ${error} +++`);
      this.loadingSavedGuests = false;
    });
  }

  /**
   * @desc - return wether or not the gender dropdown should be disabled, should be enabled only on non-gendered titles, e.g. Dr
   */
  disableGenderDropdown(): boolean {
    return this.titlesMap[this.guest_title.title];
  }

  /**
   * @desc - create and emit the new guest traveller object
   */
  submitGuest(): void {

    this.error = '';
    if (this.selectedTravellers?.length) {

      const foundExistingTraveller = this.selectedTravellers.find(traveller => (traveller.forename + traveller.surname).toLowerCase().trim()
        === (this.guest_first_name + this.guest_last_name).toLowerCase().trim());

      if (foundExistingTraveller) {
        this.error = 'Travellers with the same name not permitted under same booking.';
        console.error('+++ Traveller Exists on Trip - backing out +++');
        return;
      }

    }
    let existingGuest: Guest;
    if (this.savedGuests?.length) {
      existingGuest = this.savedGuests.find(guest => guest.title === this.guest_title.title &&
        (guest.forename + guest.surname).toLowerCase().trim() === (this.guest_first_name + this.guest_last_name).toLowerCase().trim()
        && guest.email.toLowerCase() === this.guest_email.toLowerCase() &&
        guest.nationality === this.guest_nationality && guest.gender === this.guest_title.gender.substring(0, 1));
    }
    if (!existingGuest) {
      const newGuest = {
        title: this.guest_title.title,
        forename: this.guest_first_name,
        surname: this.guest_last_name,
        nationality: this.guest_nationality,
        email: this.guest_email,
        gender: this.guest_title.gender.toLowerCase() === 'male' ? DocGender.M : DocGender.F,
        // hardcode for now as guestType is mandatory
        guestType: this.isChild ? PassengerType.Child : PassengerType.Adult,
        // this is the date-time format
        dob: this.isChild ? Helpers.formatIsoDate(moment(this.childDOB)) + 'T00:00:00Z' : null,
        countryCode: this.guestMobileCountryNo,
        phoneNumber: this.guestMobileNumber
      };

      console.log('+++ Attempting to add new guest: ', newGuest, ' +++');
      this.loading = true;
      this.subscribe(this.userService.createGuest(newGuest), result => {
        console.log(`+++ Successfully added guest: ${result} +++`);
        const newTraveller: Traveller = {
          isChild: result.guestType === PassengerType.Child ? true : false,
          isInfant: false,
          childDOB: null,
          id: result.id,
          forename: result.forename.trim(),
          guest: true,
          surname: result.surname.trim(),
          title: result.title.trim(),
          email: result.email.trim(),
          name: `${result.forename.trim()} ${result.surname.trim()}`,
          ageType: result.guestType === PassengerType.Child ? ages.Child : ages.Adult,
        };

        // now we need to add the guest to the travellers and navigate back to the main search page
        this.guest.emit(newTraveller);
      }, error => {
        console.error(`+++ Error adding guest: ${error} +++`);
        error = error;
        this.loading = false;
      }, () => {
        this.loading = false;
      });
    }
    else {
      const newTraveller: Traveller = {
        isChild: existingGuest.guestType === PassengerType.Child ? true : false,
        isInfant: false,
        childDOB: existingGuest.dob,
        id: existingGuest.id,
        forename: existingGuest.forename.trim(),
        guest: true,
        surname: existingGuest.surname.trim(),
        title: existingGuest.title.trim(),
        email: existingGuest.email.trim(),
        name: `${existingGuest.forename.trim()} ${existingGuest.surname.trim()}`,
        ageType: existingGuest.guestType === PassengerType.Child ? ages.Child : ages.Adult,
      };

      // now we need to add the guest to the travellers and navigate back to the main search page
      this.guest.emit(newTraveller);
    }

  }


  chooseSavedGuest(guestId: string) {

    const savedGuest = this.savedGuests.find(guest => guest.id === guestId);

    if (this.selectedTravellers?.length) {

      const foundExistingGuest = this.selectedTravellers.find(traveller => (traveller.forename + traveller.surname).toLowerCase().trim()
        === (savedGuest.forename + savedGuest.surname).toLowerCase().trim());

      if (foundExistingGuest) {
        this.error = 'Travellers with the same name not permitted under same booking.';
        console.error('+++ Traveller Exists on Trip - backing out +++');
        return;
      }

    }

    const newTraveller: Traveller = {
      isChild: savedGuest.guestType === PassengerType.Child ? true : false,
      isInfant: false,
      childDOB: savedGuest.dob,
      id: savedGuest.id,
      forename: savedGuest.forename,
      guest: true,
      surname: savedGuest.surname,
      title: savedGuest.title,
      email: savedGuest.email,
      name: `${savedGuest.forename} ${savedGuest.surname}`,
      ageType: savedGuest.guestType === PassengerType.Child ? ages.Child : ages.Adult
    };

    // now we need to add the guest to the travellers and navigate back to the main search page
    this.guest.emit(newTraveller);
  }

  setChildDates() {
    // For Rail a child ticket will need to be booked for ages 5-15
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 15);
    this.minChildDate = Helpers.dateToNgDate(minDate);

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 5);
    this.maxChildDate = Helpers.dateToNgDate(maxDate);
  }

  close(): void {
    this.travelersSameName = false;
  }

  deleteSavedGuest(guestData) {
    this.modalService.open(
      LightningModalTypes.BasicModalComponent,
      { centered: true, backdrop: 'static' },
      {
        title: 'Confirmation',
        body: `Would you like to delete ${guestData.email} from your list of saved guests?`,
        isLoading: false,
        options: {
          buttonCancel: true,
          buttonCancelText: 'No Thanks',
          buttonOk: true,
          buttonOkText: 'Ok'
        }
      }
    )
      .then((response: boolean) => {
        if (response) {
          this.userService.deleteGuest(guestData.id).subscribe(
            resp => {
              if (resp) {
                this.savedGuests.splice(this.savedGuests.indexOf(guestData), 1);
              }
            },
            err => {
              console.log(err.message);
            }
          );
        }
      });
  }
}
