import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasketItem } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-basket-hotel',
  templateUrl: './basket-hotel.component.html',
  styleUrls: ['./basket-hotel.component.scss'],
})
export class BasketHotelComponent implements OnInit {
  @Input() hotel: BasketItem;
  @Input() duplicateBooking = false;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChangeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void { }

  getHotelAddress(): string {
    const { town, county } = this.hotel?.detail?.hotelAddress;
    const hotelAddressArr = [];
    if (town || county) {
      if (town) hotelAddressArr.push(town);
      if (county) hotelAddressArr.push(county);
    }
    return hotelAddressArr.join(', ');
  }

}
