import { Component, HostListener, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DomSanitizer, Title, SafeHtml } from '@angular/platform-browser';
import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { Options } from 'ngx-slider-v2';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { UsStatesPipe } from '../../../startup/pipes/us-states.pipe';
import { LightningUserFavorurite } from '../../../vendor/classes/user-favourite.enum';
import { TranslateService } from '@ngx-translate/core';
import {
  CarhireEnterpriseSearchInterface,
  CarHireFilters,
  LocationTypes,
  SortTypesCar,
  TravelPolicies,
  ServiceProvider
} from '@sabstravtech/obtservices/base';
import {
  CarHireType,
  CarHireDetailRequestResponse,
  CarHireAdditionalInfoItem,
  CarHireIdentifiers,
  EnterpriseBasketService,
  HttpCallService,
  ModalOpenerService,
  EnterpriseSearchService,
  UserService,
  CarHireVehicleAvailibility,
  ServiceType,
  StorageService,
  CurrencyCode,
  CurrencyRates,
  CarHireVendor
} from '@sabstravtech/obtservices/angular';
import { CarLocationsBase } from '../../../vendor/components/base_components/car-locations-base';
import { resultIcons } from '../../../vendor/enum/result-icons.enum';
import { DeviceDetector } from '../../../vendor/services/device-detector.service';
import { Helpers } from '../../../vendor/classes/helpers';
import { Router } from '@angular/router';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.scss']
})
export class CarsComponent extends CarLocationsBase implements OnInit {
  @ViewChildren(NgbInputDatepicker) datepickerList: QueryList<NgbInputDatepicker>;
  ServiceType: typeof ServiceType = ServiceType;
  newSearchSub: Subscription;
  allowNewFilterCreation = true;

  public pickupFocus$ = new Subject<string>();
  public dropoffFocus$ = new Subject<string>();
  public show_errors: boolean = false;
  public errorsList: string[] = [];
  public filterClassString: string = '';
  public notFavourite: number;
  public hiddenElements = [];
  public priceSlidersFocused = false;
  public keyboardPrice: number[];

  public searchParams: CarhireEnterpriseSearchInterface = null;
  public originalSearchDestination: string = '';
  public pickup_tab: boolean = true;
  public showSortOptions: boolean = false;
  public showFilterOptions: boolean = false;
  public sortOption: SortTypesCar = SortTypesCar.price;
  public filterClasses: string[] = [];
  public filterTravelPolicies: string[] = [];
  public filterChains: string[] = [];
  public transmissionString: string = '';
  public fuelString: string = '';
  public carFuelTypes: string[] = [];
  public options: Options;
  public showMoreClass: boolean = false;
  public showSearchForm = false;
  public resultItemType = resultIcons;
  public carTypeCodes: [string, string, CarHireType][];
  public carDetailLoadingStates: BehaviorSubject<boolean>[] = [];
  public carHireAvailabilityDetailsObject: { [key: string]: CarHireDetailRequestResponse } = {};
  results: CarHireVehicleAvailibility[] = [];
  filters: CarHireFilters;
  detailsObject: any = {};
  conversionRates: CurrencyRates;
  preferredCurency: string;
  canOverride: boolean = false;
  SortTypesCar = SortTypesCar;

  @HostListener('document:click', ['$event'])
  public onClick(event: MouseEvent) {
    Helpers.closeOpenCalendars(this.datepickerList, event);
  }
  constructor(
    public deviceDetector: DeviceDetector,
    public searchService: EnterpriseSearchService,
    protected userService: UserService,
    protected modalService: ModalOpenerService,
    protected httpCallService: HttpCallService,
    protected basketService: EnterpriseBasketService,
    protected sanitized: DomSanitizer,
    protected usStatesPipe: UsStatesPipe,
    private storageService: StorageService,
    title: Title,
    public translateService: TranslateService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(
      deviceDetector,
      searchService,
      userService,
      modalService,
      httpCallService,
      basketService,
      sanitized,
      usStatesPipe,
      title,
      translateService
    );
    this.carTypeCodes = this.searchService.searches[ServiceType.Car].carTypeCodes;
  }
  prevLocationType: LocationTypes = LocationTypes.Airport;
  defaultSortingPerformed = false;
  ngOnInit(): void {
    this.canOverride = this.userService.canOverride();
    // super.ngOnInit();
    this.setTitle(this.translateService.instant('Car hire results - LightUk'));
    this.searchParams = this.searchService.searches[ServiceType.Car];
    const preferredVendors = this.userService.getUserFavoriteValue(
      LightningUserFavorurite.PreferredCarVendors
    );
    if (preferredVendors) {
      this.notFavourite = preferredVendors.split('|').length + 1;
    }

    this.sortOption =
      this.userService.getUserFavoriteObject<{ sortOrder: SortTypesCar }>(
        LightningUserFavorurite.CarHireResultsConfiguration
      )?.sortOrder || SortTypesCar.price;

    this.subscribe(this.searchParams.results, (results: CarHireVehicleAvailibility[]) => {
      console.log(results);
      this.results = results;
      const baseCurrencyCode =
        (results[0]?.displayRecord?.charges[0]?.currencyCode as CurrencyCode) || CurrencyCode.Gbp;
      this.searchService
        .getCurrencyConversionRates({ baseCurrencyCode: baseCurrencyCode })
        .subscribe(data => {
          this.conversionRates = data;
        });

      this.showSearchForm = false;
      this.setCarDetailLoadingStates(this.results.length);
      this.setOriginalSearchDestination();
      this.results.forEach((result, i) => {
        this.detailsObject[i] = false;
      });
      this.prevLocationType = this.searchParams.locationType;

      this.createFilters(results);
    });

    const preferredCurencyObject = this.userService.getUserFavoriteObject<{
      carhire: { enable: boolean };
      currency: string;
    }>(LightningUserFavorurite.PreferredCurrency);

    if (
      preferredCurencyObject &&
      preferredCurencyObject.carhire.enable &&
      preferredCurencyObject.currency
    ) {
      this.preferredCurency = preferredCurencyObject.currency;
    }
  }

  formatter_rail_air = (x: { destination: string }) => x.destination; // used for formatting the output on the dropdown

  formatter = (x: { Name: string; Display?: string }) => (x.Display ? x.Display : x.Name);
  postcodeFormatter = (x: { name: string }) => x.name;

  cityFormatter = (x: { name: string; countryCode: string; admin1_code: string }) =>
    x.name + '(' + x.countryCode + ')';

  switchTab(): void {
    this.pickup_tab = !this.pickup_tab;
  }

  setOriginalSearchDestination(): void {
    switch (this.searchParams.locationType) {
      case LocationTypes.Airport:
        this.originalSearchDestination = `
          ${this.searchParams.pickup_location?.destination}
          (${this.searchParams.pickup_location?.gateway}),
          ${this.searchParams.pickup_location?.country}
        `;
        break;
      case LocationTypes.City:
        this.originalSearchDestination = this.searchParams?.cityPickupDepot
          ? this.searchParams?.cityPickupDepot?.addressLines[0]
          : this.searchParams.cityPickup?.name;
        break;
      case LocationTypes.Postcode:
        this.originalSearchDestination = this.searchParams?.postcodeDropoff
          ? this.searchParams?.postcodeDropoff?.addressLines[0]
          : this.searchParams.postcodePickupRaw?.name;
        break;
      default:
        console.warn(
          `Unhandled location type: '${this.searchParams.locationType}' in setOriginalSearchDestination()`
        );
    }
  }

  /**
    @desc - sets the carDetailLoadingStates array of BehaviourSubject's to false, 1 array element for each result.
    @param numResults for how many array elements to add
  **/
  setCarDetailLoadingStates(numResults: number): void {
    this.carDetailLoadingStates = [];
    for (let i = 0; i < numResults; i++) {
      this.carDetailLoadingStates.push(new BehaviorSubject(false));
    }
  }

  getCarDetails(index: number): void {
    this.getCarHireAvailabilityDetail(this.results[index], index);
  }

  reSearch(): void {
    let prechosehotel = false;

    if (this.searchService.search_objects[ServiceType.Hotel].chosen) {
      this.searchService.search_objects[ServiceType.Hotel].chosen = false;
      prechosehotel = true;
    }

    if (this.searchService.validateForms()) {
      this.clearFilters();
      this.searchService.search_objects[ServiceType.Car].chosen = true;
      this.searchService.determineHighestSearchPriority();
      this.searchService.search_objects[ServiceType.Hotel].chosen = prechosehotel;
      this.searchService.startSearches();
    } else {
      this.show_errors = true;
    }
  }

  closeValidationErrors(): void {
    this.show_errors = false;
  }

  toggleSortOptions(): void {
    this.showSortOptions = !this.showSortOptions;
    this.showFilterOptions = false;
  }

  sliderOptions(): Options {
    return {
      floor: Math.floor(this.searchService.searches[ServiceType.Car].getMinPrice(this.results)),
      ceil: Math.ceil(this.searchService.searches[ServiceType.Car].getMaxPrice(this.results)),
      step: 1
    };
  }

  /**
    @desc - resort the results either by price (cheapest first) or by brand (alphabetical)
  **/
  sortBy(sortType: string): void {
    this.defaultSortingPerformed = true;
    switch (sortType) {
      case 'Cheapest':
        sortType = SortTypesCar.price;
        break;
      case 'Chain':
        sortType = SortTypesCar.brand;
        break;
      case 'Lowest Emissions':
        sortType = SortTypesCar.co2;
        break;
      default:
        break;
    }
    this.sortOption = <SortTypesCar>sortType.toLowerCase();

    switch (this.sortOption) {
      case SortTypesCar.price:
        this.searchService.searches[ServiceType.Car].sortCheapest();
        break;
      case SortTypesCar.brand:
        this.searchService.searches[ServiceType.Car].sortByBrand();
        break;
      case SortTypesCar.expensive:
        // ! added because go2
        this.searchService.searches[ServiceType.Car].sortExpensive();
        break;
      case SortTypesCar.size:
        this.searchService.searches[ServiceType.Car].sortBySize();
        break;
      case SortTypesCar.co2:
        this.searchService.searches[ServiceType.Car].sortByCo2();
        break;
      case SortTypesCar.preferred:
        // ! to implement this will become direct method
        this.searchService.searches[ServiceType.Car].sortResults(2);
        break;
      default:
        // console.log(sortType);
        console.log('Unknown sort type!');
    }
    this.showSortOptions = false;
  }

  /**
    @desc - filter the class according to the type passed in
  **/
  filterClass(type: string): void {
    const index = this.filterClasses.indexOf(type);
    if (index >= 0) {
      this.filterClasses = this.filterClasses
        .slice(0, index)
        .concat(this.filterClasses.slice(index + 1));
    } else {
      this.filterClasses.push(type);
    }
  }

  filterChain(chain: string): void {
    const index = this.filterChains.indexOf(chain);
    if (index >= 0) {
      this.filterChains = this.filterChains
        .slice(0, index)
        .concat(this.filterChains.slice(index + 1));
    } else {
      this.filterChains.push(chain);
    }
  }

  filterTransmission(trans: string): void {
    console.log('attempting to filter transmissions!');
    if (this.transmissionString === trans) {
      this.transmissionString = '';
    } else {
      this.transmissionString = trans;
    }
  }

  filterFuel(fuel: string): void {
    if (this.fuelString === fuel) {
      this.fuelString = '';
    } else {
      this.fuelString = fuel;
    }
  }

  filterTravelPolicy(type: string): void {
    const index = this.filterTravelPolicies.indexOf(type);
    if (index >= 0) {
      this.filterTravelPolicies = this.filterTravelPolicies
        .slice(0, index)
        .concat(this.filterTravelPolicies.slice(index + 1));
    } else {
      this.filterTravelPolicies.push(type);
    }
  }

  matchesFilterClassTypeString(classType: string): boolean {
    const index = this.filterClasses.indexOf(classType);
    if (this.filterClasses.length === 0) {
      return true;
    }
    if (index >= 0) {
      return true;
    }
    return false;
  }

  matchesTransmission(transmissionType: string): boolean {
    if (transmissionType?.toLowerCase() === this.transmissionString || !this.transmissionString) {
      return true;
    }

    return false;
  }

  matchesTravelPolicy(result: CarHireVehicleAvailibility): boolean {
    const requiresReason = result.unavailable
      ? TravelPolicies.UNAVAILABLE
      : result.requiresReasonKeys.length
      ? TravelPolicies.ALLOW_WITH_REASON
      : TravelPolicies.IN_POLICY;

    if (this.filterTravelPolicies.length === 0) {
      return true;
    }

    // needs sorting
    const filteredTravelPolicies = this.filterTravelPolicies.find(
      (policy: string) => policy.toString().toLowerCase() === requiresReason.toLowerCase()
    );
    if (filteredTravelPolicies) {
      return true;
    }

    return false;
  }

  matchesFuel(type: string): boolean {
    if (type === this.fuelString || !this.fuelString) {
      return true;
    }

    return false;
  }

  inRange(price: number | string): boolean {
    const priceVal = Number(price);

    if (priceVal >= this.filters.minPrice && priceVal <= this.filters.maxPrice) {
      return true;
    }

    return false;
  }

  matchesChainFilter(vendor: string): boolean {
    const index = this.filterChains.indexOf(vendor);
    if (this.filterChains.length === 0) {
      return true;
    }
    if (index >= 0) {
      return true;
    }
    return false;
  }

  inFilters(result: CarHireVehicleAvailibility): boolean {
    let inFilters = true;
    const vehicle = result.displayRecord.vehicle;
    if (
      this.inRange(result.displayRecord.charges[0].amount) &&
      this.matchesTransmission(vehicle.transmission) &&
      this.matchesTravelPolicy(result) &&
      this.matchesFilterClassTypeString(`${vehicle.class}_${vehicle.type}`) &&
      this.matchesChainFilter(result.displayRecord.vendor.name) &&
      this.matchesFuel(vehicle.fuel)
    ) {
    } else {
      inFilters = false;
    }
    return inFilters;
  }

  clearFilters(): void {
    this.filterClasses = [];
    this.filterTravelPolicies = [];
    this.filterChains = [];
    this.transmissionString = '';
    // ! set max and min prices to some sort of variable here
    this.filters.minPrice = this.sliderOptions().floor;
    this.filters.maxPrice = this.sliderOptions().ceil;
  }

  closeFiltersOptions(): void {
    this.showFilterOptions = false;
    this.ariaToggleHideBackground();
  }

  /**
  @desc - sets up filters in a new way using new obtservices, still filtering the old way
  @param - all searrch results
  **/
  createFilters(allResults: CarHireVehicleAvailibility[]): void {
    const newFilters: CarHireFilters = {
      minPrice: Math.floor(this.searchService.searches[ServiceType.Car].getMinPrice(allResults)),
      maxPrice: Math.ceil(this.searchService.searches[ServiceType.Car].getMaxPrice(allResults)),
      transmissions: [
        {
          display: 'Automatic',
          value: 'A',
          selected: false
        },
        {
          display: 'Manual',
          value: 'M',
          selected: false
        }
      ],
      travelPolicy: [
        {
          display: TravelPolicies.IN_POLICY,
          value: TravelPolicies.IN_POLICY,
          selected: false
        },
        {
          display: TravelPolicies.ALLOW_WITH_REASON,
          value: TravelPolicies.ALLOW_WITH_REASON,
          selected: false
        }
      ]
    };
    const carTypes = {};
    this.carFuelTypes = [];

    try {
      allResults.forEach(car => {
        const vehicle = car.displayRecord.vehicle;
        const filterValue = `${vehicle.class}_${vehicle.type}`;
        const filterDisplay = `${vehicle.class} ${this.getTypeForDisplayFromResult(car)}`;
        carTypes[filterValue] = {
          selected: false,
          value: filterValue,
          display: filterDisplay
        };

        if (this.carFuelTypes.indexOf(vehicle.fuel) === -1) {
          this.carFuelTypes.push(vehicle.fuel);
        }
      });
    } catch (error) {
      console.error(`+++ Error generating car types filters: ${error} +++`);
    }

    const carCompanies = {};
    try {
      allResults.forEach(car => {
        carCompanies[car.identifiers.vendor] = {
          selected: false,
          value: car.identifiers.vendor,
          display: car.displayRecord.vendor.name
        };
      });
    } catch (error) {
      console.error(`+++ Error generating car companies filters: ${error} +++`);
    }

    newFilters.types = Object.values(carTypes);
    newFilters.companies = Object.values(carCompanies);

    newFilters.companies = newFilters.companies.sort((a, b) => {
      if (a.display < b.display) {
        return -1;
      }
      if (a.display > b.display) {
        return 1;
      }
      return 0;
    });
    this.filters = newFilters;
  }

  /**
    @desc - add a car to the basket
  **/
  addToCart(item: CarHireVehicleAvailibility): void {
    this.searchService.searches[ServiceType.Car].addCarHireToBasket(
      item,
      this.searchService.searches[ServiceType.Car].getSearchQuery()
    );
    this.basketService.toggleMenu();
  }

  moveArrowDown(e: Event): void {
    e.preventDefault(); // prevents scrolling
    (<HTMLElement>document.activeElement.nextElementSibling).focus();
  }

  moveArrowUp(e: Event): void {
    e.preventDefault(); // prevents scrolling
    (<HTMLElement>document.activeElement.previousElementSibling).focus();
  }

  skipToResultContent() {
    const focusable = document
      .getElementById('car-list')
      .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    // @ts-ignore
    focusable[0].focus();
    return false;
  }

  trapFocusReverse(event): void {
    event.preventDefault();
    const focusable = document
      .getElementById('filters')
      .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    // @ts-ignore
    focusable[focusable.length - 1].focus();
  }

  ensureElementIsScrolledTo(event) {
    try {
      const typeAheadList = event.target.nextElementSibling;
      const activeButton = typeAheadList.getElementsByClassName('active')[0];
      if (
        activeButton.offsetTop + activeButton.clientHeight >
        typeAheadList.clientHeight + typeAheadList.scrollTop
      ) {
        typeAheadList.scrollTop =
          activeButton.offsetTop + activeButton.clientHeight - typeAheadList.clientHeight;
      } else if (activeButton.offsetTop < typeAheadList.scrollTop) {
        typeAheadList.scrollTop = activeButton.offsetTop;
      }
    } catch (e) {
      // tslint:disable-next-line: quotemark
      console.log("Couldn't find elements to scroll");
    }
  }

  trapFocus(event, i, array): void {
    if (i === array.length - 1) {
      event.preventDefault();
      const focusable = document
        .getElementById('filters')
        .querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        );
      // @ts-ignore
      focusable[0].focus();
    }
  }

  ariaToggleHideBackground(): void {
    if (this.showFilterOptions) {
      this.hiddenElements = [];
      const selection = document.querySelectorAll('*');
      const wrapper = document.getElementById('car-hire-filters-wrapper');
      for (let x = 0; x < selection.length; x++) {
        if (
          wrapper &&
          selection[x] !== wrapper &&
          !wrapper.contains(selection[x]) &&
          !selection[x].contains(wrapper) &&
          (!selection[x].getAttribute('aria-hidden') ||
            selection[x].getAttribute('aria-hidden') === 'false')
        ) {
          selection[x].setAttribute('aria-hidden', 'true');
          this.hiddenElements.push(selection[x]);
        }
      }
    } else {
      for (let x = 0; x < this.hiddenElements.length; x++) {
        this.hiddenElements[x].setAttribute('aria-hidden', 'false');
      }
    }
  }

  addAriaLabels() {
    if (this.showFilterOptions) {
      setTimeout(function () {
        const sliders = document.querySelectorAll('[role="slider"]');
        for (let x = 0; x < sliders.length; x++) {
          if (x === 0) {
            sliders[x].setAttribute('aria-label', 'Price Range Lower Bound');
            sliders[x].setAttribute('tabindex', '-1');
          }
          if (x === 1) {
            sliders[x].setAttribute('aria-label', 'Price Range Higher Bound');
            sliders[x].setAttribute('tabindex', '-1');
          }
        }
      }, 0);
    }
  }

  keyboardCheckBound(value, sliderMin, sliderMax) {
    return !(value >= sliderMin && value <= sliderMax);
  }

  getCo2Usage(result: CarHireVehicleAvailibility): string {
    try {
      return result.co2PerItem.toFixed(2);
    } catch (error) {}
    return '0.00';
  }

  /**
    @desc - sets the showSearchForm property according to the state
    @param state boolean representing the current state
  **/
  toggleSearchForm(state: boolean): void {
    this.showSearchForm = state;
  }

  /**
    @desc - for a given result return the type string for vanity purposes for a vehicle (utilising carTypeCodes in obtservices)
    @param result of type CarHireVehicleAvailibility
    @returns the car type string e.g. '2-3 Door' instead of 'TwoToThreeDoor' raw type
  **/
  getTypeForDisplayFromResult(result: CarHireVehicleAvailibility): string {
    const typeForDisplay = this.carTypeCodes.find(
      carTypeCode => carTypeCode[2] === result?.displayRecord?.vehicle?.type
    );
    if (typeForDisplay) {
      return typeForDisplay[1];
    }
  }

  /**
    @desc - check if a key exists in the carHireAvailabilityDetailsObject
    @return boolean
  **/
  carHireAvailabilityDataExists(key: string): boolean {
    return key in this.carHireAvailabilityDetailsObject;
  }

  /**
    @desc - for a given result retrieve extra details (e.g. availability detail)
    @param result of type CarHireVehicleAvailibility
  **/
  getCarHireAvailabilityDetail(result: CarHireVehicleAvailibility, resultIndex: number): void {
    // remove the '__typename' property from identifiers object as this will stop the BE call working
    const { __typename, ...identifiers } = result.identifiers;

    if (result.source === ServiceProvider.TravelportCarHire) {
      const detailResult: CarHireDetailRequestResponse = {
        additionalInfo: result.displayRecord.additionalInfo,
        address: result.displayRecord.pickupLocation,
        identifiers: result.identifiers,
        vendor: result.displayRecord.vendor
      };
      detailResult.additionalInfo?.sort((a, b) =>
        a.name > b.name ? 1 : a.name === b.name ? 0 : -1
      );
      // move the number to the beginning of the array
      let phone = detailResult.additionalInfo?.find(
        (item: CarHireAdditionalInfoItem) => item.name === 'Contact Numbers'
      );
      detailResult.additionalInfo?.sort((a, b) => (a === phone ? -1 : b == phone ? 1 : 0));

      // store result in an object so we don't have to load the same data again if it's been previously loaded
      this.updateCarHireAvailabilityDetailsObject(result, {
        key: this.createKeyFromIdentifiers(identifiers),
        value: detailResult
      });
    } else {
      const vendor = identifiers.vendor === 'Enterprise' ? 'ET' : identifiers.vendor;
      this.searchService.searches[ServiceType.Car]
        .getCarHireAvailabilityDetail(
          {
            dropOffLocation: identifiers.dropOffLocation,
            dropOffDateTime: identifiers.dropOffDateTime,
            pickUpDateTime: identifiers.pickUpDateTime,
            pickUpExtendedLocationCode: identifiers.pickUpExtendedLocationCode,
            pickUpLocationCode: identifiers.pickUpLocationCode,
            pickUpLocationName: identifiers.pickUpLocationName,
            locator: '',
            rph: '',
            vehType: identifiers.vehType,
            vendor: vendor,
            service: result.source
          },
          this.carDetailLoadingStates[resultIndex]
        )
        .subscribe(fetchedResult => {
          fetchedResult.additionalInfo.sort((a, b) =>
            a.name > b.name ? 1 : a.name === b.name ? 0 : -1
          );
          // move the number to the beginning of the array
          let phone = fetchedResult.additionalInfo.find(
            (item: CarHireAdditionalInfoItem) => item.name === 'Contact Numbers'
          );
          fetchedResult.additionalInfo.sort((a, b) => (a === phone ? -1 : b == phone ? 1 : 0));

          // store result in an object so we don't have to load the same data again if it's been previously loaded
          this.updateCarHireAvailabilityDetailsObject(result, {
            key: this.createKeyFromIdentifiers(identifiers),
            value: fetchedResult
          });
        });
    }
  }

  /**
    @desc - for a provided identifiers param return a key string by combining the 'vendor' and 'vehType' properties
    @return string containing key e.g. 'vendor_vehType'. This will be used in carHireAvailabilityDetailsObject
  **/
  createKeyFromIdentifiers(identifiers: CarHireIdentifiers): string {
    return `${identifiers.vendor}_${identifiers.vehType}`;
  }

  /**
    @desc - update the carHireAvailabilityDetailsObject with the provided key and value
    @param data object containing key value pair
  **/
  updateCarHireAvailabilityDetailsObject(
    result: CarHireVehicleAvailibility,
    data: {
      key: string;
      value: CarHireDetailRequestResponse;
    }
  ): void {
    this.carHireAvailabilityDetailsObject[data.key] = data.value;
    this.modalService.open(
      LightningModalTypes.ModalCarDetailsComponent,
      { centered: true },
      {
        result: result,
        carHireAvailabilityDetailsObject: this.carHireAvailabilityDetailsObject,
        carTypeCodes: this.carTypeCodes
      }
    );
  }

  showSpecialFares(car: CarHireVehicleAvailibility) {
    return car.fareInfo.type === 'Private';
  }

  getConvertedPrice(price: string): string {
    return this.preferredCurency && this.conversionRates
      ? (+price * this.conversionRates[this.preferredCurency]).toFixed(2)
      : price;
  }

  get dropoffCountry() {
    switch (this.prevLocationType) {
      case LocationTypes.City:
        return this.searchParams.cityDropoff.countryCode;
      case LocationTypes.Airport:
        return this.searchParams.dropoff_country;
      case LocationTypes.Postcode:
        return this.searchParams.postcodeDropoff.countryCode;
    }
  }

  switchToHotel() {
    this.searchService.search_objects[ServiceType.Flight].chosen = false;
    const searchObj = this.searchService.init_search_objects();
    const hotelResultUrl = searchObj.HOTEL.resultUrl;
    this.searchService.search_objects[ServiceType.Hotel].priority = searchObj.HOTEL.priority;
    this.searchService.search_objects[ServiceType.Hotel].resultUrl = hotelResultUrl;
    this.router.navigate([hotelResultUrl]);
  }

  setFocus(string) {
    setTimeout(() => {
      this.document.getElementById(string).focus();
    }, 0);
  }
}

