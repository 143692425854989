import { Component, OnInit, OnDestroy,ViewChild, ElementRef, AfterViewInit, HostListener, Inject } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EnterpriseBasketService, EnterpriseSearchService, ModalOpenerService ,WithSubscriptionComponent} from '@sabstravtech/obtservices/angular';
import { DDAService } from '../../../vendor/services/dda.service';
import { MenuSlideEnum } from '@sabstravtech/obtservices/base';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';
import { WCAGFocusHandler } from '../../../vendor/services/wcag-focus-handler.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        MenuSlideEnum.IN,
        style({
          transform: 'translate3d(-100%, 0, 0)'
        })
      ),
      state(
        MenuSlideEnum.OUT,
        style({
          transform: 'translate3d(0, 0, 0)'
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ]
})
export class ResultsComponent extends WithSubscriptionComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    public basketService: EnterpriseBasketService,
    public searchService: EnterpriseSearchService,
    public ddaService: DDAService,
    private modalOpenerService: ModalOpenerService,
    private wcagFocusHandlerService: WCAGFocusHandler,
    @Inject(DOCUMENT) private document: Document
  ) {super() }

  @ViewChild('main') main: ElementRef;
  @ViewChild('basket') basket: ElementRef;
  @ViewChild('basketExpanderButton') basketExpanderButton: ElementRef;

  toolTipText = 'View your basket';
  toolTipClicked = false;
  subscribedToTimeout = false;
  MenuSlideEnum = MenuSlideEnum;

  get basketCounter(): number {
    return this.basketService.getNumberItemsForBasket(null);
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    if (target.className === 'basket-button' || target.className === 'cart') {
      return;
    } else if (this.toolTipClicked === true) {
      if (this.document.getElementsByTagName('ngb-modal-window').length === 0) { //Don't toggle menu if modal is open
        this.toggleMenu();
      }
    } else if (this.basketService.menuState === 'in') {
      this.toolTipClicked = true;
    }
  }

  ngOnInit(): void {
    const context = this;
    this.subscribe(this.basketService.basketChangeEmitter, (id: string) => {
      this.basketService.getRequiresReasonService().currentBasketid = id;
      this.basketService.getRequiresOverrideReasonService().currentBasketid = id;
    })
    this.subscribe(this.basketService.highlightBasketItemsEmitter, (newItems) => {
      if (this.document.body.classList.contains('user-is-tabbing') && this.document.getElementsByTagName('app-requires-reason-dialog').length === 0) { //Ensure that we only open when user is tabbing and reason dialog isn't open
        this.modalOpenerService.open(LightningModalTypes.ModalScreenreaderBasketComponent, { centered: true });
      }
    })
    // this.searchService.searchTimedOut.subscribe(function (timedOut: boolean) {
    //   if (timedOut && context.subscribedToTimeout) {
    //     context.modalOpenerService.open(LightningModalTypes.ModalSearchTimeoutComponent, { centered: true }, {});
    //   }
    //   context.subscribedToTimeout = true; //Set to true after initial subscription
    // });
    this.basketService.getDefaultBasket();
    this.wcagFocusHandlerService.skipToBasketClicked.subscribe(() => {
      this.basketExpanderButton.nativeElement.focus();
    })
  }

  ngOnDestroy() {
    this.basketService.getRequiresReasonService().currentBasketid = null;
    this.basketService.getRequiresOverrideReasonService().currentBasketid = null;
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    this.ddaService.main = this.main;
    this.ddaService.basket = this.basket;
  }

  toggleSearchTab(): void { }

  toggleMenu(): void {
    this.basketService.toggleMenu();
    this.toolTipClicked = false;
  }

  isSearching(): boolean {
    return false;
  }

  onPanelClick(e) {
    e.stopPropagation();
  }
}
