<ng-template #emailrt let-r="result" let-t="term">
    <div class="item-search-select">
        <span class="item-dest">{{r.surname}}, {{r.forename}} {{r.email }}</span>
    </div>
</ng-template>
<form #shareForm="ngForm">
    <div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            i18n="
            @@modalShareBasketTitle"
            id="modal-header">Share Basket With: <i class="ico-info" ngbTooltip="You are only able to share your baskets with colleagues within your company."></i></h3>
        <button
            type="button"
            class="close"
            aria-label="Close"
            ngbAutoFocus
            (click)="activeModal.close()">
            <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
        </button>
    </div>

    <div class="modal-body">
        <h4>Email</h4>
        <div class="share-email-container" *ngFor="let user of shareUsers; let i = index">
            <input
                aria-label="Email"
                class="input-box input-box--border" 
                placeholder="Enter an email to share with..."
                [ngbTypeahead]="searchTravellers"
                [resultTemplate]="emailrt"
                [inputFormatter]="userFormatter"
                i18n-placeholder
                [(ngModel)]="user.user"
                name="emailLookup{{i}}"
                required
                [container]="body"
            >
            <i class="ico-cancel remove-email-button" aria-label="Remove Email" *ngIf="i" (click)="removeEmail(i)"></i>
        </div>
        <button class="small-button" aria-label="Add another email" (click)="addAnotherEmail()">Add another email</button>
    </div>
  <div class="modal-footer">
    <button type="button"
        aria-label="Cancel"
        class="small-button small-button--close"
        (click)="activeModal.close()"
        (keydown.enter)="activeModal.close()"
        (keydown.space)="activeModal.close()"
        i18n="@@modalCancel">Cancel</button>
    <button type="button"
        id="button_ok"
        class="small-button"
        aria-label="Share"
        i18n-aria-label
        aria-live="false"
        [disabled]="!valid()"
        (click)="submit()"
        (keydown.enter)="submit()"
        (keydown.space)="submit()"
        i18n="@@modalShare">
        Share
    </button>
  </div>
</div>
</form>
