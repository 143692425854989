import { Component } from '@angular/core';
import {
  OBTRailJourneyOption,
  OBTRailQuoteResult,
  RailEnterpriseSearchInterface,
  RailSearchCriteria,
  OBTRailFareTypes,
  CheapestRailPrices,
  CheapestRailClassPrices,
  FlightEnterpriseSearchInterface
} from '@sabstravtech/obtservices/base';
import {
  EnterpriseSearchService,
  ModalOpenerService,
  RailSearchJourneyType,
  ServiceType,
  WithSubscriptionComponent
} from '@sabstravtech/obtservices/angular';
import moment from 'moment';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';
import { Router } from '@angular/router';
import { result } from 'lodash';
import { Title } from '@angular/platform-browser';

export enum EarlierOrLater {
  EARLIER = 'earlier',
  LATER = 'later'
}

@Component({
  selector: 'app-rail',
  templateUrl: './rail.component.html',
  styleUrls: ['./rail.component.scss']
})
export class RailComponent extends WithSubscriptionComponent {
  searchParams: RailEnterpriseSearchInterface;
  RailJourneyType = RailSearchJourneyType;
  RailSearchCriteria = RailSearchCriteria;
  flightSearchParams: FlightEnterpriseSearchInterface;
  ServiceType: typeof ServiceType = ServiceType;
  EarlierOrLater = EarlierOrLater;

  expandedTickets: Record<OBTRailFareTypes, Record<string, boolean>> = {
    returnJourneyFares: {},
    openReturnJourneyFares: {},
    dualSingleJourneyFares: {},
    singleJourneyFares: {},
    splitFares: {}
  };

  constructor(
    private modalService: ModalOpenerService,
    private searchService: EnterpriseSearchService,
    private router: Router,
    private titleService: Title
  ) {
    super();
    this.searchParams = this.searchService.searches[ServiceType.Rail];
    this.flightSearchParams = this.searchService.searches[ServiceType.Flight];
    this.makeFareNames = this.makeFareNames.bind(this);
  }

  ngOnInit(): void {
    this.titleService.setTitle('Rail results - LightUk');
    this.expandedTickets = {
      returnJourneyFares: {},
      openReturnJourneyFares: {},
      dualSingleJourneyFares: {},
      singleJourneyFares: {},
      splitFares: {}
    };
  }

  cheapestReturn(results: OBTRailQuoteResult): number[] {
    return [];
  }

  skipToResultContent() {
    const focusable = document
      .getElementById('trains-link')
      .querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    // @ts-ignore
    focusable[0].focus();
    return false;
  }

  clearHighlights(): void {
    const elemsWithHighlight = document.getElementsByClassName('highlight');
    Array.from(elemsWithHighlight).forEach(elemWithHighlight => {
      elemWithHighlight.classList.remove('highlight');
    });
  }

  getCheapestDualOutbound(cheapest: {
    outboundCheapest: CheapestRailPrices;
    inboundCheapest: CheapestRailPrices;
    cheapestClassTicket: CheapestRailClassPrices;
    cheapestClassTicketReturn: CheapestRailClassPrices;
    cheapestClassTicketDual: CheapestRailClassPrices;
  }): number {
    const outbounds: number[] = [
      cheapest.outboundCheapest.dualSingleJourneyFares.price,
      cheapest.cheapestClassTicketDual.standardClassFare.price
    ].filter(item => item > 0);
    return Math.min(...outbounds);
  }

  getCheapestDualInbound(cheapest: {
    outboundCheapest: CheapestRailPrices;
    inboundCheapest: CheapestRailPrices;
    cheapestClassTicket: CheapestRailClassPrices;
    cheapestClassTicketReturn: CheapestRailClassPrices;
    cheapestClassTicketDual: CheapestRailClassPrices;
  }): number {
    const inbounds = [
      cheapest.inboundCheapest.dualSingleJourneyFares.price,
      cheapest.cheapestClassTicketDual.inboundStandardClassFare.price
    ].filter(item => item > 0);
    return Math.min(...inbounds);
  }

  highlightCheapestTickets(cheapestClasses: string[]): void {
    this.clearHighlights();
    if (cheapestClasses.length) {
      let scrolled = false;
      cheapestClasses.map((cheapestClass, index) => {
        const elemsWithCheapestClass = document.getElementsByClassName(cheapestClass);
        Array.from(elemsWithCheapestClass).forEach(elemWithCheapestClass => {
          elemWithCheapestClass.classList.add('highlight');
          if (index === cheapestClasses.length - 1) {
            elemWithCheapestClass.scrollIntoView({ block: 'center' });
            (elemWithCheapestClass as HTMLElement).focus();
            scrolled = true;
          }
        });
        if (!scrolled && index === cheapestClasses.length - 1) {
          //Ensures we scroll to the highlighted elements if it's missed during the last loop
          const elemsWithHighlight = document.getElementsByClassName('highlight');
          if (elemsWithHighlight.length) {
            elemsWithHighlight[elemsWithHighlight.length - 1].scrollIntoView({
              block: 'center'
            });
            (elemsWithHighlight[elemsWithHighlight.length - 1] as HTMLElement).focus();
          }
        }
      });
    }
  }
  resolveTabbingHighlight(): void {
    setTimeout(function () {
      document.body.classList.add('user-is-tabbing');
    }, 300);
  }

  reSearch(): void {
    localStorage.setItem('prevURL', window.location.href); // prevent going back to wellbeing which will no longer have correct results
    this.searchService.search_objects[ServiceType.Rail].chosen = true;
    this.searchService.determineHighestSearchPriority();
    this.searchService.startSearches();
  }

  getNextResultsPage(
    datetime: string,
    timeCriteria: RailSearchCriteria,
    firstJourney: OBTRailJourneyOption,
    lastJourney: OBTRailJourneyOption,
    type: EarlierOrLater,
    inbound?: boolean
  ): void {
    const fares = Object.keys(firstJourney.returnJourneyFares).length
      ? firstJourney.returnJourneyFares
      : Object.keys(firstJourney.singleJourneyFares).length
      ? firstJourney.singleJourneyFares
      : Object.keys(firstJourney.dualSingleJourneyFares).length
      ? firstJourney.dualSingleJourneyFares
      : Object.keys(firstJourney.openReturnJourneyFares).length
      ? firstJourney.openReturnJourneyFares
      : Object.keys(firstJourney.splitFares).length
      ? firstJourney.splitFares
      : null;

    const fareValues = Object.values(fares);

    fareValues.forEach((fareValue: any[]) => {
      fareValue.forEach(fare => {
        if (fare && !this.searchParams.transactionIdentifier) {
          this.searchParams.transactionIdentifier = fare.identifiers.transactionIdentifier;
        }
      });
    });

    /**
     * * The below code is not an idea solution to define the best time window to move earlier
     * * Even we add or subtract 10/15 more minutes but the providers may still return the journey which was in the previous result.
     */
    if (inbound) {
      this.searchParams.returnCriteria = timeCriteria;
      if (type === EarlierOrLater.EARLIER) {
        // Get the time diff by comparing the depature time between the first journey and the last journey in the current result
        const earliestJourney = firstJourney.departDateTime;
        const latestJourney = lastJourney.departDateTime;
        const diff = moment(latestJourney).diff(moment(earliestJourney), 'minutes');

        this.searchParams.inBoundDateTime = moment(datetime)
          .subtract(diff || 60, 'minutes') // go back 60 minutes by default if only one journey in the current result
          .subtract(15, 'minutes'); // Subtract 15 more minutes to get rid the earliest journey in the previous result
      } else {
        // Add 10 more minutes to get rid the latest journey in the previous result
        this.searchParams.inBoundDateTime = moment(datetime).add(10, 'minutes');
      }
    } else {
      this.searchParams.outboundCriteria = timeCriteria;
      if (type === EarlierOrLater.EARLIER) {
        // Get the time diff by comparing the depature time between the first journey and the last journey in the current result
        const earliestJourney = firstJourney.departDateTime;
        const latestJourney = lastJourney.departDateTime;
        const diff = moment(latestJourney).diff(moment(earliestJourney), 'minutes');

        this.searchParams.outBoundDateTime = moment(datetime)
          .subtract(diff || 60, 'minutes') // go back 60 minutes by default if only one journey in the current result
          .subtract(15, 'minutes'); // Subtract 15 more minutes to get rid the earliest journey in the previous result
      } else {
        // Add 10 more minutes to get rid the latest journey in the previous result
        this.searchParams.outBoundDateTime = moment(datetime).add(10, 'minutes');
      }
    }

    if (this.searchParams.isValid.value) {
      this.searchService.search_objects[ServiceType.Rail].chosen = true;
      this.searchService.determineHighestSearchPriority();
      this.searchService.startSearches();
    } else {
      this.modalService.open(
        LightningModalTypes.ModalErrorComponent,
        { centered: true },
        { error: 'Search params appear to be invalid' }
      );
    }
  }

  getDifferentDayPage(
    type: EarlierOrLater,
    inbound?: boolean
  ): void {

    if (inbound) {
      if (type === EarlierOrLater.EARLIER) {
        this.searchParams.inBoundDateTime = this.searchParams.inBoundDateTime.subtract(1, 'day');
      } else {
        this.searchParams.inBoundDateTime = this.searchParams.inBoundDateTime.add(1, 'day');
      }
    } else {
      if (type === EarlierOrLater.EARLIER) {
        this.searchParams.outBoundDateTime = this.searchParams.outBoundDateTime.subtract(1, 'day');
      } else {
        this.searchParams.outBoundDateTime = this.searchParams.outBoundDateTime.add(1, 'day');
      }
    }

    if (this.searchParams.isValid.value) {
      this.searchService.search_objects[ServiceType.Rail].chosen = true;
      this.searchService.determineHighestSearchPriority();
      this.searchService.startSearches();
    } else {
      this.modalService.open(
        LightningModalTypes.ModalErrorComponent,
        { centered: true },
        { error: 'Search params appear to be invalid' }
      );
    }
  }

  makeFareNames(journeys: OBTRailJourneyOption[]): Record<OBTRailFareTypes, string[]> {
    return [
      'returnJourneyFares',
      'openReturnJourneyFares',
      'dualSingleJourneyFares',
      'singleJourneyFares',
      'splitFares'
    ].reduce(
      (
        accum: Record<OBTRailFareTypes, string[]>,
        next: OBTRailFareTypes
      ): Record<OBTRailFareTypes, string[]> => {
        accum[next] = journeys?.length > 0 ? Object.keys(journeys[0][next]) : [];
        return accum;
      },
      {
        returnJourneyFares: [],
        openReturnJourneyFares: [],
        dualSingleJourneyFares: [],
        singleJourneyFares: [],
        splitFares: []
      }
    );
  }

  switchToFlight() {
    this.searchService.search_objects[ServiceType.Rail].chosen = false;
    this.router.navigate([this.searchService.search_objects[ServiceType.Flight].resultUrl]);
  }

  getFinalDisplayedResultIndex(resultBlock: OBTRailJourneyOption[]): number {
    if (window.screen.width <= 600) { //If mobile we only display 3 columns
      //If there are less than 3 results, get the last index, if not get the 3rd
      return resultBlock.length < 3 ? resultBlock.length - 1 : 2;
    } else { //Otherwise we display all
      return resultBlock.length - 1;
    }
  }

  switchToHotel(){
    this.searchService.search_objects[ServiceType.Flight].chosen = false;
    const searchObj = this.searchService.init_search_objects();
    const hotelResultUrl = searchObj.HOTEL.resultUrl;
    this.searchService.search_objects[ServiceType.Hotel].priority = searchObj.HOTEL.priority;
    this.searchService.search_objects[ServiceType.Hotel].resultUrl = hotelResultUrl;
    this.router.navigate([hotelResultUrl]);
  }
}

