import { Component, Input, OnInit } from '@angular/core';
import { ParkingComponent } from '../parking.component';
import { EnterpriseBasketService, ServiceType, ParkingQuote, EnterpriseSearchService, UserService } from '@sabstravtech/obtservices/angular';
import { ActivatedRoute } from '@angular/router';
import { resultIcons } from '../../../../vendor/enum/result-icons.enum';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-parking-list-view',
  templateUrl: './parking-list-view.component.html',
  styleUrls: ['./parking-list-view.component.scss']
})
export class ParkingListViewComponent extends ParkingComponent implements OnInit {
  @Input() data: any;
  @Input() canOveride: boolean = false;
  resultItemType = resultIcons;
  constructor(
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    public route: ActivatedRoute,
    public basketService: EnterpriseBasketService,
    public translateService: TranslateService,
    public title: Title
  ) {
    super(searchService, userService, route, basketService, translateService, title);
  }

  ngOnInit(): void { }

  viewMapPage(item: ParkingQuote) {
    this.searchService.searches[ServiceType.Parking].listViewToggle.next(item);
  }
}
