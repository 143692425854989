<!-- <app-theme></app-theme> -->
<router-outlet></router-outlet>
<cookie-component></cookie-component>

<app-modal-opener></app-modal-opener>

<ng-container *ngIf="{loading: basketService.addingToBasket | async} as data">
   <app-loading-screen *ngIf="data.loading">
      <span title i18n="@@basket_adding_item"> Adding item to the basket... </span>
   </app-loading-screen>
</ng-container>

<ng-container *ngIf="{loading: basketService.creatingNewBasket | async} as data">
   <app-loading-screen *ngIf="data.loading">
      <span title i18n="@@basket_creating_basket"> Creating new basket... </span>
   </app-loading-screen>
</ng-container>

<ng-container *ngIf="{loading: basketService.removingFromBasket | async} as data">
   <app-loading-screen *ngIf="data.loading">
      <span title i18n="@@basket_loading_title_remove"> Removing item from the basket... </span>
   </app-loading-screen>
</ng-container>

<ng-container *ngIf="{loading: searchService.gettingFlightClasses | async} as data">
   <app-loading-screen *ngIf="data.loading">
      <span title i18n="@@basket_loading_title_get"> Getting flight classes... </span>
   </app-loading-screen>
</ng-container>
<ng-container *ngIf="{loading: searchService.repricingFlight | async} as data">
   <app-loading-screen *ngIf="data.loading">
      <span title i18n="@@basket_loading_title_reprice"> Repricing Flight... </span>
   </app-loading-screen>
</ng-container>
<button *ngIf="displayScrollButton" (click)="scrollToTop()" aria-label="Scroll back to top" class="scrolltop_button">
   <i class="ico-chevron-up"></i>
 </button>