import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import moment from 'moment';
import _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { OBTFlightCabinClass, OBTAirportDetails, TerminalsType } from '@sabstravtech/obtservices/base';
import { AbstractControl } from '@angular/forms';
import { ServiceType } from '@sabstravtech/obtservices/angular';

// declare var hex_hmac_md5: any;

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const full_months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const full_weekdays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const FLIGHT_CLASSES = [
  {
    fareBasis: OBTFlightCabinClass.EconomyNoBags,
    branded: OBTFlightCabinClass.EconomyNoBagsBranded,
    showClass: 'showEconomyNoBags',
    title: 'Economy No Bags'
  },
  {
    fareBasis: OBTFlightCabinClass.EconomyRestricted,
    branded: OBTFlightCabinClass.EconomyRestrictedBranded,
    showClass: 'showEconomyRestricted',
    title: 'Economy Restricted',
  },
  {
    fareBasis: OBTFlightCabinClass.EconomyFlexi,
    branded: OBTFlightCabinClass.EconomyFlexiBranded,
    showClass: 'showEconomyFlexi',
    title: 'Economy Flexi',
  },
  {
    fareBasis: OBTFlightCabinClass.PremiumEconomy,
    branded: OBTFlightCabinClass.PremiumEconomyBranded,
    showClass: 'showPremium',
    title: 'Premium',
  },
  {
    fareBasis: OBTFlightCabinClass.Business,
    branded: OBTFlightCabinClass.BusinessBranded,
    showClass: 'showBusiness',
    title: 'Business',
  },
  {
    fareBasis: OBTFlightCabinClass.First,
    branded: OBTFlightCabinClass.FirstBranded,
    showClass: 'showFirst',
    title: 'First',
  }
];
export class Helpers {
  static READ_DATE_FORMAT = 'ddd, D MMM YYYY';
  static READ_TIME_FORMAT = 'HH:mm';
  static DATE_FORMAT = 'YYMMDD';
  static ISO_DATA_FORMAT = 'YYYY-MM-DD';
  static ISO_DATA_FORMAT_TIME = 'YYYY-MM-DD HH:mm';
  static ISO_DATA_FORMAT_NO_HYPHANS = 'YYYYMMDD';
  static TIME_FORMAT = 'HHmm';
  static TIME_FORMAT_COLON = 'HH:mm';
  static MMDDYY_DATE = 'DDMMYYYY';
  static SCRATCHPAD_DATE = 'DD/MM/YYYY HH:mm';

  static toScratchpadDate(date: string): string {
    return moment(date).format(Helpers.SCRATCHPAD_DATE);
  }

  static dateToNgDate(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }



  static formatTimeColon(date: moment.Moment | string): string {
    return moment(date).format(Helpers.TIME_FORMAT_COLON);
  }

  static formatIsoDate(date: moment.Moment | string): string {
    return moment(date).format(Helpers.ISO_DATA_FORMAT);
  }


  /**
   * Generates array of times in format MM:mm
   * @param from where to start default 0
   * @param to where to end default 24
   * @param step in minutes
   */
  static generateTimesList(from = 0, to = 24, step = 15): string[] {
    const dateTimeArray: string[] = [];
    for (let hour = 0; hour < to - from; hour++) {
      for (let minute = 0; minute < 60; minute += step) {
        dateTimeArray.push(moment({ hour, minute }).format('HH:mm'));
      }
    }
    return dateTimeArray;
  }

  static momentToNgDate(date: moment.Moment): NgbDateStruct {
    return Helpers.dateToNgDate(date.toDate());
  }

  static ngDateToDate(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  static ngDateToMoment(date: NgbDateStruct, time?: string): moment.Moment {
    const mom = moment(Helpers.ngDateToDate(date));
    if (time) {
      const times = time.split(':');
      if (times.length === 2) {
        mom.set('hours', Number(times[0])).set('minutes', Number(times[1]));
      } else {
        mom
          .set('hours', Number(time.substring(0, 2)))
          .set('minutes', Number(time.substring(2, 4)));
      }
    }
    return mom;
  }

  static todayToNgbDateStruct(): NgbDateStruct {
    const today = moment();
    return { year: today.year(), month: today.month() + 1, day: today.date() };
  }


  static yyyymmddToDate(value: string): Date {
    return new Date(
      Number(value.substring(0, 4)),
      Number(value.substring(4, 6)) - 1,
      Number(value.substring(6, 8))
    );
  }

  static yyyymmddToMoment(value: string): moment.Moment {
    return moment(this.yyyymmddToDate(value));
  }


  static yyyymmddToNgDate(value: string): NgbDateStruct {
    return Helpers.dateToNgDate(this.yyyymmddToDate(value));
  }


  static dateStringToyyyymmdd(d: string): string | null {
    if (typeof d !== 'string') {
      return null;
    }
    const m = /(\d{4})[-\/]?(\d{2})[-\/]?(\d{2})/.exec(d);
    return m ? `${m[1]}${m[2]}${m[3]}` : null;
  }


  static stringToBool(s: string): boolean {
    return s && s !== 'false' && s !== '0' && s !== 'no' && s !== 'n';
  }

  static closeOpenCalendars(datepickerList, event) {
    (datepickerList || []).forEach((datepickerInput) => {
      if (!datepickerInput.isOpen()) {
        datepickerInput['readyToClose'] = false;
        return;
      }
      if (
        !datepickerInput['_cRef'].location.nativeElement.contains(
          event.target
        ) &&
        datepickerInput['readyToClose']
      ) {
        datepickerInput.close();
        datepickerInput['readyToClose'] = false;
      } else {
        setTimeout(() => {
          datepickerInput['readyToClose'] = true;
        });
      }
    });
  }

  static clone<T = any>(toClone: T): T {
    return <T>_.cloneDeep(toClone);
  }

  static replaceKeysInText(text: string, replacements): string {
    return Object.keys(replacements).reduce((current, key) => {
      return current.replace('%' + key, replacements[key]);
    }, text);
  }

  /**
   * @description - get the next fifteen mins
   * @package date - a moment date - defaults to now
   */
  static getNextMinuets(date: moment.Moment = moment(), minutesToRound = 15) {
    date.minute(Math.ceil(date.minute() / minutesToRound) * minutesToRound);
    return date.format('HH:mm');
  }

  /********************************************************************************************
   * Below are functions needed for generating the hashed number once token, taken as required	*
   * Don't Mess with these																		*
   ********************************************************************************************/
  // static getNumberOnceValue(token): string {
  //   const random_value = Helpers.guid();
  //   return random_value + '_' + hex_hmac_md5(token, random_value);
  // }

  static guid(): any {
    let random_hex = '';

    for (let i = 0; i < 32; i++) {
      random_hex += Helpers.s4();
    }

    return random_hex;
  }

  static s4(): any {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  static capitaliseFirstLetter(word: string): string {
    if (word.length > 0) {
      return word[0].toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return '';
    }
  }

  static fromObject(...args: any[]): any {
    let data = args.shift();
    for (let index = 0; data && index < args.length; index += 1) {
      data = data[args[index]];
    }
    return data;
  }

  static momentRoundMinutes(time: moment.Moment, round: number): moment.Moment {
    time = moment(time).startOf('minutes');
    return time.subtract(time.minutes() % round, 'minute');
  }

  static icsFold(body: string): string {
    const lines = body.split(/\r\n/g);
    return lines
      .map((line) => {
        let res = '';
        const n = 72;
        line = line.trim();
        while (line.length > n) {
          res += `${line.slice(0, n)}\r\n`;
          line = ` ${line.slice(n).trim()}`;
        }
        if (line.trim()) {
          res += line;
        }
        return res;
      })
      .join('\r\n');
  }

  static splitAddressLine(
    line: string,
    len: number
  ): { line: string; residue: string; } {
    const res = {
      line: line.replace(/\s+/g, ' ').trim(),
      residue: '',
    };
    if (res.line.length > len) {
      let i = res.line.slice(0, len).lastIndexOf(',');
      if (i > 0) {
        res.residue = res.line.slice(i + 1).trim();
        res.line = res.line.slice(0, i).trim();
      } else {
        i = res.line.slice(0, len).lastIndexOf(' ');
        if (i > 0) {
          res.residue = res.line.slice(i + 1).trim();
          res.line = res.line.slice(0, i);
        } else {
          res.residue = res.line.slice(len);
          res.line = res.line.slice(0, len);
        }
      }
    }
    return res;
  }

  static daysBetween(start: moment.Moment, end: moment.Moment): number {
    return end.diff(start, 'days');
  }

  // For each element of a, execute f, which may return a promise. If max is provided, this is the maximum number of promises to run simultaneously.
  // Return promise which resolves to an array of results. If any promise rejects the returned value resolves to the first promise rejected.

  static mapPromise<T = any, R = any>(
    a: T[],
    f: (val: T, index: number) => R | Promise<R>,
    max?: number
  ): Promise<R[]> {
    const it = a[Symbol.iterator]();
    const result = [];
    const buffer = [];
    let i = 0;
    const nextf = () => {
      const next = it.next();
      if (next.done) {
        return;
      }
      const index = i++;
      return Promise.resolve(f(next.value, index)).then((val) => {
        result[index] = val;
        return nextf();
      });
    };
    while (!max || buffer.length <= max) {
      const n = nextf();
      if (n) {
        buffer.push(n);
      } else {
        break;
      }
    }
    return Promise.all(buffer).then(() => {
      return result;
    });
  }

  static mapSeriesPromise<T = any, R = any>(a: T[], f): Promise<R[]> {
    return this.mapPromise(a, f, 1);
  }

  static obj2queryString(obj: any): string {
    return _.reduce(
      obj,
      (a, v, k) => {
        if (a !== '') {
          a += '&';
        }
        return `${a}${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
      },
      ''
    );
  }

  static stripPunctuation(s: string): string {
    return s.replace(/[^a-zA-Z0-9\s]/g, '');
  }

  static replaceAll(
    haystack: string,
    needle: string,
    replacement: string
  ): string {
    return haystack
      ? haystack.replace(new RegExp(needle, 'g'), replacement)
      : null;
  }

  static dateTime(sabsDate, sabsTime): any {
    const tempDate = sabsDate.match(/.{1,2}/g); // splits every 2 characters
    const tempTime = sabsTime.match(/.{1,2}/g);

    const newStr =
      '20' +
      tempDate[0] +
      '-' +
      tempDate[1] +
      '-' +
      tempDate[2] +
      'T' +
      tempTime[0] +
      ':' +
      tempTime[1] +
      ':00';
    return newStr;
  }

  static bookingDateTime(sabsDate: string, translate: TranslateService) {
    try {
      let jsDate = null;
      let timeSplit: string[] = null;
      if (moment.isMoment(sabsDate)) {
        jsDate = sabsDate.toDate();

        timeSplit = sabsDate.format('HH:mm').split(':');
      } else {
        sabsDate = sabsDate || '';
        let dateTimeSplit = sabsDate.split(' ');
        if (dateTimeSplit.length === 1) {
          dateTimeSplit = sabsDate.split('T');
        }

        const dateSplit: number[] = dateTimeSplit[0]
          .split('-')
          .map((item: string) => Number(item));

        timeSplit = dateTimeSplit[1]?.split(':');

        jsDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
      }
      return (
        full_weekdays[jsDate.getDay()] +
        ', ' +
        jsDate.getDate() +
        ' ' +
        full_months[jsDate.getMonth()] +
        ' ' +
        jsDate.getFullYear() +
        ` at ` +
        timeSplit[0] +
        ':' +
        timeSplit[1]
      );
    } catch (err) {
      console.error(err, sabsDate);
    }
    return 'ERROR';
  }

  static bookingDateAnytime(sabsDate: string, translate: TranslateService) {
    try {
      let jsDate = null;
      let timeSplit: string[] = null;
      if (moment.isMoment(sabsDate)) {
        jsDate = sabsDate.toDate();

        timeSplit = sabsDate.format('HH:mm').split(':');
      } else {
        sabsDate = sabsDate || '';
        let dateTimeSplit = sabsDate.split(' ');
        if (dateTimeSplit.length === 1) {
          dateTimeSplit = sabsDate.split('T');
        }

        const dateSplit: number[] = dateTimeSplit[0]
          .split('-')
          .map((item: string) => Number(item));

        timeSplit = dateTimeSplit[1].split(':');

        jsDate = new Date(dateSplit[0], dateSplit[1] - 1, dateSplit[2]);
      }

      return (
        translate.instant(full_weekdays[jsDate.getDay()]) +
        ', ' +
        jsDate.getDate() +
        ' ' +
        translate.instant(full_months[jsDate.getMonth()]) +
        ' ' +
        jsDate.getFullYear() +
        ', ' + translate.instant('Anytime')
      );
    } catch (err) {
      console.error(err, sabsDate);
    }
    return 'ERROR';
  }

  static bookingDate(sabsDate: string, translate: TranslateService) {
    const tempDate = sabsDate
      .match(/.{1,2}/g)
      .map((item: string) => Number(item)); // splits every 2 characters
    const jsDate: Date = new Date(
      tempDate[0] + 2000,
      tempDate[1] - 1,
      tempDate[2]
    );

    return (
      jsDate.getDate() +
      ' ' +
      translate.instant(months[jsDate.getMonth()]) +
      ' ' +
      jsDate.getFullYear()
    );
  }

  static formatDateShort(sabsDate: string, translate: TranslateService) {
    const momentDate = this.yyyymmddToMoment(sabsDate);

    return (
      translate.instant(weekdays[momentDate.day()]) +
      ', ' +
      momentDate.date() +
      ' ' +
      translate.instant(months[momentDate.month()]) +
      ' ' +
      momentDate.year()
    );
  }

  static translateCodes(
    codes: {
      code: string;
      name: string;
    }[],
    translateService: TranslateService
  ): {
    code: string;
    name: string;
  }[] {
    return codes.map((code: { code: string; name: string; }) => {
      if (code.name === 'Any') {
        code.name = translateService.instant('Any');
      }
      return code;
    });
  }

  static translateArrayCode(
    codes: string[][],
    translateService: TranslateService
  ): string[][] {
    return codes.map((code: string[]) => {
      if (code[1] === 'Any') {
        code[1] = translateService.instant('Any');
      }
      return code;
    });
  }

  static alternateJSONStringify(jsonObject: any): string {
    let jsonString = JSON.stringify(jsonObject);
    jsonString = jsonString.replace(/"/g, '`');
    return jsonString;
  }

  // Convert object to array
  static ConvertObjectToArray(object: Object): any[] {
    let array = _.values(object);
    array = _.flatten(array);
    return array;
  }
  /**
    @desc - helper function add a day from a ngbDateStruct
  **/
  static addDay(dateStruct): any {
    const date = new Date(
      dateStruct.year,
      dateStruct.month - 1,
      dateStruct.day + 1
    );
    const retDate = {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
    //// console.log(retDate);
    //// console.log(date);
    return retDate;
  }

  static hashCode(toCode: string): string {
    let hash = 0;

    for (let i = 0; i < toCode.length; i += 1) {
      const char = toCode.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }
    return hash.toString();
  }

  static MMddyyToNgb(date: string): NgbDateStruct {
    return Helpers.momentToNgDate(moment(date, Helpers.MMDDYY_DATE));
  }

  /**
   * @description A pure function to mutate and filter an array and produce ;
   * @param array;
   * @param value;
   */
  static uniqBy(array: any[], value: string) {
    return _.uniqBy(array, value);
  }

  static getReturnUrl(): string {
    return environment.hostBaseUrl && environment.hostBaseUrl.length
      ? environment.hostBaseUrl
      : window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port === '80' ? '' : ':' + window.location.port);
  }

  static transform(array: any[], callback, acc: any): any[] {
    return _.transform(array, callback, acc);
  }

  static arrayToObject<T>(array: T[], field: string): { [key: string]: T; } {
    return array
      .filter((data: T) => !!data)
      .reduce((configs: { [key: string]: T; }, configItem: T) => {
        if (configItem && configItem[field]) {
          configs[configItem[field].toLowerCase()] = configItem;
          return configs;
        }
      }, {});
  }



  static fieldFromObjectArray<T>(obj: object, field: any[]): T {
    return (field || []).reduce((current: object, field: string) => {
      if (current && current[field]) {
        return current[field];
      }
      return null;
    }, obj);
  }

  static setFormValues<T extends object>(form: any, obj: T) {
    if (!obj) {
      Object.keys(form.value).forEach((key: string) => {
        const control: AbstractControl = form.controls[key];
        if (control) {
          control.setValue(null);
        }
      });
    } else {
      Object.keys(obj).forEach((key: string) => {
        const control: AbstractControl = form.controls[key];
        if (control) {
          control.setValue(obj[key]);
        }
      });
    }
  }


}
