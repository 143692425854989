<ng-template #userDetails let-user="user">
    <span *ngIf="user">{{ user.email }}</span>
    <span *ngIf="!user" i18n="@@diu-nouser">No booking for user</span>
</ng-template>

<div cdkTrapFocus aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title" i18n="@@diu-header" id="modal-header">
            Warning - Different Booking For Users
        </h3>
        <button
            type="button"
            class="close"
            aria-label="Cancel"
            ngbAutoFocus
            (click)="activeModal.close(DifferentInvokdedUserReasons.cancel)">
            <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div>
            <span i18n="@@diu-body1">The current booking for user ( </span>
            <ng-container
                [ngTemplateOutlet]="userDetails"
                [ngTemplateOutletContext]="{ user:  currentBobo}"></ng-container>
            <span i18n="@@diu-body2"> ) and the one assigned to the basket ( </span>
            <ng-container
                [ngTemplateOutlet]="userDetails"
                [ngTemplateOutletContext]="{ user: basketBobo }"></ng-container>
            <span i18n="@@diu-body3"> ) are different.</span>
        </div>
        <div>
            <span i18n="@@diu-body4">
                These need to be the same. Please choose one of the options below
            </span>
            <ul>
                <li i18n="@@diu-body-newbasket">Create a new basket with this booking for user.</li>
                <li i18n="@@diu-body-research">
                    Change the current booking for user and perform the search again.
                </li>
                <li i18n="@@diu-body-cancel">Cancel adding the item to the basket.</li>
            </ul>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="modal_button_okay"
            (click)="activeModal.close(DifferentInvokdedUserReasons.newBasket)"
            i18n="@@diu-newBasket">
            Create new basket
        </button>
        <button
            type="button"
            class="modal_button_okay"
            (click)="activeModal.close(DifferentInvokdedUserReasons.research)"
            i18n="@@diu-research">
            Search Again
        </button>
        <button
            type="button"
            class="modal_button_okay"
            (click)="activeModal.close(DifferentInvokdedUserReasons.cancel)"
            i18n="@@diu-cancel">
            Cancel
        </button>
    </div>
</div>

