import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { faTrash, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  UserOrGuest,
  EnterpriseSearchService,
  PassengerType,
  UserService
} from "@sabstravtech/obtservices/angular";
import { Traveller, FavouriteGuest } from "@sabstravtech/obtservices/base";
import moment from "moment";
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
@Component({
  selector: "app-traveller-detail",
  templateUrl: "./traveller-detail.component.html",
  styleUrls: ["./traveller-detail.component.scss"],
})
export class TravellerDetailComponent extends WithSubscriptionComponent implements OnInit {
  faTrash = faTrash;
  faUser = faUser;
  @Input() traveller: UserOrGuest;
  ageType: string;
  isLoadingFrequent:boolean;
  constructor(
    public searchService: EnterpriseSearchService,
    public userService: UserService) {
    super();
  }

  ngOnInit(): void {
    const guestInfo = this.traveller as FavouriteGuest;
    if (!guestInfo?.guestType) {
      const travellerAge = this.traveller.dob
        ? moment().diff(this.traveller.dob, "years", true)
        : null;
      const ageType = travellerAge
        ? this.getAgeType(travellerAge)
        : PassengerType.Adult;
      this.ageType = ageType;
    } else {
      this.ageType = guestInfo.guestType;
    }
  }

  getAgeType(age) {
    switch (true) {
      case age >= 12:
        return PassengerType.Adult;
      case age >= 1 && age <= 11:
        return PassengerType.Child;
      default:
        return PassengerType.Infant;
    }
  }

  addToTraveller(traveller: UserOrGuest) {
    const newTraveller = this.createTravellerFormat(traveller);
    this.searchService.addTraveller(newTraveller);
  }

  removeFrequentTravellers(traveller: Traveller){
    this.isLoadingFrequent = true;
    this.subscribe(this.userService.removeFavouriteTravellers(traveller), result => {
      if (result) {
        this.userService.favouriteTravellers.next(this.userService.favouriteTravellers.value.filter(frequentTraveller => frequentTraveller.id !== traveller.id));
        this.isLoadingFrequent = false;
      }
    })
  }

  createTravellerFormat(traveller: UserOrGuest): Traveller {
    const guestInfo = traveller as FavouriteGuest;
    const dob = guestInfo.guestType ? guestInfo.guestDob : this.traveller.dob;
    return {
      isChild: this.ageType === PassengerType.Child,
      isInfant: this.ageType === PassengerType.Infant,
      childDOB: null,
      id: traveller.id,
      forename: traveller.forename,
      guest: !!guestInfo.guestType,
      surname: traveller.surname,
      title: traveller.title,
      email: traveller.email,
      name: `${traveller.forename} ${traveller.surname}`,
      dob: dob,
    };
  }
}
