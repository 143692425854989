<ng-container
  *ngIf="{
    userdata: (userService.fullUserDetails.userData | async) || {},
    services: (userService.fullUserDetails.userServices | async) || {},
    baskets: (basketService.baskets | async) || [],
    selectedBasket: ((selectedBasket || {}).subject | async) || {
      basketItems: []
    },
    deletingBasket: basketService.deletingBasket.value,
    miUpdating: (basketService.miUpdating | async) || false
  } as data">
  <div
    *ngIf="!basketService.basketUpdating"
    class="basket_panel_header background-46"
    aria-live="polite">
    <h2 class="basket_panel_heading">
      <i class="material-icons">shopping_basket</i>
      <span i18n="@@basketPanelCart">Baskets</span>
    </h2>
    <div class="basket-item-count">
      <span i18n="@@basketItems">Items:</span>
      <span
        id="ripple_test"
        class="custom_ripple no-click"
        role="contentinfo"
        attr.aria-label="There are {{
          (data.selectedBasket || { basketItems: 0 }).basketItems.length
        }} Items in the cart">
        {{ (data.selectedBasket || { basketItems: [] }).basketItems.length }}
      </span>
    </div>
    <div class="basket-item-reset">
      <button
        class="small-button"
        (click)="startAgain()"
        i18n="@@basketPanelStartAgain"
        aria-label="Start Again">
        Start Again
      </button>
    </div>
  </div>

  <div class="multi_baskets_container" id="basket_contents_container">
    <div class="multi_basket_add_row">
      <section
        [ngbTooltip]="
          data.baskets?.length >= maxBasketNumber
            ? 'You currently have the maximum number of baskets allowed.'
            : null
        "
        placement="left">
        <button
          class="small-button"
          i18n="@@basketPanelAddNewBasket"
          (click)="addNewBasket(data.baskets); multiBasketIndex = 0"
          [disabled]="data.baskets?.length >= maxBasketNumber">
          Add New Basket
        </button>
      </section>
    </div>
    <mat-tab-group
      *ngIf="!data.deletingBasket"
      [(selectedIndex)]="multiBasketIndex"
      [disablePagination]="true"
      #basketTabGroup>
      <mat-tab label="Current Basket" i18n-label>
        <div
          class="no_basket_warning"
          *ngIf="!data.selectedBasket || !data.selectedBasket.id"
          i18n="@@basketPanelNoCurrentBasket">
          You do not have a current basket.
        </div>
        <div *ngIf="data.selectedBasket && data.selectedBasket.id">
          <div class="multi_basket_header multi_basket_header--selected">
            <span class="multi_basket_name" *ngIf="!editNameMode">{{
              data.selectedBasket.title
            }}</span>
            <div class="flex-column-container" *ngIf="editNameMode">
              <label id="new_basket_title">New Basket Title:</label>
              <input [(ngModel)]="renameString" aria-labelledby="new_basket_title" />
            </div>
            <div class="multi_basket_functions">
              <button
                *ngIf="data.selectedBasket.basketItems?.length"
                class="multi_basket_button"
                ngbTooltip="Share Basket"
                aria-label="Share Basket"
                i18n-ngbTooltip
                placement="left"
                (click)="openShareBasket(data.selectedBasket)">
                <svg class="share-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                  <g fill="none" fill-rule="evenodd">
                    <path
                      id="share-icon-path"
                      fill="#000"
                      fill-rule="nonzero"
                      d="M23.333 0a4.667 4.667 0 0 0-4.666 4.667c.002.328.038.655.109.975L8.18 10.94a4.667 4.667 0 0 0-3.513-1.607 4.667 4.667 0 0 0 0 9.334 4.667 4.667 0 0 0 3.516-1.605l10.588 5.296a4.667 4.667 0 1 0 4.562-3.691 4.667 4.667 0 0 0-3.516 1.604L9.23 14.975c.069-.32.104-.647.104-.975a4.667 4.667 0 0 0-.109-.973L19.82 7.729a4.667 4.667 0 0 0 3.513 1.604 4.667 4.667 0 1 0 0-9.333z" />
                  </g>
                </svg>
              </button>
              <button
                class="multi_basket_button"
                *ngIf="!editNameMode"
                ngbTooltip="Edit Basket Title"
                aria-label="Edit selected basket title"
                i18n-ngbTooltip
                placement="left"
                (click)="openBasketRename(data.selectedBasket)">
                <i class="ico-edit" aria-hidden="true"></i>
              </button>
              <button
                class="multi_basket_button"
                *ngIf="editNameMode"
                ngbTooltip="Save Title Change"
                aria-label="Save selected basket title"
                i18n-ngbTooltip
                placement="left"
                (click)="saveBasketRename(data.selectedBasket)">
                <i class="ico-check-1" aria-hidden="true"></i>
              </button>
              <button
                class="multi_basket_button"
                *ngIf="editNameMode"
                ngbTooltip="Cancel Title Change"
                aria-label="Cancel selected basket title change"
                i18n-ngbTooltip
                placement="left"
                (click)="cancelBasketRename()">
                <i class="ico-cancel" aria-hidden="true"></i>
              </button>
              <button
                class="multi_basket_button"
                ngbTooltip="Delete Basket"
                aria-label="Delete selected basket"
                i18n-ngbTooltip
                placement="left"
                [disabled]="deletingBasket"
                (click)="closeAndDeleteBasket(data.selectedBasket.id)">
                <i class="ico-trash" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <app-basket-invoked-user
            [basket]="data.baskets[getSelectedBasketIndex(data.baskets)]?.subject | async">
          </app-basket-invoked-user>

          <div class="cart_panel_items">
            <!-- Cart items -->
            <div
              class="cart_item"
              *ngIf="
                ((data.selectedBasket || { basketItems: [] }).basketItems || []).length == 0 &&
                data &&
                data.baskets &&
                data.selectedBasket
              "
              i18n="@@basketPanelNoItems">
              There are no items in your basket.
            </div>
            <div class="cart_item" *ngIf="!(data && data.baskets && data.selectedBasket)">
              <i class="button_spinner"></i>
            </div>

            <ng-container
              *ngFor="
                let basketItem of data.selectedBasket.basketItems;
                trackBy: trackbyId;
                let i = index
              ">
              <div class="cart_item" [ngSwitch]="basketItem.service.type">
                <app-basket-flight
                  *ngSwitchCase="ItemTypes.Flight"
                  [flight]="basketItem"
                  (remove)="removeBasketItemCheck(basketItem)"
                  (openChangeModal)="openMoveBasketModal(basketItem, data)">
                </app-basket-flight>
                <app-basket-lounge
                  *ngSwitchCase="ItemTypes.Lounge"
                  [lounge]="basketItem"
                  [itemIndex]="i"
                  (remove)="removeBasketItemCheck(basketItem)"
                  (openChangeModal)="openMoveBasketModal(basketItem, data)">
                </app-basket-lounge>

                <app-basket-car-hire
                  *ngSwitchCase="ItemTypes.Car"
                  [car]="basketItem"
                  (remove)="removeBasketItemCheck(basketItem)"
                  (openChangeModal)="openMoveBasketModal(basketItem, data)">
                </app-basket-car-hire>

                <app-basket-hotel
                  *ngSwitchCase="ItemTypes.Hotel"
                  [hotel]="basketItem"
                  (remove)="removeBasketItemCheck(basketItem)"
                  (openChangeModal)="openMoveBasketModal(basketItem, data)">
                </app-basket-hotel>

                <app-basket-parking
                  *ngSwitchCase="ItemTypes.Parking"
                  [parking]="basketItem"
                  [itemIndex]="i"
                  (remove)="removeBasketItemCheck(basketItem)"
                  (openChangeModal)="openMoveBasketModal(basketItem, data)">
                </app-basket-parking>

                <app-basket-rail
                  *ngSwitchCase="ItemTypes.Rail"
                  [rail]="basketItem"
                  (remove)="removeBasketItemCheck(basketItem)"
                  (openChangeModal)="openMoveBasketModal(basketItem, data)">
                </app-basket-rail>

                <app-basket-irl
                  *ngSwitchCase="ItemTypes.InternationalRail"
                  [rail]="basketItem"
                  (remove)="removeBasketItemCheck(basketItem)"
                  (openChangeModal)="openMoveBasketModal(basketItem, data)">
                </app-basket-irl>

                <app-basket-taxi
                  *ngSwitchCase="ItemTypes.Cab"
                  [taxi]="basketItem"
                  (remove)="removeBasketItemCheck(basketItem)"
                  (openChangeModal)="openMoveBasketModal(basketItem, data)">
                </app-basket-taxi>
                <app-basket-eurostar
                  *ngSwitchCase="ItemTypes.Eurostar"
                  [eurostar]="basketItem"
                  (remove)="removeBasketItemCheck(basketItem)"
                  (openChangeModal)="openMoveBasketModal(basketItem, data)">
                </app-basket-eurostar>
                <app-basket-fast-track
                  *ngSwitchCase="ItemTypes.FastTrack"
                  [fastTrack]="basketItem"
                  [itemIndex]="i"
                  (remove)="removeBasketItemCheck(basketItem)"
                  (openChangeModal)="openMoveBasketModal(basketItem, data)">
                </app-basket-fast-track>
              </div>
            </ng-container>

            <app-price-breakdown
              *ngIf="data?.selectedBasket?.basketItems?.length > 0"
              [index]="getSelectedBasketIndex(data.baskets)"></app-price-breakdown>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Other Baskets" i18n-label *ngIf="maxBasketNumber > 1">
        <ng-container *ngFor="let basket of data.baskets | filterUnique; let index = index">
          <div *ngIf="basket.title && selectedBasketIndex !== basket.id" class="multi_basket_div">
            <div
              class="multi_basket_header"
              [ngClass]="{ 'multi_basket_header--selected': selectedBasketIndex === basket.id }">
              <ng-container>
                <span class="multi_basket_name">{{ basket.title }}</span>
                <div class="multi_basket_functions">
                  <button
                    class="small-button"
                    attr.aria-label="Select Basket {{ basket.title }}"
                    (click)="changeBasketSelect(data, basket); multiBasketIndex = 0">
                    Select Basket
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </mat-tab>
    </mat-tab-group>
    <div *ngIf="data.deletingBasket">
      <i class="button_spinner"></i>
      <div class="flex-row-container flex-row-container--center">
        <span class="loading-screen-text" i18n="@@basketPanelDeletingBasket"
          >Deleting Basket...</span
        >
      </div>
    </div>
  </div>
  <div
    class="cart_panel_buttons"
    *ngIf="!data.deletingBasket && data?.selectedBasket?.basketItems?.length > 0">
    <button
      (click)="continue(data.selectedBasket.basketItems, selectedBasketIndex)"
      [disabled]="data.miUpdating"
      class="large-button large-button--max-width large-button--no-margin"
      id="basket-button-continue"
      attr.aria-label="Continue to basket ({{ data.selectedBasket.basketItems.length }} {{
        data.selectedBasket.basketItems.length > 1 ? 'Items' : 'Item'
      }} in basket)">
      <span *ngIf="data.miUpdating"
        ><i class="button_spinner input-loading-spinner-left smaller-size"></i
      ></span>
      <span i18n="@@basketPanelContinueButton" *ngIf="!data.miUpdating">Continue</span>
    </button>
  </div>
</ng-container>
