<ng-template #hintTooltip>
  <a
    class="carbon_link"
    href="https://uk.travelctm.com/guide-to-ctm-carbon-emissions-calculations/ "
    tabindex="0"
    target="_blank"
    >How CTM calculates carbon emissions</a
  >
</ng-template>
<ng-container
  *ngIf="{
    results: (currentResults | async) || [],
    secondTabResults: (secondTabResults | async) || [],
    isvalid: searchService.searches[ServiceType.Hotel].isValid | async,
    isLoading: (searchParams.isLoading | async),
    jitRulesReceived: (searchParams.jitRulesReceived | async),
    fetching: (hotelAvalibilityService.fetching | async)
  } as data">
  <h2 class="search_content_titles" i18n="@@hotel_searchCriteria">Search Criteria</h2>
  <a
    href="#hotel-search-1"
    (click)="skipToResultContent()"
    role="link"
    class="sr-only sr-only-focusable"
    >Skip to Result Content</a
  >
  <app-invoked-user></app-invoked-user>
  <app-hotel-loading-screen [animated]="false"></app-hotel-loading-screen>

  <div *ngIf="!data.isLoading">
    <div class="background-30 research-container">
      <app-search-toggle>
        <app-hotel-search></app-hotel-search>
        <div class="theme--default background-40" style="padding: 20px">
          <div class="flex-row-container flex-row-container--right">
            <button
              type="button"
              (click)="performSearch()"
              [disabled]="!data.isvalid"
              class="large-button large-button--search"
              type="button"
              i18n="@@hotel_Search">
              Search
            </button>
          </div>
        </div>
      </app-search-toggle>
      <app-risk-alerts
        *ngIf="searchParams.tempParams.location_type_select"
        [countryCode]="getCountryCode()"></app-risk-alerts>
    </div>

    <!-- results grid -->
    <div class="hotel_search_results" id="hotel-search-1">
      <div class="flex-row-container flex-column-mobile">
        <div class="app-left-filters background-60 hotel-filters-margin-top">
          <div class="flex-row-container">
            <h2 i18n="@@hotelsFiltersTitle">Filters</h2>
            <span
              class="filters-clear-top"
              tabindex="0"
              role="button"
              aria-label="Clear filters"
              (click)="clearFilters()"
              (keydown.enter)="clearFilters()"
              (keydown.space)="clearFilters()"
              i18n="@@hotelsClear"
              >Clear</span
            >
          </div>
          <app-filter-hotels
            (availabilityFilter)="setAvailabilityFilterState($event)"
            [srchRslts]="searchParams.searchResults"
            [hotels]="searchParams.searchResults"
            [viewControl]="hotelViewController"
            [swapSellHotelGroup]="swapSellHotelGroup"></app-filter-hotels>
        </div>
        <div class="flex-column-container width-100">
          <div class="search_results_header">
            <h2 class="search_results_page_title">
              <span [innerHtml]="getNumHotelsMsg()" role="status"></span>
            </h2>
            <div class="co2-ontainer" *ngIf="!data.isLoading && data.results[0]?.co2PerItem">
              <span>
                <span i18n="@@Hotelsatthisdestinationcontribute"
                  >Hotels at this destination contribute
                </span>
                <strong> {{ co2Number }}kg <span i18n="@@ofCO2">of CO2 </span></strong>
                <span i18n="@@perNight">per Night</span></span
              >
              &nbsp;
              <a
                class="link_tooltip"
                [ngbTooltip]="hintTooltip"
                tabindex="0"
                triggers="manual"
                (click)="ref.open()"
                (keydown.enter)="ref.open()"
                #ref="ngbTooltip">
                <i class="ico-info-circle"></i>
              </a>
            </div>
            <div class="sort_results_actions">
              <!-- Sort and Filtering Options -->
              <!-- Sort -->
              <app-sort
                *ngIf="!data.isLoading && data.results?.length"
                [sortTypes]="sortTypes"
                [sortType]="sortType"
                (sortChange)="onSortChange($event)"
                [type]="ServiceType.Hotel">
              </app-sort>

              <span class="more-results-info light-flex light-center">
                <section (mouseleave)="tt.close()" class="container">
                  <i
                    class="ico-info"
                    [ngbTooltip]="moreInfo | translate"
                    placement="auto"
                    triggers="manual"
                    #tt="ngbTooltip"
                    (mouseenter)="tt.open()"
                    (focus)="tt.open()"
                    (blur)="tt.close()"
                    tabindex="0"></i>
                </section>
                <span i18n="@@hotelsNeedResults">Need more results?</span>
              </span>

              <!-- *ngIf="!hotelViewController.showMap && !hotelViewController.hotelChosen" -->
              <div class="flex-row-container">
                <div
                  (click)="setView(HotelView.LIST)"
                  (keydown.enter)="setView(HotelView.LIST)"
                  [ngClass]="{ 'ico-thumbnails-active': view === HotelView.LIST }"
                  class="ico-thumbnails"
                  i18n-title
                  title="See hotels as list"
                  tabindex="0"
                  role="button"
                  attr.aria-expanded="{{ hotelViewController.showMap }}"></div>
                <div
                  [ngClass]="{ 'ico-locale-active': view === HotelView.MAP }"
                  (click)="setView(HotelView.MAP)"
                  (keydown.enter)="setView(HotelView.MAP)"
                  class="ico-locale"
                  i18n-title
                  title="See hotels as map"
                  tabindex="0"
                  aria-labelledby="hotels-see-as-map"
                  role="button"
                  attr.aria-expanded="{{ hotelViewController.showMap }}">
                  <span id="hotels-see-as-map" aria-hidden="true" i18n="@@HotelsSeeAsMap">
                    Map View
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="data.results.length <= 0 && searchParams.crownRatesOnly && data.fetching">
            <h2 class="no_results_message_heading" i18n="@@crownRatesOnlyLoading">Loading...</h2>
          </div>
          <div
            class="no_results_message background-10"
            role="status"
            [ngClass]="{ 'visuallyhidden': !(data.results.length <= 0 &&
            (!searchParams.crownRatesOnly || (searchParams.crownRatesOnly && !data.fetching))) }">
            <ng-container *ngIf="
            data.results.length <= 0 &&
            (!searchParams.crownRatesOnly || (searchParams.crownRatesOnly && !data.fetching))
            ">
              <h2 class="no_results_message_heading">
                <i class="ico-nobed no_results_icon" aria-hidden="true"></i>Oh, no!
              </h2>
              <p>
                <span i18n="@@hotelsCouldntMatchCriteria"
                  >We couldn't find any hotels that match your criteria. Try&nbsp;</span
                >
                <strong i18n="@@hotelsCouldntMatchCriteriaB">updating your search</strong>.
              </p>
              <p i18n="@@hotelsContactConsultant">
                If you can't find exactly what you're looking for, contact a consultant.
              </p>
            </ng-container>
          </div>
          <div class="hotel-pref-message">
            <span *ngIf="showPreferredMessage && !isPrefHotelsSelected()">
              <b
                class="back_to_search"
                (click)="switchTriggerPref(showPreferredMessage)"
                role="button"
                >CLICK HERE</b
              >
              <span *ngIf="showPreferredMessage === 'trigger'">
                to discover alternative hotels and enjoy up to *10% off by booking CTM’s preferred
                programme *Discount applies to select properties in the CTM Preferred Hotel
                Programme*</span
              >
              <span *ngIf="showPreferredMessage === 'preferred'">
                to return to previous results</span
              >
            </span>
          </div>
          <div *ngIf="isPrefHotelsSelected()">
            <span class="back_to_search" (click)="backToHotelModal()" role="button"
              >CLICK HERE</span
            >
            to return to previous results
          </div>
          <div class="hotel_search_results_items clearfix">
            <!-- LIST VIEW-->
            <ng-container *ngIf="!hotelViewController.showMap">
              <!-- individual hotel item -->
              <div
                class="hotel-results-container"
                *ngIf="!hotelViewController.showMap && hotelViewController.hotelChosen !== false">
                <div class="list-actions">
                  <a
                    class="light-btn-link light-color-primary imp"
                    (click)="setView(HotelView.LIST)"
                    i18n="@@hotelsBackToList"
                    role="link"
                    >Back to full list</a
                  >
                </div>
                <ng-container *ngIf="searchParams?.results?.value[hotelViewController.hotelChosen]">
                  <ng-container
                    *ngIf="{
                      rooms: (hotelAvalibility[hotelViewController.hotelCodeChosen] | async) || {},
                      hotelRating: (hotelRatings[itemHotel?.name?.toLowerCase()] | async) || {},
                      hotelGroupTags:
                        (hotelGroupTags[itemHotel?.group?.toLowerCase()] | async) || {}
                    } as subdata">
                    <ng-container
                      *ngIf="
                        !availabilityFilterState ||
                        (availabilityFilterState && subdata?.rooms?.prpn)
                      ">
                      <app-item-hotels
                        inview
                        (inViewChanged)="
                          fetchPrices(
                            $event,
                            searchParams?.results?.value[hotelViewController.hotelChosen]
                          )
                        "
                        [hotelDetail]="selectedHotelOnTheMap(hotelViewController.hotelCodeChosen)"
                        [holidayCode]="hotelViewController.hotelCodeChosen"
                        [rooms]="subdata.rooms"
                        [noOfRooms]="noOfRooms"
                        [jitRulesReceived]="data.jitRulesReceived"
                        [viewControl]="hotelViewController"
                        (changeFilter)="changeFilter($event)"
                        [index]="1"
                        [distanceUnit]="userDistanceUnit"
                        [localCurrency]="userLocalCurrency"
                        [hotelRatingsInfo]="subdata?.hotelRating"
                        [hotelGroupTags]="subdata?.hotelGroupTags">
                      </app-item-hotels>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
              <ng-container *ngIf="!searchParams?.results?.value[hotelViewController.hotelChosen]">
                <app-light-toggle-group
                  tabindex="-1"
                  class="hotel-tabs"
                  *ngIf="data.results.length && data.secondTabResults.length">
                  <app-light-toggle-btn
                    [selected]="secondTabSelected === false"
                    (click)="secondTabSelected = false"
                    tabindex="-1"
                    i18n="@@hotelsTabSearchResults">
                    Search Results
                  </app-light-toggle-btn>
                  <app-light-toggle-btn
                    [selected]="secondTabSelected === true"
                    (click)="secondTabSelected = true"
                    tabindex="-1"
                    i18n="@@hotelsTabOtherLondonOptions">
                    Other London Options
                  </app-light-toggle-btn>
                </app-light-toggle-group>
                <!-- Complete list of hotels -->
                <div
                  *ngFor="
                    let itemHotel of secondTabSelected ? data.secondTabResults : data.results;
                    let i = index
                  ">
                  <ng-container
                    *ngIf="
                      i < maxResultSize && {
                        rooms: (hotelAvalibility[itemHotel.id] | async) || {},
                        hotelRating: (hotelRatings[itemHotel?.name?.toLowerCase()] | async) || {},
                        hotelGroupTags:
                          (hotelGroupTags[itemHotel?.group?.toLowerCase()] | async) || {}
                      } as subdata
                    ">
                    <!--
                      DEV NOTE:
                      only show the hotel in the list if the availabilityFilterState property is "false" OR
                      the availabilityFilterState property is "true" and a price is available in the subdata.rooms.prpn property.
                    -->
                    <ng-container
                      *ngIf="
                        !availabilityFilterState ||
                        (availabilityFilterState && subdata?.rooms?.prpn)
                      ">
                      <app-item-hotels
                        inview
                        (inViewChanged)="fetchPrices($event, itemHotel)"
                        [hotelDetail]="itemHotel"
                        [holidayCode]="itemHotel.id"
                        [rooms]="subdata.rooms"
                        [noOfRooms]="noOfRooms"
                        [jitRulesReceived]="data.jitRulesReceived"
                        [viewControl]="hotelViewController"
                        (changeFilter)="changeFilter($event)"
                        [index]="i + 1"
                        [distanceUnit]="userDistanceUnit"
                        [localCurrency]="userLocalCurrency"
                        [hotelRatingsInfo]="subdata?.hotelRating"
                        [hotelGroupTags]="subdata?.hotelGroupTags">
                      </app-item-hotels>
                    </ng-container>
                  </ng-container>
                </div>
                <!-- ENT-6004 below not needed anymore, leaving commented out just in case -->
                <!-- <div
                  #hotel_loading_screen
                  class="loadingPolicies background-60"
                  *ngIf="data.results.length < maxResultSize"
                  inview
                  (inViewChanged)="updateHotelResults($event)">
                  <div class="availability-polices-loading-indicator">
                    <i class="ico-bed fade_in_one"></i>
                    <i class="ico-bed fade_in_two"></i>
                    <i class="ico-bed fade_in_three"></i>
                    <i class="ico-bed fade_in_four"></i>
                    <i class="ico-bed fade_in_five"></i>
                  </div>
                  <span
                    class="availability-polices-loading-title"
                    i18n="@@hotels_loadingRatesAndPolicy">
                    Checking availability for more hotels and applying your travel policies
                  </span>
                </div> -->
              </ng-container>
            </ng-container>
            <!-- MAP VIEW-->
            <ng-container *ngIf="hotelViewController.showMap">
              <div class="hotel-map-results" *ngIf="hotelViewController.single">
                <app-map-hotels
                  [noOfRooms]="noOfRooms"
                  (afterBackToList)="setView(HotelView.LIST)"
                  [arrayOfHotels]="this.hotelViewController.hotelChosen"
                  [mapControl]="hotelViewController"></app-map-hotels>
              </div>

              <div class="hotel-map-results" *ngIf="!hotelViewController.single">
                <app-map-hotels
                  [noOfRooms]="noOfRooms"
                  (afterBackToList)="setView(HotelView.LIST)"
                  [arrayOfHotels]="data.results"
                  [mapControl]="hotelViewController"></app-map-hotels>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
