<p *ngIf="travelersSameName">
  <ngb-alert i18n="@@travelersWithTheSamenameAlert"
             class="alert alert-danger"
             [type]="alert"
             (close)="close()"> Travellers with the same name not permitted under the same booking. </ngb-alert>
</p>

<div *ngIf='loadingSavedGuests'>
  <i class="button_spinner"></i>
  <h3 class='loading_message'
      i18n="@@guestTravellerFormLoadingGuests">Loading Saved Guests...</h3>
</div>


<ng-container *ngIf="savedGuests.length" class="saved-guests-container">
  <div class="half-container half-container--start saved-guests-wrapper">
    <div class="half-block">
      <label class="header-label" i18n="@@guestTravellerSavedGuests">Saved Guests:</label>
      <mat-select
        (selectionChange)="chooseSavedGuest($event.value)"
        disableOptionCentering
        panelClass="guests-select"
        class="select-box select-box--no-icon"
        placeholder="Please select..."
      >
        <div *ngFor="let guest of savedGuests; let first = first">
          <div class="guest-wrapper">
            <mat-option [value]="guest.id">
              {{guest.title}} {{guest.forename}} {{guest.surname}} ({{guest.email}})
            </mat-option>
            <div>
              <fa-icon [icon]="faTrash" class="remove-guest-icon" aria-label="remove" tabindex="0"
                (click)="deleteSavedGuest(guest); $event.stopPropagation()">
              </fa-icon>
            </div>
          </div>
        </div>
      </mat-select>
    </div>
    <div class="half-block">
    </div>
  </div>
</ng-container>

<!-- Error Message -->
<div *ngIf='!loading && error'>
  <h3 class='loading_message error'>{{error}}</h3>
</div>

<form action="#"
      #form='ngForm'
      autocomplete='off'
      id="guestTravellerForm"
      *ngIf='!loading'>

  <div class="half-container">
    <div class="two-thirds-container two-thirds-container--ipad-break">
      <div class="two-third">
        <div class="two-thirds-container two-thirds-container--ipad-break">
          <div class="one-third">
            <label for="guest_title"
                   class="header-label"
                   i18n="@@guestTravellerFormTitle">Title</label>
            <select id='guest_title'
                    [(ngModel)]='guest_title'
                    name='guest-title'
                    aria-label="Title"
                    tabindex="0"
                    class="select-box select-box--no-icon">
              <option *ngFor='let title of titles'
                      [ngValue]='title'>{{title.title}}</option>
            </select>
          </div>
          <div class="two-third">
            <div class="half-container">
              <div class="half-block">
                <label for="guest_first_name_input"
                       class="header-label"
                       i18n="@@guestTravellerFormFirstName">First Name</label>
                <input type="text"
                       required="true"
                       id="guest_first_name_input"
                       autocomplete="off"
                       pattern="^[A-Za-z]{1,}([\']|[A-Za-z]){1}([-A-Za-z]){0,}(?:\s[A-Za-z]{1,})*"
                       [(ngModel)]="guest_first_name"
                       name="guest_first_name_input"
                       i18n-placeholder
                       placeholder="Enter guest's first name"
                       aria-label="First name"
                       class="input-box input-box--no-icon"
                       #firstName='ngModel' />
              </div>
              <div class="half-block">
                <label for="guest_last_name_input"
                       class="header-label"
                       i18n="@@guestTravellerFormLastName">Last
                  Name</label>
                <input type="text"
                       required="true"
                       autocomplete="off"
                       pattern="^[A-Za-z]{1,}([\']|[A-Za-z]){1}([-A-Za-z]){0,}(?:\s[A-Za-z]{1,})*"
                       minlength="2"
                       [(ngModel)]="guest_last_name"
                       name="guest_last_name_input"
                       id="guest_last_name_input"
                       i18n-placeholder
                       placeholder="Enter guest's last name"
                       aria-label="Last name"
                       class="input-box input-box--no-icon"
                       #lastName='ngModel' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="one-third">
        <label for="guest_email_input"
               class="header-label"
               i18n="@@guestTravellerFormEmail">Email</label>
        <input type='text'
               [required]="emailRequired"
               autocomplete='off'
               id="guest_email_input"
               pattern='^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
               name='guest_email_input'
               [(ngModel)]='guest_email'
               i18n-placeholder
               placeholder="Enter guest's email"
               aria-label="Email"
               class="input-box input-box--no-icon" />
      </div>
    </div>
  </div>
  <div class="half-container">
    <div class="half-block">
      <label for="mobileNumber"
             class="header-label"
             i18n="@@guestTravellerFormMobilePhone">Mobile Phone:</label>
      <div class="two-thirds-container">
        <div class="one-third">
          <input autocomplete="off"
                 pattern="(\+?\d{1,3}|\d{1,4})"
                 class="input-box input-box--no-icon"
                 name='mobileCountryNo'
                 i18n-placeholder
                 id="mobileNumber"
                 placeholder='Country Code'
                 [required]="isFlightSelected"
                 [(ngModel)]='guestMobileCountryNo'
                 type='text'
                 min='0'
                 aria-label="Mobile Phone: Country Code" />
        </div>
        <div class="two-third">
          <input autocomplete="off"
                 pattern="[0-9]{9,10}"
                 class="input-box input-box--no-icon"
                 name="mobileNumber"
                 id="mobileNumber-2"
                 i18n-placeholder
                 placeholder='Mobile Number'
                 [required]="isFlightSelected"
                 [(ngModel)]='guestMobileNumber'
                 type='text'
                 aria-label="Mobile Phone: Number" />
        </div>
      </div>
    </div>
    <div class="half-block">
      <div class="half-container">
        <div class="half-block">
          <label for="guest_country_select"
                 class="header-label"
                 i18n="@@guestTravellerFormNationality">Nationality</label>
          <select [(ngModel)]='guest_nationality'
                  name='guest_country_select'
                  id="guest_country_select"
                  class="select-box select-box--no-icon">
            <option [ngValue]="''"
                    selected
                    i18n="@@guestTravellerFormChooseAnOption">Choose an option</option>
            <option *ngFor='let country of searchService.getCountries()'
                    [ngValue]='country.cCode'>{{country.cName}}</option>
          </select>
        </div>
        <div class="half-block">
          <label for="guest_gender"
                 class="header-label"
                 i18n="@@guestTravellerFormGender">Gender</label>
          <select name='guest_gender'
                  [required]='true'
                  [disabled]='disableGenderDropdown()'
                  [(ngModel)]='guest_title.gender'
                  tabindex="0"
                  id="guest_gender"
                  class="select-box select-box--no-icon">
            <option *ngFor='let gender of genders'
                    [ngValue]='gender'> {{gender | translate}} </option>

          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-row-container child-row"  *ngIf="searchService.getCurrentNoOfTravellers() >= 1 && childAllowed">
    <mat-checkbox color="primary"
                  class="light-primary"
                  value="Manual"
                  [disabled]='!(searchService.getCurrentNoOfTravellers() >= 1)'
                  [checked]="isChild"
                  (change)="isChild= !isChild"
                  i18n="@@guestTravellerFormIsChild">
      Traveller is a child
    </mat-checkbox>
    <section (mouseleave)="tt.close()"
             class="container">
      <i class="ico-info child-warning-icon"
         i18n-ngbTooltip
         ngbTooltip="An adult traveller needs to be added before you can add a child"
         aria-label="An adult traveller needs to be added before you can add a child"
         triggers="manual"
         #tt="ngbTooltip"
         (mouseenter)="tt.open()"
         (focus)="tt.open()"
         (blur)="tt.close()"
         tabindex="0"><span class="visuallyhidden">An adult traveller needs to be added before you can add a
          child</span></i>
    </section>
    <div class="flex-column-container"
         *ngIf="isChild">
      <label i18n="@@guestTravellerFormChildDob"
             for="isChild">Childs Date of Birth</label>
      <i class="ico-calendar combo-box-icon cursor_pointer" aria-hidden="true"
       ></i>
       <input autocomplete="off"
        class="combo-box"
        i18n-placeholder
        placeholder="Select childs date of birth"
        [required]='isFlightSelected'
        name="dp"
        [minDate]='minChildDate'
        [maxDate]='maxChildDate'
        [startDate]='childDOB'
        [(appValidateDate)]='childDOB'
        [(ngModel)]='childDOB'
        [displayMonths]="deviceDetector.displayMonths"
        [navigation]="'select'"
        ngbDatepicker
        #dp
        container="body"
        #d="ngbDatepicker"
        (click)="d.toggle()"
        placement='left'
        tabindex="0"
        id="isChild" />
    </div>
    
  </div>
  <div class="child-warn" *ngIf="isChild">
    <p i81n="@@childEU">
      <br/>
      <strong>Please Note for EU Rail Only:</strong>
      For children between 0-5 years old you will be able to enter their specific age on the search form. Please select the lowest possible date of birth to add them as a guest.
    </p>
  </div>
  <div class="flex-row-container flex-row-container--right">
    <button type="button"
            class='large-button'
            [disabled]="!form.form.valid"
            [class.large-button--disabled]="!form.form.valid"
            form="guestTravellerForm"
            [attr.aria-disabled]="!form.form.valid"
            (click)='submitGuest()'
            id="guest-button-save"
            i18n="@@guestTravellerFormSaveGuestDetails">
      Save guest details</button>
  </div>
</form>

<!-- Loading Spinner - show when fetching countries on init -->
<div *ngIf='loading'>
  <i class="button_spinner"></i>
  <h3 class='loading_message'
      i18n="@@guestTravellerFormLoading">Loading...</h3>
</div>



<div class='clearfix'></div>
