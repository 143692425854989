import { Component, Input, OnInit } from '@angular/core';
import { OBTRailJourneyFare, OBTRailJourneyOption, CheapestRailPrices } from '@sabstravtech/obtservices/base';
import {
    ModalOpenerService,
    EnterpriseSearchService,
    ServiceType,
    EnterpriseBasketService
} from '@sabstravtech/obtservices/angular';
import { LightningModalTypes } from '../../../../vendor/classes/modal-types.enum';

@Component({
    selector: 'app-wellbeing-chosen-rail',
    templateUrl: './wellbeing-chosen-rail.component.html',
    styleUrls: ['./wellbeing-chosen-rail.component.scss']
})
export class WellbeingChosenRailComponent implements OnInit {
    @Input() outboundCheapest: CheapestRailPrices;
    @Input() inboundCheapest: CheapestRailPrices;
    chosenOutboundRail: OBTRailJourneyOption;
    chosenReturnRail: OBTRailJourneyOption;
    chosenTicket: OBTRailJourneyFare;
    dualSingle: boolean = false;

    constructor(
        private modalService: ModalOpenerService,
        private searchService: EnterpriseSearchService,
        private basketService: EnterpriseBasketService
    ) { }

    ngOnInit(): void {
        if (this.outboundCheapest.dualSingleJourneyFares.price + this.inboundCheapest.dualSingleJourneyFares.price > this.outboundCheapest.returnJourneyFares.price) {
            this.chosenOutboundRail = this.outboundCheapest.returnJourneyFares.journey;
            this.chosenReturnRail = this.inboundCheapest.returnJourneyFares.journey;
            this.chosenTicket = this.outboundCheapest.returnJourneyFares.fare;
        } else {
            this.dualSingle = true;
            this.chosenOutboundRail = this.outboundCheapest.dualSingleJourneyFares.journey;
            this.chosenReturnRail = this.inboundCheapest.dualSingleJourneyFares.journey;
            this.chosenTicket = this.outboundCheapest.dualSingleJourneyFares.fare;
        }
    }

    /**
  @desc - open a modal showing the leg details of the selected journey
  **/
    openDetailsModal(result: any): void {
        // ! get this when we get the legs

        this.modalService.open(
            LightningModalTypes.ModalRailJourneyDetailsComponent,
            {
                centered: true
            },
            { result }
        );
    }

    /**
  @desc - Open a modal to display the ticket terms. Terms data is on the ticket
**/
    openTicketTermsModal(ogTicket: OBTRailJourneyFare): void {
        const ticketTerms = [];
        if (ogTicket?.terms) {
            for (const [key, value] of Object.entries(ogTicket.terms)) {
                if (key !== '__typename') {
                    ticketTerms.push({ key, value });
                }
            }

            this.modalService.open(
                LightningModalTypes.ModalRailJourneyTicketTermsComponent,
                { centered: true },
                { ticketTerms }
            );
        }
    }

    async addToBasket(): Promise<void> {
        console.log(this.searchService.searches[ServiceType.Rail]);
        await this.searchService.searches[ServiceType.Rail].selectTicket(
            'outbound',
            this.chosenOutboundRail,
            !this.dualSingle ? this.chosenTicket : this.outboundCheapest?.dualSingleJourneyFares?.fare,
            this.searchService.searches[ServiceType.Rail].results.value[0].sourceId.toString(),
            !this.dualSingle ? 'returnJourneyFares' : 'dualSingleJourneyFares'
        );

        await this.searchService.searches[ServiceType.Rail].selectTicket(
            'inbound',
            this.chosenReturnRail,
            !this.dualSingle ? this.chosenTicket : this.inboundCheapest?.dualSingleJourneyFares?.fare,
            this.searchService.searches[ServiceType.Rail].results.value[0].sourceId.toString(),
            !this.dualSingle ? 'returnJourneyFares' : 'dualSingleJourneyFares'
        );

        this.basketService.toggleMenu();
    }
}
