<div cdkTrapFocus
     aria-live="assertive">
  <div class="modal-header">
    <h3 class="modal-title"
        i18n="@@BasketApprovalWarningHeader"
        id="modal-header">This basket requires approval</h3>
  </div>
  <div class="modal-body">
   
    <div class="error">
        <strong i18n="@@BasketApprovalWarningBody">One or more items in your current basket has triggered Approval by your organisation for everything. Items that do not require Approval can be moved into a new basket and these will be processed and booked separately.</strong>
    </div>

  </div>
  <div class="modal-footer">
    <button 
        type="button"
        id='button_no'
        class="modal_button_okay"
        (click)="closeModal(false)"
        (keydown.enter)="closeModal(false)"
        (keydown.space)="closeModal(false)"
        i18n="@@cancel">
        Cancel
    </button>
    <button 
        type="button"
        id='button_ok'
        class="modal_button_okay"
        (click)="closeModal(true)"
        (keydown.enter)="closeModal(true)"
        (keydown.space)="closeModal(true)"
        i18n="@@ok">
        Ok
    </button>
  </div>
</div>