<div [ngClass]="{ border: altColour, alt_format: altColour }">
  <div class="leg"
       *ngFor="let leg of legs; let i = index; let first = first; let last = last">
    <div class="leg_header_container">
      <div class="leg_header"
           [ngClass]="{ alt_colour: altColour }">
        <i [ngClass]="setIco(leg)"></i>
        <strong class="tourOpId">{{ leg.marketingCarrier }}{{ leg.marketingFlightNumber }}</strong>
        <strong class="busWarning"
                *ngIf="leg.equipmentCode === 'BUS'"
                i18n="@busLegWarning">*Please be advised that this leg is via bus*</strong>
        <!-- <span>{{ leg.transNo }}</span> -->
        <span class="leg_time"
              [ngClass]="{ alt_colour: altColour }">
          <time>{{ leg.totalDuration | sabsTimePipe: 'min_duration' }}</time>
        </span>
      </div>
      <div class="leg_header"
           [ngClass]="{ alt_colour: altColour }">
        <span class="codeshare"><span i18n="@@flightLegsOperatedBy">Operated By</span>
          {{ leg.operatingCarrierName }}</span>

        <!-- <span *ngIf="leg.Equipment" class="codeshare"
          ><span i18n="@@flightLegsEquipmentBy"> Equipment:</span>
          {{ leg.Equipment || 'Unavailable' }}</span
        > -->
      </div>
    </div>
    <div class="flex-row-container amenities-leg-container">
      <div class="leg_trip">
        <span class="leg_start_location"
              [ngClass]="{ alt_colour: altColour }">
          <strong>{{ leg.originAirportName }} ({{
            leg.originAirport
            }})</strong>
          <br />
          <em>
            {{ leg.dateTimeDeparting | sabsDate: 2 }}
          </em>
          <div>
            <span i18n="@@flightLegsTerminalDep">Terminal: </span>
            <span>{{ leg.originTerminal ? leg.originTerminal : 'not available' }}</span>
          </div>
        </span>
        <span class="leg_start_time">{{
          leg.dateTimeDeparting | sabsTimePipe: 'timeString'
          }}</span>
        <span class="leg_end_location"
              [ngClass]="{ alt_colour: altColour }">
          <strong>{{ leg.destinationAirportName }} ({{
            leg.destinationAirport
            }})</strong>
          <br />
          <em>
            {{ leg.dateTimeArriving | sabsDate: 2 }}
          </em>
          <div>
            <span i18n="@@flightLegsTerminalArr">Terminal: </span>
            <span>{{ leg.destinationTerminal ? leg.destinationTerminal : 'not available' }}</span>
          </div>
          <br />
        </span>
        <span class="leg_seat_class">{{ leg.cabinClass }}</span>
        <span class="leg_c02"
              *ngIf="flight?.classes[leg.cabinClass]?.flights[0]?.co2PerItem">
          <span i18n="@flight_leg_co2">CO2</span>
          {{flight.classes[leg.cabinClass].flights[0].co2PerItem}}
          <span i18n="@flight_kgpp">Kg pp</span>
        </span>
        <span class="leg_end_time">{{
          leg.dateTimeArriving | sabsTimePipe: 'timeString'
          }}</span>
        <div class="leg_line">
          <div class="leg_line_line"></div>
        </div>
      </div>
      <app-routehappy-amenities [amenities]="amenitites[i]"></app-routehappy-amenities>
    </div>
    <div class="legs_layover"
         *ngIf="!last">
      <i class="ico-transfer" aria-hidden="true"></i>
      <em i18n="@@flightLegsChangeAt">Change at</em>
      {{ leg.destinationAirportName }} ({{ leg.destinationAirport }})
      <span>{{
        getTimeDifference(
        leg.dateTimeArrivingUTC,
        legs[i + 1].dateTimeDepartingUTC
        ) | sabsTimePipe: 'min_duration'
        }}</span>
    </div>
  </div>
  <!-- <span
    (click)="showFlightCosts(FlightItineraryWithSelected)"
    (keydown.enter)="$event.preventDefault(); showFlightCosts(FlightItineraryWithSelected)"
    (keydown.space)="showFlightCosts(FlightItineraryWithSelected)"
    class="flightBreakDown"
    role="button"
    tabindex="0"
    aria-label="View flight breakdown"
    i18n="@@flightCostLink"
    >View flight breakdown</span
  > -->
</div>