import { Component, OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SortMethod, SortType } from '../../interfaces/sort-type.interface';
import { CabHireEnterpriseSearchInterface, UserServices, ServiceProvider } from '@sabstravtech/obtservices/base';
import {
    CabHireLocationInput,
    WithSubscriptionComponent,
    CabHireQuote,
    EnterpriseBasketService,
    EnterpriseSearchService,
    UserService,
    CabHireResult,
    AddressType,
    ServiceType
} from '@sabstravtech/obtservices/angular';
import { Geolocation } from '../../../vendor/classes/geolocation';
import { resultIcons } from '../../../vendor/enum/result-icons.enum';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-taxis',
    templateUrl: './taxis.component.html',
    styleUrls: ['./taxis.component.scss']
})
export class TaxisComponent extends WithSubscriptionComponent implements OnInit {
    cabIconUrl = 'https://images.sabscorp.com/images/TourOp/png/cmac_small.png';
    // readonly cabIconUrl = 'https://images.sabscorp.com/images/TourOp/svg/CAB.svg';

    mapView: boolean = false;
    loadingMsg: string;
    selectedItem: CabHireResult;
    pins: (Geolocation & { message: string; })[];
    geolocation: Geolocation;
    itemSelected: EventEmitter<CabHireResult> = new EventEmitter();
    resultItemType = resultIcons;
    public taxiServiceType = ServiceType.Cab;
    canOverride: boolean = false;

    sortingTypes: SortType[] = [
        {
            title: 'Price',
            method: SortMethod.sortByPrice,
            icon: 'ico-dollar',
            dir: true
        }
    ];
    searchParams: CabHireEnterpriseSearchInterface;

    constructor(
        public searchService: EnterpriseSearchService,
        private route: ActivatedRoute,
        private basketService: EnterpriseBasketService,
        private userService: UserService,
        private translate: TranslateService,
        private titleService: Title,
    ) {
        super();
        this.searchParams = this.searchService.searches[ServiceType.Cab];
    }

    get vias(): CabHireLocationInput[] {
        return this.searchParams?.vias;
    }

    ngOnInit() {
        this.titleService.setTitle('Taxi results - LightUk');
        this.canOverride = this.userService.canOverride();

        this.route.queryParams.subscribe(({ center }) => {
            this.mapView = !!center;
        });


        this.subscribe(this.searchParams.isLoading, (isLoading: boolean) => {
            if (!isLoading) {
                this.searchParams.sortBy(false);
            }
        });
    }

    addToCart(item: CabHireQuote): void {
        this.searchService.searches[ServiceType.Cab].addCabHireToBasket(
            item,
            this.searchService.searches[ServiceType.Cab].getSearchQuery()
        );
        this.basketService.toggleMenu();
    }

    goToMapView(taxi: any): void {
        this.geolocation = new Geolocation(
            `${taxi.fromLocation.latitude},${taxi.fromLocation.longitude}`
        );

        this.pins = taxi.vias.map((via) => ({
            ...new Geolocation(`${via?.latitude},${via?.longitude}`),
            message: ''
        }));

        this.pins.push({
            ...new Geolocation(`${taxi.toLocation.latitude},${taxi.toLocation.longitude}`),
            message: ''
        });
        this.pins = this.pins.filter(pin => !isNaN(pin.lat) && !isNaN(pin.lng));
        this.selectedItem = taxi;
        this.cabIconUrl = this.getLogo(taxi);
        this.itemSelected.emit(taxi);
        this.mapView = true;
    }

    onCloseMapView(): void {
        this.mapView = false;
    }

    skipToResultContent() {
        const focusable = document
            .getElementById('taxi')
            .querySelectorAll(
                'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
        // @ts-ignore
        focusable[0].focus();
        return false;
    }

    getLogo(item: CabHireQuote): string {
        switch (item.source) {
            case ServiceProvider.CMAC:
                return 'https://images.sabscorp.com/images/TourOp/png/cmac_small.png';
            case ServiceProvider.Groundspan:
                return 'https://images.sabscorp.com/images/TourOp/groundspan-small.jpg';
            case ServiceProvider.Groundscope:
                return 'https://images.sabscorp.com/images/TourOp/GRS.gif';
        }

        return '';
    }
}