<div cdkTrapFocus>
    <div class="modal-header">
      <h3 class="modal-title">
        <span><span i18n="@@boltAdditionalInformationFor">Additional Information for </span> {{ lounge.lounge.name }}</span>
      </h3>
      <button
        type="button"
        class="close"
        aria-label="Close"
        ngbAutoFocus
        (click)="modal.close()">
        <span aria-hidden="true"><i class="ico-cancel modal_exit_cross"></i></span>
    </button>
    </div>
    <div class="modal-body">
        <app-lounge-details [lounge]="lounge"></app-lounge-details>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="small-button small-button--no"
        i18n="@@fareruleOK"
        (click)="modal.close()"
      >
        Ok
      </button>
    </div>
  </div>