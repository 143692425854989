<ng-container
  *ngIf="{
    fetchingRooms: !hotelDetail || !(rooms && rooms.rooms),
    hasRooms: hotelDetail && rooms && rooms.rooms && rooms.rooms.length > 0,
    rooms: rooms,
    jitRulesReceived: jitRulesReceived
  } as data">
  <ng-template #NoAvailableRates>
    <span
      *ngIf="!data.fetchingRooms && !data.hasRooms && data.jitRulesReceived"
      class="no_rates_link"
      i18n="@@itemHotelsNoRatesAvailable"
      >No Available Rates
    </span>
  </ng-template>
  <ng-template #NoRates>
    <span
      *ngIf="!data.fetchingRooms && !data.hasRooms && data.jitRulesReceived"
      class="no_rates_link"
      i18n="@@itemHotelsNoRatesAvailable"
      >No Available Rates
    </span>
  </ng-template>
  <div class="hotel-item-content">
    <h3 class="visuallyhidden">
      Result {{ index }} {{ hotelDetail.name
      }}<span *ngIf="hotelDetail.hasRooms"
        >, price from {{ getPrice(rooms) | number : '1.2-2' }} {{ rooms.currencyCode }}
        {{ hotelDetail.availableRates.rooms[0].type }}</span
      >
    </h3>

    <div class="hotel-item-two-box">
      <div class="item-hotels-img-container" (click)="openHotelPhotosDialog()">
        <img
          [src]="imageUrl"
          class="hotel-item-image"
          alt="image of {{ hotelDetail.name ? hotelDetail.name : 'hotel' }}" />
      </div>
      <div #{{hotelDetail.holidayCode}} id="{{ hotelDetail.id }}" class="hotel-item-details">
        <div class="hotel-information">
          <div class="flex-row-container flex-row-container--wrap hotel-information__first">
            <label class="hotel-item-labels" for="price{{ hotelDetail.id }}" i18n="@@itemHotelsFrom"
              >From</label
            >
            <span
              *ngIf="!data.fetchingRooms && !data.hasRooms"
              class="hotel-item-na"
              i18n="@@itemHotelsNotAvailable"
              >(Not Available)</span
            >
            <span *ngIf="data.fetchingRooms" class="hotel-item-ico">
              <i class="button_spinner light-icon-small light-icon-inline"></i>
            </span>

            <ng-container *ngIf="data.hasRooms">
              <span class="hotel-item-price" id="price{{ hotelDetail.id }}">{{
                getPrice(rooms) | number : '1.2-2'
              }}</span>
              <span class="hotel-item-price-type">{{ rooms.currencyCode }} </span>
              <span class="hotel-item-price-prpn">&nbsp;({{ rooms.prpn | number : '1.2-2' }}</span>
              <span class="hotel-item-price-prpn hotel-item-price-prpn--small">{{
                rooms.currencyCode
              }}</span>
              <section (mouseleave)="tt2.close()" class="container">
                <span
                  class="hotel-item-price-prpn"
                  ngbTooltip="Per Room Per Night"
                  i18n-ngbTooltip
                  aria-label="Per Room Per Night"
                  i18n-aria-label
                  triggers="manual"
                  #tt2="ngbTooltip"
                  (mouseenter)="tt2.open()"
                  >&nbsp;<span i18n="@@itemHotelsPRPN">prpn</span>)</span
                >
              </section>

              <hr />
            </ng-container>
          </div>
          <div class="flex-row-container flex-row-container--wrap" *ngIf="data.hasRooms">
            <span *ngIf="rooms.currencyCode !== localCurrency" class="hotel-item-price-prpn">
              <ng-container *ngIf="getConvertedTotal(rooms) as convertedTotal">
                (<span i18n="@@itemHotelsRoomRateApprox">Average room rate approx</span>:
                {{ convertedTotal }} {{ localCurrency }}
                {{ convertedTotal / noOfNights | number : '1.2-2' }}
                <section (mouseleave)="tt3.close()" class="container">
                  <span
                    i18n-ngbTooltip
                    i18n-aria-label
                    ngbTooltip="Per Room Per Night"
                    aria-label="Per Room Per Night"
                    triggers="manual"
                    #tt3="ngbTooltip"
                    i18n="@@itemHotelsPRPN"
                    (mouseenter)="tt3.open()"
                    >prpn</span
                  >
                </section>
                )
              </ng-container>
            </span>
          </div>
          <span
            class="location_span"
            *ngIf="!hotelDetail.location"
            i18n="@@itemHotelsLocationDataNA">
            Location data not available.
          </span>
          <p class="price-detail" i18n="@@itemHotelsPriceRooms">
            Price for {{ noOfRooms }} {noOfRooms, plural, =1 {room} other {rooms}}
            {{ noOfNights }} {noOfNights, plural, =1{night} other {nights}}
          </p>
          <p class="hotel-item-name" [innerHTML]="hotelDetail.name"></p>
          <!-- Changed to inner html as some hotel names have html in them  -->
          <p>{{ getAddress(hotelDetail) }}</p>
          <p class="stars_container">
            <ng-container *ngIf="hotelDetail.stars">
              <i
                class="ico-star"
                *ngIf="hotelDetail.stars >= 0.5"
                [ngClass]="{ half_star: hotelDetail.stars === 0.5 }"
                aria-hidden="true"></i>
              <i
                class="ico-star"
                *ngIf="hotelDetail.stars >= 1.5"
                [ngClass]="{ half_star: hotelDetail.stars === 1.5 }"
                aria-hidden="true"></i>
              <i
                class="ico-star"
                *ngIf="hotelDetail.stars >= 2.5"
                [ngClass]="{ half_star: hotelDetail.stars === 2.5 }"
                aria-hidden="true"></i>
              <i
                class="ico-star"
                *ngIf="hotelDetail.stars >= 3.5"
                [ngClass]="{ half_star: hotelDetail.stars === 3.5 }"
                aria-hidden="true"></i>
              <i
                class="ico-star"
                *ngIf="hotelDetail.stars >= 4.5"
                [ngClass]="{ half_star: hotelDetail.stars === 4.5 }"
                aria-hidden="true"></i>
              <span class="sr-only"
                >{{ hotelDetail.stars }}&nbsp;<span i18n="@@itemHotelsStars">stars</span></span
              >
            </ng-container>
          </p>
        </div>

        <div class="hotel-geo">
          <div *ngIf="hotelDetail.transports?.length">
            <span class="location_span">
              <span i18n="@@itemHotelsLocation">Location</span>:
              <span *ngFor="let transport of hotelDetail.transports">
                <ng-container *ngIf="transport.type === 'MetroStation'">
                  <span
                    >&nbsp; <i class="ico-metro" aria-hidden="true"></i>
                    <span class="visuallyhidden">Closest Underground Station</span>
                    &nbsp;{{
                      transport | switchDistanceFormat : distanceUnit | number : '1.1-1'
                    }}&nbsp;<span i18n="@@itemHotelsDistanceTypes">{{
                      distanceUnit | lowercase
                    }}</span></span
                  >
                </ng-container>
                <ng-container *ngIf="transport.type === 'TrainStation'">
                  <span
                    >&nbsp;&nbsp; <i class="ico-rail" aria-hidden="true"></i>
                    <span class="visuallyhidden">Closest Train Station</span>
                    &nbsp;{{
                      transport | switchDistanceFormat : distanceUnit | number : '1.1-1'
                    }}&nbsp;<span i18n="@@itemHotelsDistanceTypes">{{
                      distanceUnit | lowercase
                    }}</span></span
                  >
                </ng-container>
              </span>
            </span>

            <div *ngFor="let transport of hotelDetail.transports">
              <div class="location_span" *ngIf="transport.type === 'Airport'">
                <span
                  >&nbsp;&nbsp; <i class="ico-flight" aria-hidden="true"></i>
                  <span class="visuallyhidden">Closest Airport</span>
                  &nbsp;{{
                    transport | switchDistanceFormat : distanceUnit | number : '1.1-1'
                  }}&nbsp;<span i18n="@@itemHotelsDistanceTypes">{{
                    distanceUnit | lowercase
                  }}</span></span
                >
                <span
                  *ngIf="
                    hotelDetail.Details.airport_details && hotelDetail.Details.airport_details.name
                  "
                  >&nbsp;&nbsp; <i class="ico-flight" aria-hidden="true"></i>&nbsp;{{
                    transport.name
                  }}</span
                >
              </div>
            </div>
          </div>

                    <div class="ratings-and-featured-tags"  *ngIf="(hotelRatingsInfo || hotelGroupTags) && (hotelRatingsInfo?.rating || hotelRatingsInfo?.staticTags?.length || hotelGroupTags?.staticTags?.length || hotelRatingsInfo.numberOfBookings)">
                      <ng-container *ngIf="hotelRatingsInfo.numberOfBookings">
                        <div class="count-container">
                          <span i18n="@@hotelBookedBefore">Your company has booked this hotel</span>
                          <span *ngIf="hotelRatingsInfo.numberOfBookings === 1" i18n="@@hotelBookedBeforeSingle">&nbsp;before!</span>
                          <span *ngIf="hotelRatingsInfo.numberOfBookings > 1 && hotelRatingsInfo.numberOfBookings < 1000" i18n="@@hotelBookedBeforeMultiple">&nbsp;{{hotelRatingsInfo.numberOfBookings}} times before!</span>
                          <span *ngIf="hotelRatingsInfo.numberOfBookings >= 1000" i18n="@@hotelBookedBeforeLot">&nbsp;a lot!</span>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="hotelGroupTags?.staticTags?.length">
                          <ng-container *ngFor="let staticTag of hotelGroupTags.staticTags">
                              <div class="hotel-featured-tag" *ngIf="staticTag.displayType === staticTagDisplay.Featured">
                                  <section (mouseleave)="ttHGF.close()"
                                            class="container">
                                      <fa-icon [icon]="iconLibrary.definitions.far[staticTag.icon]"
                                                triggers="manual"
                                                #ttHGF="ngbTooltip"
                                                (mouseenter)="ttHGF.open()"
                                                (focus)="ttHGF.open()"
                                                (blur)="ttHGF.close()"
                                                tabindex="0"
                                                [ngbTooltip]="staticTag.name"
                                                [attr.aria-label]="staticTag.name"></fa-icon>
                                  </section>
                              </div>
                          </ng-container>
                      </ng-container>
                      <ng-container *ngIf="hotelRatingsInfo?.staticTags?.length">
                          <ng-container *ngFor="let staticTag of hotelRatingsInfo.staticTags">
                              <div class="hotel-featured-tag" *ngIf="staticTag.displayType === staticTagDisplay.Featured">
                                  <section (mouseleave)="ttHF.close()"
                                            class="container">
                                      <fa-icon [icon]="iconLibrary.definitions.far[staticTag.icon]"
                                                triggers="manual"
                                                #ttHF="ngbTooltip"
                                                (mouseenter)="ttHF.open()"
                                                (focus)="ttHF.open()"
                                                (blur)="ttHF.close()"
                                                tabindex="0"
                                                [ngbTooltip]="staticTag.name"
                                                [attr.aria-label]="staticTag.name"></fa-icon>
                                  </section>
                              </div>
                          </ng-container>
                      </ng-container>
                      <section (mouseleave)="ttHR.close()"
                                class="container"
                                *ngIf="hotelRatingsInfo?.rating">
                          <div class="hotel-rating"
                              #ttHR="ngbTooltip"
                              (mouseenter)="ttHR.open()"
                              (focus)="ttHR.open()"
                              (blur)="ttHR.close()"
                              tabindex="0"
                              ngbTooltip="This score is the average based on users who stayed at this property from your company."
                              (click)="openHotelReviewsDialog()"
                              (keyup.enter)="openHotelReviewsDialog()"
                              role="button"
                              [attr.aria-label]="'View User Ratings for ' + hotelDetail.name">
                              {{hotelRatingsInfo.rating.toFixed(1).replace(".0","")}}/5
                          </div>
                      </section>
                    </div>
                    

          <div class="preferred-container">
            <ng-container *ngIf="hotelGroupTags && hotelGroupTags.staticTags?.length">
              <ng-container *ngFor="let staticTag of hotelGroupTags.staticTags">
                <div
                  class="hotel-featured-tag"
                  *ngIf="staticTag.displayType === staticTagDisplay.Standard">
                  <section (mouseleave)="ttHS.close()" class="container">
                    <fa-icon
                      [icon]="iconLibrary.definitions.far[staticTag.icon]"
                      triggers="manual"
                      #ttHS="ngbTooltip"
                      (mouseenter)="ttHS.open()"
                      (focus)="ttHS.open()"
                      (blur)="ttHS.close()"
                      tabindex="0"
                      [ngbTooltip]="staticTag.name"
                      [attr.aria-label]="staticTag.name"></fa-icon>
                  </section>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="hotelRatingsInfo && hotelRatingsInfo.staticTags?.length">
              <ng-container *ngFor="let staticTag of hotelRatingsInfo.staticTags">
                <div
                  class="hotel-featured-tag"
                  *ngIf="staticTag.displayType === staticTagDisplay.Standard">
                  <section (mouseleave)="ttHS.close()" class="container">
                    <fa-icon
                      [icon]="iconLibrary.definitions.far[staticTag.icon]"
                      triggers="manual"
                      #ttHS="ngbTooltip"
                      (mouseenter)="ttHS.open()"
                      (focus)="ttHS.open()"
                      (blur)="ttHS.close()"
                      tabindex="0"
                      [ngbTooltip]="staticTag.name"
                      [attr.aria-label]="staticTag.name"></fa-icon>
                  </section>
                </div>
              </ng-container>
            </ng-container>
            <section
              (mouseleave)="tt4.close()"
              *ngIf="hotelDetail.preferred.client"
              class="container">
              <app-image-with-error
                alt="Preferred supplier logo"
                logoUrl="{{ preferredLogoUrl?.imageSrc }}"
                errorIcon="ico-heart"
                ngbTooltip="Preferred"
                aria-label="Preferred"
                i18n-ngbTooltip="@@HotelIconPreferred"
                triggers="manual"
                #tt4="ngbTooltip"
                (mouseenter)="tt4.open()"
                (focus)="tt4.open()"
                (blur)="tt4.close()"
                class="heart-img flex-row-container preferred-container">
              </app-image-with-error>
            </section>
            <app-preferred-icons
              [tmcPreferred]="showTMCPreferredLogo"
              [tmcPreferredLogoUrl]="tmcPreferredLogo"
              [tmcPreferredMessage]="tmcPreferredMessage"
              [clientPreferred]="preferred"
              [companyPreferredMessage]="companyPreferredMessage"
              [officePreferredMessage]="officePreferredMessage">
            </app-preferred-icons>
            <span
              class="flex-row-container flex-row-container--v-center"
              *ngFor="let tag of hotelDetail.sortedTags | slice : 0 : 3; let i = index">
              <section (mouseleave)="tt6.close()" class="container">
                <img
                  src="{{ tag.icon }}"
                  height="24"
                  width="24"
                  ngbTooltip="{{ tag.display }}"
                  triggers="manual"
                  #tt6="ngbTooltip"
                  (mouseenter)="tt6.open()"
                  (focus)="tt6.open()"
                  (blur)="tt6.close()"
                  tabindex="0"
                  i18n-ngbTooltip
                  attr.alt="{{ tag.Text }}" />
              </section>
              <div
                *ngIf="i == 2"
                class="preferred-container__more"
                (click)="open(hotelDetail)"
                i18n="@@itemHotelsMore"
                role="button"
                tabindex="0"
                (keyup.enter)="open(hotelDetail)">
                more&hellip;
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="item-hotels-details">
      <div class="hotel-item-distance">
        <span> <span i18n="@@itemHotelsDistance2">Distance</span>: </span>
        <span class="hotel-item-distance-details"
          >{{ hotelDetail.location | switchDistanceFormat : distanceUnit | number : '1.1-1' }}
          <span i18n="@@itemHotelsDistanceTypes">{{ distanceUnit | lowercase }}</span>
        </span>
      </div>
      <div class="hotel-item-icon-list">
        <!-- <section
        (click)="log(hotelDetail)"
        (mouseleave)="tt7.close()"
                 *ngIf='data?.rooms?.rooms && !data?.rooms?.rooms[0].CanBook'
                 class="container">
          <img src="{{resultItemType.minus}}"
               class='error_icon'
               placement="top"
               triggers="manual"
               #tt7="ngbTooltip"
               (mouseenter)="tt7.open()"
               (focus)="tt7.open()"
               (blur)="tt7.close()"
               tabindex="0"
               ngbTooltip="{{data?.rooms?.rooms[0].unavailableMessage | policyChanger}}"
               attr.aria-label="{{data?.rooms?.rooms[0].unavailableMessage | policyChanger}}"><span
                class="visuallyhidden">{{data?.rooms?.rooms[0].unavailableMessage | policyChanger}}</span>
        </section> -->
        <ng-container
          *ngIf="
            data.jitRulesReceived &&
            !hotelDetail.jitRemoveResultRule &&
            data?.rooms?.rooms?.length &&
            (data?.rooms?.rooms | hotelRoomsStatus) as roomsStatus
          ">
          <ng-container [ngSwitch]="roomsStatus.status">
            <ng-container *ngIf="data.hasRooms">
              <section class="container" *ngSwitchCase="hotelRoomStatus.Available">
                <img
                  src="{{ resultItemType.check }}"
                  class="error_icon"
                  placement="top"
                  triggers="manual"
                  alt="Able to book {{ hotelDetail.name }}"
                  tabindex="0" />
              </section>
              <section
                (mouseleave)="tt10.close()"
                *ngSwitchCase="hotelRoomStatus.Reason"
                class="container">
                <img
                  src="{{ resultItemType.exclamation }}"
                  class="error_icon"
                  placement="top"
                  [class.can-book-overridden]="hotelDetail.CanBookOverridden"
                  ngbTooltip="{{ roomsStatus.message | policyChanger }}"
                  attr.aria-label="{{ roomsStatus.message | policyChanger }}"
                  triggers="manual"
                  #tt10="ngbTooltip"
                  (mouseenter)="tt10.open()"
                  (focus)="tt10.open()"
                  alt="Require reason to book {{ hotelDetail.name }}"
                  (blur)="tt10.close()"
                  tabindex="0" /><span class="visuallyhidden">{{
                  roomsStatus.message | policyChanger
                }}</span>
              </section>
            </ng-container>
            <section
              *ngSwitchCase="hotelRoomStatus.Unavailable"
              (mouseleave)="tt8.close()"
              class="container">
              <img
                src="{{ resultItemType.minus }}"
                class="error_icon"
                placement="top"
                (click)="log(hotelDetail, data)"
                ngbTooltip="{{ roomsStatus.message | policyChanger }}"
                attr.aria-label="{{ roomsStatus.message | policyChanger }}"
                triggers="manual"
                #tt8="ngbTooltip"
                alt="Unable to book {{ hotelDetail.name }}"
                (mouseenter)="tt8.open()"
                (focus)="tt8.open()"
                (blur)="tt8.close()"
                tabindex="0" /><span class="visuallyhidden">{{
                roomsStatus.message | policyChanger
              }}</span>
            </section>
          </ng-container>
          <!-- <section (mouseleave)="tt9.close()"
                  *ngIf='hotelDetail.CanBook && hotelDetail.RequiresReason'
                  class="container">

            <img src="{{resultItemType.exclamation}}"
                class="error_icon"
                placement="top"
                [class.can-book-overridden]="hotelDetail.CanBookOverridden"
                ngbTooltip="{{hotelDetail.ReasonMessage | policyChanger }}"
                attr.aria-label="{{hotelDetail.ReasonMessage | policyChanger }}"
                triggers="manual"
                #tt9="ngbTooltip"
                (mouseenter)="tt9.open()"
                (focus)="tt9.open()"
                (blur)="tt9.close()"
                tabindex="0"><span class="visuallyhidden">{{hotelDetail.ReasonMessage | policyChanger }}</span>
          </section> -->
        </ng-container>
        <span>
          <a
            class="add_to_cart_link"
            id="hotel-add-to-cart-{{ index }}"
            *ngIf="
              data.jitRulesReceived &&
                !hotelDetail.jitRemoveResultRule &&
                data?.rooms?.rooms?.length;
              else NoAvailableRates
            "
            (click)="openRoomRates(data.rooms.rooms)"
            role="link"
            (keyup.enter)="openRoomRates(data.rooms.rooms)"
            tabindex="0"
            [attr.aria-label]="'View Rate Options for ' + hotelDetail.name"
            i18n="@@itemHotelsClickforRates"
            >View Rate Options</a
          >
        </span>
        <div
          *ngIf="
            !data.jitRulesReceived ||
            (!(data?.rooms | keyvalue).length && hotelDetail.jitRemoveResultRule) ||
            (hotelDetail.jitRemoveResultRule && data?.rooms?.rooms?.length)
          "
          class="rates-applying-message"
          i18n="@@itemHotelsRatesDisplayWhenApplied">
          Rates will be displayed when policy has been applied.
        </div>
      </div>
      <div class="item-buttons">
        <div
          *ngIf="hotelDetail"
          class="info-box"
          (click)="openHotelResultModal(0)"
          role="link"
          [ngClass]="{
            no_event: !data.jitRulesReceived || hotelDetail.jitRemoveResultRule
          }"
          (keyup.enter)="openHotelResultModal(0)"
          tabindex="0">
          <span
            *ngIf="
              data.jitRulesReceived &&
                !hotelDetail.jitRemoveResultRule &&
                data?.rooms?.rooms?.length;
              else NoRates
            ">
            <span i18n="@@itemHotelsSeeAll">See All</span>
            {{ data.rooms.rooms.length }}
            <span i18n="@@itemHotelsRates">&nbsp;Rates</span>
            <span class="visuallyhidden">&nbsp; for {{ hotelDetail.name }}</span>
          </span>
          <div
            *ngIf="
              !data.jitRulesReceived ||
              (!(data?.rooms | keyvalue).length && hotelDetail.jitRemoveResultRule) ||
              (hotelDetail.jitRemoveResultRule && data?.rooms?.rooms?.length)
            "
            i18n="@@itemHotelsApplyingPolicy">
            Applying policy
          </div>
        </div>

        <div
          class="info-box"
          *ngIf="showAllPhotos"
          (click)="openHotelResultModal(1)"
          (keyup.enter)="openHotelResultModal(1)"
          i18n="@@itemHotelsSeePhotos"
          role="button"
          tabindex="0">
          See Photos
        </div>
        <div
          class="info-box"
          (click)="showOnMap()"
          (keyup.enter)="showOnMap()"
          role="button"
          i18n="@@itemHotelsMap"
          tabindex="0">
          Map
        </div>
        <div
          (click)="openHotelResultModal(2)"
          (keyup.enter)="openHotelResultModal(2)"
          class="info-box"
          role="button"
          i18n="@@itemHotelsInfo"
          tabindex="0">
          Info
        </div>
        <div
          (click)="openHotelResultModal(3)"
          (keyup.enter)="openHotelResultModal(3)"
          class="info-box"
          role="button"
          *ngIf="hotelRatingsInfo && hotelRatingsInfo.rating"
          i18n="@@itemHotelsShowUserReview"
          tabindex="0">
          User Review
        </div>
      </div>
    </div>
  </div>
</ng-container>
