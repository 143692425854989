import { Component, OnInit } from '@angular/core';
import { WithSubscriptionComponent } from '@sabstravtech/obtservices/angular';
import { FADE_IN_OUT } from '../../../startup/data/animations';
import {
  ServiceType,
  SearchUsersCanBookForFetcher,
  EnterpriseSearchService,
  UserService,
  HelperRoutines,
  IrlJourneyType
} from '@sabstravtech/obtservices/angular';
import { Traveller, RecentSearch, LocationTypes } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-recent-searches',
  templateUrl: './recent-searches.component.html',
  styleUrls: ['./recent-searches.component.scss'],
  animations: [FADE_IN_OUT]
})
export class RecentSearchesComponent extends WithSubscriptionComponent implements OnInit {
  public serviceType: typeof ServiceType = ServiceType;
  public irlJourneyType: typeof IrlJourneyType = IrlJourneyType;
  public recentSearches: RecentSearch[];
  searchParams: any; // BaseEnterpriseSearch;
  public locationTypes: typeof LocationTypes = LocationTypes;

  constructor(
    public searchUsersCanBookForFetcher: SearchUsersCanBookForFetcher,
    public searchService: EnterpriseSearchService,
    public userService: UserService,
    private helpers: HelperRoutines
  ) {
    super();
  }

  ngOnInit() {
    this.getUserRecentSearches();
  }

  private getUserRecentSearches() {
    this.subscribe(this.searchService.getUserRecentSearches(), (data: RecentSearch[]) => {
      this.recentSearches = data.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));
    });
  }

  public performRecentSearch(recentSearch: RecentSearch) {
    console.log(recentSearch);
    this.searchService.search_objects = this.helpers.clone(
      this.searchService.init_search_objects()
    );
    this.addToTravellerSelection(recentSearch.travellers);

    switch (recentSearch.serviceType) {
      case ServiceType.Rail:
        this.getParams(recentSearch);
        break;

      case ServiceType.InternationalRail:
        this.getParams(recentSearch);
        break;

      case ServiceType.Hotel:
        this.getParams(recentSearch);
        break;

      case ServiceType.Car:
        this.getParams(recentSearch);
        break;

      case ServiceType.Flight:
        this.getParams(recentSearch);
        break;

      case ServiceType.Parking:
        this.getParams(recentSearch);
        break;

      case ServiceType.Lounge:
        this.getParams(recentSearch);
        break;

      case ServiceType.Cab:
        this.getParams(recentSearch);
        break;
      case ServiceType.Eurostar:
        this.getParams(recentSearch);
        break;
      case ServiceType.FastTrack:
        this.getParams(recentSearch);
        break;
      default:
        break;
    }
  }

  private addToTravellerSelection(travellers: Traveller[]): void {
    // remove exist Travellers if they are
    if (this.searchService.getCurrentNoOfTravellers() >= 1) {
      this.searchService.removeAllTravellers();
    }
    // add recent search Travellers
    if (travellers) {
      travellers.forEach((traveller: Traveller) => {
        this.searchService.addTraveller(traveller);
      });
    }
  }

  getParams(recentSearch: RecentSearch): void {
    this.searchParams = this.searchService.searches[recentSearch.serviceType];
    this.searchService.search_objects[recentSearch.serviceType].chosen = true;
    this.searchService.search_objects[recentSearch.serviceType].originalChosen = true;
    this.searchService.highest_search_priority =
      this.searchService.search_objects[recentSearch.serviceType].priority;
    this.searchParams.startRecentSearch(recentSearch);
  }

  public removeRecentSearchItem(item: RecentSearch, $event) {
    $event.stopPropagation();

    this.searchService.deleteRecentSearchByID(item.id as string).subscribe(response => {
      if (response) {
        this.recentSearches.splice(this.recentSearches.indexOf(item), 1);
      }
    });
  }
}

