<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            id="modal-header">Move Item to a Different Basket</h3>
        <button type="button"
                class="close"
                ngbAutoFocus
                (click)="close()"
                (keydown.enter)="close()"
                (keydown.space)="close()"
                aria-label="Close Move Item to a Different Basket Modal">
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="flex-column-container">
            <ng-container *ngFor="let basket of baskets; let index = index">
                <button class="small-button"
                        *ngIf="basket.id !== selectedBasketId"
                        (click)="moveItemToDifferentBasket(basket)">
                    Move item to {{basket.title}}
                </button>
            </ng-container>
        </div>
    </div>
</div>