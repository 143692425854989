<div class="pnl-item"
     *ngFor="let item of data?.results | parkingPriceSort; let index = index;">
    <div class="pnl-image">
        <img [src]="item.carPark.logo"
             alt="{{item.carPark.name}} logo">
    </div>
    <div class="pnl-desc">
        <h3>{{item.carPark.name}}</h3>
        <app-preferred-icons 
            [tmcPreferred] = "item.tmcPreferred"
            [tmcPreferredLogoUrl] = "item.tmcPreferredLogoUrl"
            [tmcPreferredMessage]="item.tmcPreferredMessage"
            [clientPreferred] = "item.officePreferred || item.companyPreferred"
            [companyPreferredMessage] = "item.companyPreferredMessage"
            [officePreferredMessage] = "item.officePreferredMessage">
        </app-preferred-icons>
        <div class="parking-filters">
            <ng-container *ngFor="let type of item?.carPark | parkingFilters">
                <div *ngIf="type"><i class="ico-check-1 light-color-primary"></i>{{type | translate}}</div>
            </ng-container>
        </div>
        <div class="parking-tip">
            <div class="parking-text">
                <ng-container
                              *ngIf="item.carPark.misc.seoPageTransferTime || (item.carPark.misc.transferFrequency && item.carPark.misc.transferFrequency!=='0')">
                    <span i18n="@@pnltransfersRun">Transfers run</span>
                    <span class="light-fat light-color-primary">
                        <span i18n="@@pnlEvery"
                              *ngIf="item.carPark.misc.transferFrequency">every</span>
                        {{item.carPark.misc.transferFrequency}}
                        mins</span><span class="light-fat light-color-primary"
                          *ngIf="!item.carPark.misc.transferFrequency"
                          i18n="@@pnlFrequently"> frequently </span><span i18n="@@pnlAndTake"
                          *ngIf="item.carPark.misc.seoPageTransferTime">and take</span><span
                          class="light-fat light-color-primary"
                          *ngIf="item.carPark.misc.seoPageTransferTime">{{item.carPark.misc.seoPageTransferTime}}
                        mins</span>.
                </ng-container>
            </div>
            <app-tooltips [tooltipText]="item.carPark.information"></app-tooltips>
        </div>

        <section class="container">
            <button (click)='viewMapPage(item)'
                    attr.aria-label="Go to map view for {{ item.carPark.name }}"
                    class="light-btn light-btn-ico light-btn-primary">
                <i class="ico-map" aria-hidden="true"></i>
            </button>
        </section>
    </div>
    <div class="pnl-actions">
        <button (click)="addToCart(item)"
                [disabled]="item.unavailable && !canOverride"
                class="light-btn light-btn-fancy"
                id="parking-add-to-basket-{{index}}"
                attr.aria-label="{{item?.price | currency: item.currency || 'GBP'}} add to cart {{item.carPark.name}}">
            <span class="price-wrapper">
                <span>{{item.price | currency: item.currency || 'GBP'}}</span>
            </span>
            <span i18n="@@pnlAddToCart">Add to basket</span>
        </button>
        <app-warning-tags [ticket]="item"></app-warning-tags>
    </div>
    <div class="pnl-details"
         (click)="toggleItemDetails(index)"
         (keydown.enter)="toggleItemDetails(index)"
         attr.aria-expanded="{{showItemDetails[index]? 'true': 'false'}}"
         aria-controls="itemexpanded"
         aria-live="polite"
         role="button"
         tabindex="0"
         attr.aria-label="{{showItemDetails[index]? 'Hide': 'Show'}} additional information for {{item.carPark.name}}"
         aria-live="polite">
        <span *ngIf="showItemDetails[index]"
              i18n="@@pnlHideAdditionalInformation">Hide additional information</span>
        <span *ngIf="!showItemDetails[index]"
              i18n="@@pnlShowAdditionalInformation">Show additional information</span>
    </div>
    <app-parking-details *ngIf="showItemDetails[index]"
                         [parking]="item"
                         class="parking-details"></app-parking-details>
</div>