<div class="journey_header">
  <h4>
    <span *ngIf="direction === RailDirection.Outbound" i18n="@@railComponetOutboundtitle">
      Outbound Journey</span
    >
    <span *ngIf="direction === RailDirection.Inbound" i18n="@@railComponetInboundtitle">
      Inbound Journey</span
    >
    -
    <span class="journey_date">{{ date }}</span>
  </h4>
  <div>
    <span i18n="@@railComponentOutboundFrom">From</span>&nbsp;
    {{ from }}
    <section (mouseleave)="tt46.close()" *ngIf="from === 'London Terminals'" class="container">
      <i
        class="ico-info"
        placement="bottom"
        triggers="manual"
        #tt46="ngbTooltip"
        (mouseenter)="tt46.open()"
        ngbTooltip="Different station options are available on the route, please click on
                  ‘details’ for more information on a specific train."
        aria-label="Different station options are available on the route, please click on
                  ‘details’ for more information on a specific train."
        (focus)="tt46.open()"
        (blur)="tt46.close()"
        tabindex="0"
        ><span class="visuallyhidden"
          >Different station options are available on the route, please click on ‘details’ for more
          information on a specific train.</span
        ></i
      >
    </section>
    <!-- TODO add in message about multiple departures -->
  </div>
  <div>
    <span i18n="@@railComponentOutboundTo">To</span>&nbsp;
    {{ to }}
    <section (mouseleave)="tt46.close()" *ngIf="to === 'London Terminals'" class="container">
      <i
        class="ico-info"
        placement="bottom"
        triggers="manual"
        #tt46="ngbTooltip"
        (mouseenter)="tt46.open()"
        ngbTooltip="Different station options are available on the route, please click on
                  ‘details’ for more information on a specific train."
        aria-label="Different station options are available on the route, please click on
                  ‘details’ for more information on a specific train."
        (focus)="tt46.open()"
        (blur)="tt46.close()"
        tabindex="0"
        ><span class="visuallyhidden"
          >Different station options are available on the route, please click on ‘details’ for more
          information on a specific train.</span
        ></i
      >
    </section>
    <!-- TODO add in message about multiple destinations -->
  </div>
</div>
<span class="border_line"></span>
<app-rail-results-header [departStation]="to" [results]="journeys"></app-rail-results-header>
<div class="time_button_conatiner">
  <div class="flex-row-container flex-column-mobile">
    <button
      class="small-button small-button--margin previous_day"
      (click)="prevDay.emit()"
      i18n-aria-label
      id="rail-results-button-previous-day-{{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}"
      attr.aria-label="Previous Day Rail Times {{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}">
      <i class="ico-chevron-left" aria-hidden="true"></i>
      <span i18n="@@railPreviousDay">Previous Day</span>
    </button>
    <button
      class="small-button small-button--margin"
      (click)="earlier.emit()"
      i18n-aria-label
      id="rail-results-button-earlier-{{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}"
      attr.aria-label="Earlier Rail Times {{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}">
      <i class="ico-chevron-left" aria-hidden="true"></i>
      <span i18n="@@railEarlier">Earlier</span>
    </button>
  </div>
  <div class="later_rail_times_container flex-row-container flex-column-mobile">
    <button
      class="small-button small-button--margin later_rail_times"
      (click)="later.emit()"
      i18n-aria-label
      id="rail-results-button-later-{{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}"
      attr.aria-label="Later Rail Times {{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}">
      <span i18n="@@railLater">Later</span>
      <i class="ico-chevron-right" aria-hidden="true"></i>
    </button>
    <button
      class="small-button small-button--margin later_rail_times next_day"
      (click)="nextDay.emit()"
      i18n-aria-label
      id="rail-results-button-next-day-{{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}"
      attr.aria-label="Next Day Rail Times {{
        direction === RailDirection.Outbound ? 'outbound' : 'inbound'
      }}">
      <span i18n="@@railNextDay">Next Day</span>
      <i class="ico-chevron-right" aria-hidden="true"></i>
    </button>
  </div>
</div>
<span class="border_line"></span>
<span class="visuallyhidden" role="alert">{{ getHiddenText() }}</span>
<ng-container *ngFor="let ticketType of ticketTypes">
  <table class="data train_journey_table train_ticket_table">
    <ng-container *ngFor="let fareType of getTicketTypes | memoize : ticketType; let index = index">
      <tr *ngIf="index === 0">
        <th class="fares-header">
          {{ ticketType | translate }}
        </th>
      </tr>
      <tr
        *ngFor="
          let temp of []
            .constructor(
              expandedTickets[ticketType][fareType.key] && fareType.price ? fareType.value : 1
            )
            .fill(0);
          let index = index
        ">
        <th>
          <button
            [ngbTooltip]="
              showSplitTooltip(
                expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                fareTypes,
                ticketType,
                fareType.key,
                index
              )
                ? 'To view the Splitsave journey details, please add the item to your basket.'
                : ''
            "
            (mouseleave)="tt11.close()"
            triggers="manual"
            #tt11="ngbTooltip"
            (mouseenter)="tt11.open()"
            (focus)="tt11.open()"
            (blur)="tt11.close()"
            class="ticket_details_link"
            (click)="
              openRailTicketDetailsDialog(
                expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                fareTypes,
                ticketType,
                fareType.key,
                index
              )
            ">
            <span>
              {{ fareType.key | titlecase }}
              <i
                class="ico-info"
                *ngIf="railcardCode"
                [ngbTooltip]="railcardCode"
                placement="right"></i>
            </span>
          </button>
          <div class="icon-group">
            <section
              (mouseleave)="tt7.close()"
              *ngIf="
                isEticketAvailable(
                  expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                  fareTypes,
                  ticketType,
                  fareType.key,
                  index
                )
              "
              class="container">
              <img
                i18n-ngbTooltip
                ngbTooltip="This is an eTicket that may be SelfPrinted."
                alt="This is an eTicket that may be SelfPrinted."
                [src]="eTicketIcon"
                class="ico-float-left"
                tabindex="0"
                triggers="manual"
                #tt7="ngbTooltip"
                (mouseenter)="tt7.open()"
                (focus)="tt7.open()"
                (blur)="tt7.close()" />
            </section>

            <section
              (mouseleave)="tt7.close()"
              *ngIf="
                isLimitedRow(
                  expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                  fareTypes,
                  ticketType,
                  fareType.key,
                  index
                )
              "
              class="container">
              <span i18n="@@railLimitedSeats">(Limited seats)</span>
            </section>
            <section
              *ngIf="
                isGroupFare(
                  expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                  fareTypes,
                  ticketType,
                  fareType.key,
                  index
                )
              "
              class="container">
              <div [ngSwitch]="sourceName">
                <div *ngSwitchCase="ServiceProvider.Evolvi" (mouseleave)="tt10.close()">
                  <img
                    src="https://images.sabscorp.com/images/GroupDiscount.gif"
                    ngbTooltip="Group ticket - to qualify for this fare all travellers must travel together on the same ticket"
                    alt="Group ticket"
                    class="ico-float-left"
                    tabindex="0"
                    triggers="manual"
                    #tt10="ngbTooltip"
                    (mouseenter)="tt10.open()"
                    (focus)="tt10.open()"
                    (blur)="tt10.close()" />
                </div>
                <div *ngSwitchCase="ServiceProvider.Trainline" (mouseleave)="tt10.close()">
                  <span
                    ngbTooltip="GroupSave discount applied. All passengers must travel together"
                    class="ico-tl-group-save"
                    triggers="manual"
                    #tt10="ngbTooltip"
                    (mouseenter)="tt10.open()"
                    (focus)="tt10.open()"
                    (blur)="tt10.close()"
                    >GroupSave</span
                  >
                </div>
              </div>
            </section>
          </div>

          <!-- <button (click)="log(fareTypes[ticketType])">
                        {{fareType.value}}
                    </button> -->
          <div class="flex-row-container train-icons-container">
            <section
              *ngIf="
                isTrainlineSplitFare(
                  expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                  fareTypes,
                  ticketType,
                  fareType.key,
                  index
                )
              "
              class="container">
              <span>
                <img
                  class="split-ticket-logo"
                  src="https://images.sabscorp.com/images/enterprise/lightUK/assets/png/split-ticket.png"
                  alt="Split Tickets"
              /></span>
            </section>
            <i
              *ngIf="
                !expandedTickets[ticketType][fareType.key] &&
                fareType.value > 1 &&
                fareType.price &&
                index === 0
              "
              (click)="expandedTickets[ticketType][fareType.key] = true"
              container="body"
              placement="top"
              tabindex="0"
              role="button"
              triggers="manual"
              class="ico-float-right ico-add"
              attr.aria-label="+ Show other tickets for {{ fareType.key | titlecase }} {{
                direction === RailDirection.Outbound ? 'outbound' : 'inbound'
              }}"
              i18n-aria-label
              aria-expanded="false"
              ><span i18n="@@railResultsBlockShowOther" class="visuallyhidden"
                >Show other tickets</span
              ></i
            >
            <i
              *ngIf="
                expandedTickets[ticketType][fareType.key] &&
                fareType.value > 1 &&
                fareType.price &&
                index === 0
              "
              (click)="expandedTickets[ticketType][fareType.key] = false"
              container="body"
              placement="top"
              tabindex="0"
              role="button"
              triggers="manual"
              class="ico-float-right ico-minus"
              attr.aria-label="- Hide other tickets for {{ fareType.key | titlecase }} {{
                direction === RailDirection.Outbound ? 'outbound' : 'inbound'
              }}"
              aria-expanded="true"
              i18n-aria-label
              ><span i18n="@@railResultsBlockHideOther" class="visuallyhidden"
                >Hide other tickets</span
              ></i
            >
          </div>
        </th>
        <td *ngFor="let journey of journeys">
          <ng-container
            *ngIf="
              fieldFromObject(
                journey,
                expandedTickets[ticketType][fareType.key] || fareType.value === 1,
                fareTypes,
                ticketType,
                fareType.key,
                index
              ) || {} as ticket
            ">
            <!-- [disabled]="!ticket.selectable" -->
            <!-- {{log(direction,ticket, journey, ticketType, fareType.key)}} -->
            <ng-container
              [ngTemplateOutlet]="
                ticket.selectable && (!ticket.unavailable || canOverride) ? button : disabled_button
              "
              [ngTemplateOutletContext]="{ ticket, journey, ticketType }"></ng-container>
            <div class="white-theme">
              <app-preferred-icons
                [tmcPreferred]="ticket.tmcPreferred"
                [tmcPreferredLogoUrl]="ticket.tmcPreferredLogoUrl"
                [tmcPreferredMessage]="ticket.tmcPreferredMessage"
                [clientPreferred]="ticket.officePreferred || ticket.companyPreferred"
                [companyPreferredMessage]="ticket.companyPreferredMessage"
                [officePreferredMessage]="ticket.officePreferredMessage">
              </app-preferred-icons>
            </div>

            <app-warning-tags [ticket]="ticket"></app-warning-tags>
          </ng-container>
        </td>
      </tr>
    </ng-container>
  </table>
</ng-container>

<ng-template #button let-ticket="ticket" let-journey="journey" let-ticketType="ticketType">
  <button
    [ngClass]="[
      ticket.selected ? 'selected-price' : 'notselected',
      direction + '-' + ticket.price,
      (ticketType === RailFareTypes.returnJourneyFares ||
        ticketType === RailFareTypes.splitFares) &&
      searchParams.selectedTicket &&
      direction === RailDirection.Inbound
        ? 'selectable_return'
        : '',
      ticketType === RailFareTypes.splitFares ? 'split_border' : ''
    ]"
    class="rail-price"
    id="{{ !!ticket.price ? direction + '-' + ticket.price : direction + '-' + 'No ticket' }}"
    (click)="onSelectTicket(journey, ticket, ticketType)"
    mat-button
    color="accent"
    attr.aria-label="{{
      (searchParams.chosenSearchType === RailSearchJourneyType.ReturnJourney &&
      this.direction === RailDirection.Inbound &&
      !searchParams.chosenSearchType.selectedTicket
        ? '-'
        : '') +
        direction ===
      RailDirection.Outbound
        ? 'Outbound'
        : 'Inbound'
    }} 
    {{ ticket.price | currency : ticket.currency }} {{ ticket.type | titlecase }}{{
      ticket.selected ? ' selected' : ''
    }}. Departing {{ journey.from }} at 
    {{ journey.departDateTime | sabsTimePipe }} arriving at {{ journey.to }} at {{
      journey.arriveDateTime | sabsTimePipe
    }}"
    [attr.aria-pressed]="ticket.selected"
    aria-live="polite">
    <ng-container
      *ngIf="
        ticketType !== RailFareTypes.returnJourneyFares ||
        !searchParams.selectedTicket ||
        direction !== RailDirection.Inbound
      ">
      {{
        ticket.price === undefined
          ? ''
          : direction === RailDirection.Inbound &&
            (ticketType === RailFareTypes.returnJourneyFares ||
              ticketType === RailFareTypes.splitFares)
          ? '-'
          : (ticket.price | currency : ticket.currency || '')
      }}
      <div class="available-seats" *ngIf="ticket.availableSeats && ticket.availableSeats < 9">
        {{ ticket.availableSeats }} <span i18n="@@railSeatsLeft">Seats Left</span>
      </div>
    </ng-container>
    <ng-container
      *ngIf="
        (ticketType === RailFareTypes.returnJourneyFares ||
          ticketType === RailFareTypes.splitFares) &&
        searchParams.selectedTicket &&
        direction === RailDirection.Inbound
      ">
      <div class="basket">
        <span class="counter">+</span>
        <img
          class="cart"
          [ngClass]="{ split_icon: ticketType === RailFareTypes.splitFares }"
          src="https://images.sabscorp.com/images/enterprise/lightUK/assets/images/empty-cart.svg"
          alt="Cart" />
      </div>
    </ng-container>
  </button>
</ng-template>

<ng-template #disabled_button let-ticket="ticket" let-journey="journey" let-ticketType="ticketType">
  <span
    [ngClass]="[ticket.selected ? 'selected-price' : 'notselected', direction + '-' + ticket.price]"
    aria-hidden="true"
    class="rail-price rail-no"
    id="{{ !!ticket.price ? direction + '-' + ticket.price : direction + '-no-ticket' }}">
    {{
      ticket.price === undefined
        ? ''
        : direction === RailDirection.Inbound &&
          (ticketType === RailFareTypes.returnJourneyFares ||
            ticketType === RailFareTypes.splitFares)
        ? '-'
        : (ticket.price | currency : ticket.currency || '')
    }}
  </span>
</ng-template>
