import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasketItem } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-basket-taxi',
  templateUrl: './basket-taxi.component.html',
  styleUrls: ['./basket-taxi.component.scss'],
})
export class BasketTaxiComponent implements OnInit {
  @Input() taxi: BasketItem;
  @Input() duplicateBooking = false;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChangeModal: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void { }
}
