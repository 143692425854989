import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BasketItem } from '@sabstravtech/obtservices/angular';
import { EnterpriseSearchService, ServiceType } from '@sabstravtech/obtservices/angular';
import { FastTrackEnterpriseSearchInterface, TerminalsType } from '@sabstravtech/obtservices/base';

@Component({
  selector: 'app-basket-fast-track',
  templateUrl: './basket-fast-track.component.html',
  styleUrls: ['./basket-fast-track.component.scss']
})
export class BasketFastTrackComponent implements OnInit {
  @Input() fastTrack: BasketItem;
  @Input() duplicateBooking = false;
  @Input() itemIndex: number = 0;
  @Output() remove: EventEmitter<void> = new EventEmitter<void>();
  @Output() openChangeModal: EventEmitter<void> = new EventEmitter<void>();

  public searchParameters: FastTrackEnterpriseSearchInterface = null;

  constructor(private searchService: EnterpriseSearchService) { }

  ngOnInit() {
    this.searchParameters = this.searchService.searches[ServiceType.FastTrack];
  }

}
