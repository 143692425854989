import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { ServiceProvider } from '@sabstravtech/obtservices/base';
import {
  CarHireType,
  CarHireVehicleAvailibility,
  CarHireDetailRequestResponse,
  EnterpriseSearchService,
  ServiceType,
  CarHireIdentifiers,
  CarHireAdditionalInfoItem
} from '@sabstravtech/obtservices/angular';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-gmt-car-fare-rules-modal',
  templateUrl: './gmt-car-fare-rules-modal.html',
  styleUrls: ['./gmt-car-fare-rules-modal.scss']
})
export class GmtCarModalFareRulesComponent implements OnInit {
  result: CarHireVehicleAvailibility = null;
  carTypeCodes: [string, string, CarHireType][];
  carHireAvailabilityDetailsObject: { [key: string]: CarHireDetailRequestResponse } = {};
  ServiceProvider = ServiceProvider;
  public carDetailLoadingStates: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(public modal: NgbActiveModal, public searchService: EnterpriseSearchService) {}

  ngOnInit(): void {
    this.carTypeCodes = this.searchService.searches[ServiceType.Car].carTypeCodes;
    this.getCarDetails();
  }

  //-- Copied from CarsComponent
  getCarDetails(): void {
    if (
      !this.carHireAvailabilityDataExists(this.createKeyFromIdentifiers(this.result.identifiers))
    ) {
      this.getCarHireAvailabilityDetail(this.result);
    }
  }

  carHireAvailabilityDataExists(key: string): boolean {
    return key in this.carHireAvailabilityDetailsObject;
  }

  createKeyFromIdentifiers(identifiers: CarHireIdentifiers): string {
    return `${identifiers.vendor}_${identifiers.vehType}`;
  }

  updateCarHireAvailabilityDetailsObject(data: {
    key: string;
    value: CarHireDetailRequestResponse;
  }): void {
    this.carHireAvailabilityDetailsObject[data.key] = data.value;
  }

  getCarHireAvailabilityDetail(result: CarHireVehicleAvailibility): void {
    const { __typename, ...identifiers } = result.identifiers;
    if (result.source === ServiceProvider.TravelportCarHire) {
      const detailResult: CarHireDetailRequestResponse = {
        additionalInfo: result.displayRecord.additionalInfo,
        address: result.displayRecord.pickupLocation,
        identifiers: result.identifiers,
        vendor: result.displayRecord.vendor
      };
      detailResult.additionalInfo?.sort((a, b) =>
        a.name > b.name ? 1 : a.name === b.name ? 0 : -1
      );
      // move the number to the beginning of the array
      let phone = detailResult.additionalInfo?.find(
        (item: CarHireAdditionalInfoItem) => item.name === 'Contact Numbers'
      );
      detailResult.additionalInfo?.sort((a, b) => (a === phone ? -1 : b == phone ? 1 : 0));

      // store result in an object so we don't have to load the same data again if it's been previously loaded
      this.updateCarHireAvailabilityDetailsObject({
        key: this.createKeyFromIdentifiers(identifiers),
        value: detailResult
      });
    } else {
      this.searchService.searches[ServiceType.Car]
        .getCarHireAvailabilityDetail(
          {
            dropOffLocation: identifiers.dropOffLocation,
            dropOffDateTime: identifiers.dropOffDateTime,
            pickUpDateTime: identifiers.pickUpDateTime,
            pickUpExtendedLocationCode: identifiers.pickUpExtendedLocationCode,
            pickUpLocationCode: identifiers.pickUpLocationCode,
            pickUpLocationName: identifiers.pickUpLocationName,
            locator: '',
            rph: '',
            vehType: identifiers.vehType,
            vendor: identifiers.vendor
          },
          this.carDetailLoadingStates
        )
        .subscribe(result => {
          result.additionalInfo.sort((a, b) => (a.name > b.name ? 1 : a.name === b.name ? 0 : -1));
          // move the number to the beginning of the array
          let phone = result.additionalInfo.find(
            (item: CarHireAdditionalInfoItem) => item.name === 'Contact Numbers'
          );
          result.additionalInfo.sort((a, b) => (a === phone ? -1 : b == phone ? 1 : 0));

          // store result in an object so we don't have to load the same data again if it's been previously loaded
          this.updateCarHireAvailabilityDetailsObject({
            key: this.createKeyFromIdentifiers(identifiers),
            value: result
          });
        });
    }
  }

  getTypeForDisplayFromResult(result: CarHireVehicleAvailibility): string {
    const typeForDisplay = this.carTypeCodes.find(
      carTypeCode => carTypeCode[2] === result?.displayRecord?.vehicle?.type
    );
    if (typeForDisplay) {
      return typeForDisplay[1];
    }
  }

  getCo2Usage(result: CarHireVehicleAvailibility): string {
    try {
      return result.co2PerItem.toFixed(2);
    } catch (error) {}
    return '0.00';
  }

  //-- end Copied from CarsComponent
}

LightningModalTypes.GmtCarModalFareRulesComponent.component = GmtCarModalFareRulesComponent;

