<div class="details-wrapper">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <span class="mat-content">
            <mat-panel-title>
              <span i18n="@@basketPanelTotalShowDetails">Show Details</span>
              <span class="sr-only"> for {{ detail?.hotelName }}</span>
            </mat-panel-title>
          </span>
        </mat-expansion-panel-header>
          <ng-container>
            <div class="summary">
              <div *ngIf="detail?.rooms[0]?.roomType as roomType">
                <strong i18n="@@ShowDetailsRoomType">Room Type</strong> {{roomType}}
              </div>
              <div *ngIf="detail?.rooms[0]?.rateDescription as description">
                <strong i18n="@@ShowDetailsDescription">Description</strong> {{description}}
              </div>
              <div *ngIf="detail?.rooms[0]?.nights as amountOfNights">
                <strong i18n="@@ShowDetailsAmountOfNights">Amount of nights</strong> {{detail?.rooms[0].nights.length}}
              </div>
            </div>
          </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>