import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { LoungeQuoteResult } from '@sabstravtech/obtservices/base';
@Component({
  selector: 'app-gmt-lounge-fare-rules-modal',
  templateUrl: './gmt-lounge-fare-rules-modal.html',
  styleUrls: ['./gmt-lounge-fare-rules-modal.scss']
})
export class GmtLoungeModalFareRulesComponent implements OnInit {
  lounge: LoungeQuoteResult = null;
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

}

LightningModalTypes.GmtLoungeModalFareRulesComponent.component = GmtLoungeModalFareRulesComponent;