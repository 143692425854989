<div cdkTrapFocus
     aria-live="assertive">
    <div class="modal-header">
        <h3 class="modal-title"
            id="modal-header">Warning</h3>
        <button type="button"
                class="close"
                attr.aria-label="Leave Itinerary Warning Modal close"
                ngbAutoFocus
                (click)="activeModal.close(false)">
            <span aria-hidden="true"><i class='ico-cancel modal_exit_cross'></i></span>
        </button>
    </div>
    <div class="modal-body">
        Moving away from this basket will send the itinerary to the My Bookings, with an "Open" status to either Confirm
        or Remove the booking when you are ready.
    </div>
    <div class="modal-footer">
        <button type="button"
                id='button_ok'
                class="small-button"
                (click)="activeModal.close(true)">Ok</button>
    </div>
</div>