<div class="flight_panel_container">
  <div class="flight-container" *ngIf="flightJourney">
    <div class="flight-logo-container">
      <app-preferred-icons class="icons-container" *ngIf="preferredMessages"
        [tmcPreferred] = "tmcPreferredLogoUrl"
        [tmcPreferredLogoUrl] = "tmcPreferredLogoUrl"
        [tmcPreferredMessage]="preferredMessages[PreferredFlags.tmcPreferred]"
        [clientPreferred] = "preferred !== PreferredFlags.none && preferred !== PreferredFlags.tmcPreferred || preferenceLevel"
        [companyPreferredMessage] = "preferredMessages[PreferredFlags.companyPreferred]"
        [officePreferredMessage] = "preferredMessages[PreferredFlags.officePreferred]">
      </app-preferred-icons>
      <app-image-with-error
        title="{{ source }}"
        logoUrl="{{ logoUrl }}"
        alt="{{flightJourney.operatingCarrierName}} - {{ flightJourney.marketingCarrier }} {{ flightJourney.marketingFlightNumber }} - {{ source }}"
        errorIcon="ico-flight"
        errorUrl=""
        class="ie-width-fix">
      </app-image-with-error>
    </div>

    <div class="flight-description" [ngClass]="{ 'flight-header-border': isReturn }">
      <div class="flight-airport-and-times">
        <div class="flight-airport">
          <span class="full-airport-name">{{ flightJourney.originAirportName }}</span>
          <span>({{ flightJourney.originAirport }})</span>
        </div>
        <div class="flight-time">
          <time>{{ parseDateToMoment(flightJourney.dateTimeDeparting) | sabsTimePipe }}</time>
        </div>

        <div class="durationAndStops">
          <div class="duration">
            <!--  *ngIf="searchType === FlightSearchType.multiCity && searchParams.bySchedule) -->
            <time *ngIf="searchType !== FlightSearchType.multiCity">{{
              duration | sabsTimePipe : 'min_duration'
            }}</time>
          </div>
          <div>
            <span id="flight-header-stops" *ngIf="allJourneys.length === 1">Direct</span>
            <span id="flight-header-stops" *ngIf="allJourneys.length > 1"> {{ allJourneys.length - 1 }} Stop<span *ngIf="allJourneys.length - 1 > 1">s</span>
              <section *ngIf="searchType === FlightSearchType.multiCity" (mouseleave)="tt.close()" class="container">
                <i class='ico ico-info-circle'
                  i18n-ngbTooltip="@@toViewAFullBreakdownTooltip"
                  triggers="manual"
                  (mouseenter)="tt.open()"
                  (focus)="tt.open()"
                  (blur)="tt.close()"
                  #tt="ngbTooltip"
                  tabindex="0"
                  ngbTooltip='To view a full breakdown of your flights, please click onto Show Trips details & fares.'>
                  <span class="visuallyhidden">To view a full breakdown of your flights from {{flightJourney.originAirportName}} to {{ lastFlightJourney.destinationAirportName }}, please click onto Show Trips details & fares.</span>
                </i>
              </section>
            </span>
          </div>
          <div class="marketing-carrier">
            <div *ngFor="let journey of allJourneys">
              {{ journey.marketingCarrier }}{{ journey.marketingFlightNumber }}
            </div>
          </div>
        </div>

        <div class="flight-time">
          <time>{{ parseDateToMoment(lastFlightJourney.dateTimeArriving) | sabsTimePipe }}</time>
          <span class="day-difference">
            {{
              searchType !== FlightSearchType.multiCity
                ? dayDifference(flightJourney.dateTimeDeparting, lastFlightJourney.dateTimeArriving)
                : dayDifference(
                    lastFlightJourney.dateTimeDeparting,
                    lastFlightJourney.dateTimeArriving
                  )
            }}
          </span>
        </div>
        <div class="flight-airport">
          <span class="full-airport-name">{{ lastFlightJourney.destinationAirportName }}</span>
          <span>({{ lastFlightJourney.destinationAirport }})</span>
        </div>
      </div>
    </div>
  </div>
</div>

