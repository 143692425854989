import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _, { isArray, keyBy } from 'lodash';
import { LightningModalTypes } from '../../../vendor/classes/modal-types.enum';

@Component({
  selector: 'app-rail-fare-terms',
  templateUrl: './rail-fare-terms.component.html',
  styleUrls: ['./rail-fare-terms.component.scss']
})
export class RailFareTermsComponent implements OnInit {
  public ticketDetails: any = {};
  public includedExtras: string[] = [];
  // Mandatory for Trainline
  ticketConditionsOrdered: any = {
    ticketTypeName: 0,
    description: 1,
    generalValidity: 2,
    preBookingRequirement: 3,
    refundCancellationRule: 4,
    changeRule: 5,
    generalDiscount: 6,
    journeyBreakRule: 7,
    'ADDITIONAL RESTRICTIONS': 8,
    ticketTypeCode: 9,
    ACCESSIBILITY: 10,
    route: 11
  };

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (this.ticketDetails) {
      if (isArray(this.ticketDetails)) {
        this.ticketConditionsOrdered = {};
        this.ticketDetails = keyBy(this.ticketDetails, x => x.name);
        _.forOwn(this.ticketDetails, (value, key) => {
          this.ticketConditionsOrdered[key] = value.value;
        });
      } else {
        delete this.ticketDetails.__typename;
        delete this.ticketDetails.ticketTypeAltName;

        this.ticketDetails = _.forOwn(this.ticketDetails, (value, key) => {
          if (_.isArray(value)) {
            value.forEach(x => {
              if (_.has(x, 'name') && _.has(x, 'description')) {
                this.ticketDetails[x.name] = x.description;
              }
            });
            delete this.ticketDetails[key];
          }
        });

        this.ticketDetails['description'] = this.ticketDetails['ticketTypeConditionsDescription'];
        delete this.ticketDetails['ticketTypeConditionsDescription'];

        // Re-order ticket details
        _.forOwn(this.ticketDetails, (value, key) => {
          this.ticketConditionsOrdered[key] = value ?? 0;
        });

        // Remove the properties do not exist in ticketDetails
        _.forOwn(this.ticketConditionsOrdered, (value, key) => {
          if (!isNaN(value)) {
            delete this.ticketConditionsOrdered[key];
          }
        });
      }
    }
  }
}

LightningModalTypes.RailFareTermsComponent.component = RailFareTermsComponent;
