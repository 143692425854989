<div class="cart_item" [class.duplicate-item]="duplicateBooking">
    <div class="cart_item_wrapper">
      <ng-container *ngIf="eurostar?.detail as flightDetail">

        <div class="cart_item_heading"
             *ngIf="eurostarToDisplay.detail.flights as flights">
          <ng-container *ngIf="!duplicateBooking">
            <i class="custom_icon-eurostar"></i>&nbsp;
            <span i18n="@@eurostar">Eurostar</span>
            <br />
          </ng-container>
          <ng-container *ngFor="let flightDetail of flights; let first = first; let index = index">
            <div [class.duplicate-item-info]="duplicateBooking">
              <div *ngIf="!first"><br /></div>
              <div class="duplicate-item-date">
                <!-- <i class="custom_icon-eurostar"></i>&nbsp; -->
                <span *ngIf="index === noOfOutbounds" i18n="@@return"><b>Return</b><br/></span>
                <span *ngIf="first" i18n="@@outbound"><b>Outbound</b><br/></span>
                <!-- <strong>{{flightDetail.dateTimeDeparting | sabsDate: 1}}</strong> -->
              </div>
              <div>
              <span>
                <!-- <span *ngIf="!duplicateBooking" i18n="@@from">From</span>  -->
                <b>{{
                getFullStationName(flightDetail.originAirport)
                }}</b></span>&nbsp; 
                <span i18n="@@to">to</span>&nbsp;
                <span>
                <b>{{
                getFullStationName(flightDetail.destinationAirport)
              }}</b></span>
              </div>
          
            </div>

              <br />

            <button *ngIf="first && !duplicateBooking"
                    class="cart_item_remove ico-trash"
                    ngbTooltip="Remove {{ flightDescription }} from your basket"
                    attr.aria-label="Remove {{ getFullStationName(flightDetail.originAirport) }} to {{ getFullStationName(flightDetail.destinationAirport) }} eurostar from your basket"
                    i18n-ngbTooltip
                    (click)="remove.emit()"></button>
            <img *ngIf="first && !duplicateBooking"
                 src="https://images.sabscorp.com/images/enterprise/lightUK/assets/svg/basket_move.svg"
                 class="move_basket_icon"
                 role="button"
                 attr.aria-label="Move {{ getFullStationName(flightDetail.originAirport) }} to {{ getFullStationName(flightDetail.destinationAirport) }} eurostar to different basket"
                 (click)="openChangeModal.emit()"
                 ngbTooltip="Move to different basket"
                 i18n-ngbTooltip
                 (keydown.enter)="openChangeModal.emit()"
                 (keydown.space)="openChangeModal.emit()"
                 tabindex="0">
            <span *ngIf="!duplicateBooking">
            <!-- <span *ngIf="index === noOfOutbounds" i18n="@@return"><b>Return</b><br/></span>
            <span *ngIf="first" i18n="@@outbound"><b>Outbound</b><br/></span> -->
            {{flightDetail.dateTimeDeparting | sabsDate : 5}} - {{flightDetail.dateTimeArriving | sabsDate : 5}} </span>
          </ng-container>
        </div>
        <div class="cart_item_price">
          <strong *ngIf="duplicateBooking" i18n="@@cost">Cost</strong>
          <strong>{{ eurostar?.price | currency: eurostar?.currency }}</strong><em *ngIf="!duplicateBooking" i18n="@@totalPrice"> Total Price</em>
        </div>
      </ng-container>
    </div>
  </div>
