import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './vendor/components/main/main.component';
// import { MainComponent } from './vendor/components/main/main.component';
import { LogonComponent } from './startup/logon/logon.component';
import { AuthGuard } from './vendor/services/auth-guard.service';
import { PasswordResetComponent } from './startup/password-reset/password-reset.component';
import { ProfileInformationComponent } from './profile-information/profile-information.component';
import { ChangeUserComponent } from './vendor/components/change-user/change-user.component';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      // { path: 'main', component: MainComponent },
      { path: 'profile-information', component: ProfileInformationComponent },
      // { path: 'trip-robot', component: TripRobotBookedList },
      {
        path: 'search',
        loadChildren: () => import('./search-module/search-module.module').then(m => m.SearchModule)
      },
      {
        path: 'gmt',
        loadChildren: () => import('./get-me-there/get-me-there.module').then(m => m.GetMeThereModule)
      },
      {
        path: 'itinerary',
        loadChildren: () => import('./itinerary-module/itinerary-module').then(m => m.ItineraryModule)
      },
      {
        path: 'my-bookings',
        loadChildren: () => import('./my-bookings/my-bookings.module').then(m => m.MyBookingsModule)
      },
      // { path: 'elements', component: ElementsComponent },
      {
        path: 'results',
        loadChildren: () => import('./results-module/results.module').then(m => m.ResultsModule)
      },
      {
        path: 'live-rail',
        loadChildren: () => import('./search-module/components/rail-live/rail-live-search.module').then(m => m.LiveRailSearchModule)
      }
      // { path: 'sso/:type', component: SsoComponent }
    ]
  },
  { path: 'reset-password', component: PasswordResetComponent },
  { path: 'logon', component: LogonComponent },
  // { path: 'logOutDigitsUser', component: DigitsComponent },
  // { path: 'landingPageBrand', component: LandingPageBrandComponent },
  // {
  //   path: 'getmethere',
  //   component: GetmethereComponent,
  //   canActivate: [GetMeThereAuthGuardService]
  // },
  {
    path: 'changeuser',
    component: ChangeUserComponent
    // canActivate: [CanChangeUserGuardService]
  },
  { path: '**', redirectTo: 'search', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
