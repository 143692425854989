import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightningModalTypes } from '../../vendor/classes/modal-types.enum';
import { EnterpriseSearchService, Basket } from '@sabstravtech/obtservices/angular';

@Component({
  selector: 'app-send-pdf-by-email',
  templateUrl: './send-pdf-by-email.component.html',
  styleUrls: ['./send-pdf-by-email.component.scss']
})
export class SendPdfByEmailComponent implements OnInit {
  id: string = '';
  email: string = null;
  emailsList: string[] = [];
  addEmailForm: FormGroup;
  emailsForm: FormGroup;
  constructor(
    public activeModal: NgbActiveModal,
    private searchService: EnterpriseSearchService,
    private fb: FormBuilder
  ) {}

  get emails() {
    return this.emailsForm.controls['emails'] as FormArray;
  }

  ngOnInit(): void {
    this.emailsForm = new FormGroup({
      emails: new FormArray([
        new FormGroup({
          selected: new FormControl(true),
          email: new FormControl(this.email)
        })
      ])
    });

    this.addEmailForm = new FormGroup({
      selected: new FormControl(true),
      email: new FormControl('', [Validators.email])
    });
    this.createEmailList();
  }

  addEmail() {
    const emailForm = this.fb.group({
      selected: true,
      email: this.addEmailForm.get('email').value
    });
    this.emails.push(emailForm);

    this.emailsForm.markAsDirty();
    // set default value
    this.addEmailForm.setValue({ selected: true, email: '' });
    this.createEmailList();
  }

  createEmailList() {
    this.emailsList = [];
    this.emailsForm.value.emails.map(item => {
      if (item.selected) {
        this.emailsList = this.emailsList.concat(item.email);
      }
    });
  }

  submit() {
    this.searchService
      .emailBasket(this.id, this.emailsList)
      .subscribe(() => this.activeModal.close(true));
  }
}

LightningModalTypes.SendPdfByEmailComponent.component = SendPdfByEmailComponent;

